import React, {Component} from 'react';
import {StringUtil} from "../util/StringUtil";
import Tooltip from "@material-ui/core/Tooltip";
import {Button} from "reactstrap";

interface IProps {
    relatedContent: any,
    openRelatedContent: any,
    timeOutOpenRelatedContent: any,
    setShowRelatedContent: any,
    countdownSeconds: number,
    intervalCountdownSeconds: any
}

interface IState {
}

class RelatedContent extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
    }

    cancelWatchingNow = () => {
        clearTimeout(this.props.timeOutOpenRelatedContent);
        clearInterval(this.props.intervalCountdownSeconds);
        this.props.setShowRelatedContent(false);
    }

    watchingNow = () => {
        window.location.href = this.props.openRelatedContent();
    }

    render() {
        return (
            <div className="related-content-div">
                <div className="related-content">
                    <p>次の講義を再生 {this.props.countdownSeconds}</p>
                    <div className="content-related">
                        <div className="content-related-thumbnail">
                            <img className="img-fit-content"
                                 src={this.props.relatedContent.thumbnail}
                                 alt="Product"/>
                            <span className="program-duration">{StringUtil.getMinuteAndSecondByTime(this.props.relatedContent.prg_duration)}</span>
                        </div>
                        <div className="content-related-title">
                            <h3 className="product-title font-size-sm d-inline-block font-weight-bold col-11 px-0">
                                {this.props.relatedContent.prg_title}
                            </h3>
                            <Tooltip
                                placement="bottom"
                                arrow
                                title={<span dangerouslySetInnerHTML={{__html: this.props.relatedContent.prg_theme}}></span>}>
                                <h3 className="product-title font-size-sm theme-text post__items" dangerouslySetInnerHTML={{__html: this.props.relatedContent.prg_theme}}></h3>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="related-content-button d-flex">
                        <Button className="btn-cancel" onClick={this.cancelWatchingNow}>この講義にとどまる</Button>
                        <Button className="btn-watching-now" onClick={this.watchingNow}>次の講義を再生</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default RelatedContent;