import React from "react";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
interface IProps {
    seekNext5Seconds?: any,
    seekNext10Seconds?: any,
    seekBack5Seconds?: any,
    seekBack10Seconds?: any,
    seekTo?: any,
    fullScreen?:any,
    largevideo?:any,
    isShowLargeVideo?:boolean,

}

interface IState {
    seekBarIndex: number
}


class ControlsBar extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            seekBarIndex: 1
        }
    }
    handleChange = (value) => {
        this.setState({
            seekBarIndex: value
        });
        if(this.props.seekTo){
            this.props?.seekTo(value.toFixed(1));
        }
    }
    render() {
        const { seekBarIndex } = this.state;
        return <div id="player_console" className="clearfix">
            <div id="player_seek">
                <ul className="pl-0">
                    <li className="seeks-prev-more" onClick={() => this.props?.seekBack10Seconds()}><a className="seek-control" data-seek-time="-10"
                                                       data-toggle="tooltip" title="10秒戻る"/></li>
                    <li className="seeks-prev" onClick={() => this.props?.seekBack5Seconds()}><a className="seek-control" data-seek-time="-5"
                                                  data-toggle="tooltip" title="5秒戻る"/></li>
                    <li className="seeks-skip">スキップ</li>
                    <li className="seeks-next" onClick={() => this.props?.seekNext5Seconds()}><a className="seek-control" data-seek-time="5"
                                                  data-toggle="tooltip" title="5秒進む"/></li>
                    <li className="seeks-next-more" onClick={() => this.props?.seekNext10Seconds()}><a className="seek-control" data-seek-time="10"
                                                       data-toggle="tooltip" title="10秒進む"/></li>
                </ul>
            </div>
            <div id="player_speed" style={{visibility: 'visible'}}>
                <p>再生速度</p>
                <div className='slider-horizontal'>
                        <Slider
                            min={0.8}
                            max={2}
                            step={0.1}
                            orientation='horizontal'
                            value={seekBarIndex}
                            onChange={this.handleChange}
                            tooltip={false}

                        />
                </div>
                <p className="player_speed_num"><span>{seekBarIndex.toFixed(1) }</span>X</p>
            </div>
            <div id="player_option" hidden={true} >
                <ul>
                    <li data-func="largevideo" className={`${this.props.isShowLargeVideo ? 'selected' : ''}`} onClick={() =>  this.props?.largevideo()}><a href="#" data-toggle="tooltip" title="Enlarge"/></li>
                    <li data-func="fullvideo" onClick={() =>  this.props?.fullScreen()}><a href="#" data-toggle="tooltip" title="全画面表示"/></li>
                </ul>
            </div>
        </div>
    }
}

export default ControlsBar;

