import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import {StringUtil} from "../util/StringUtil";
import ReactExport from 'react-data-export';
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface IProps {
    memos: any[],
    content: any
}

export default function DownloadMemo (props: IProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const currentDate = moment(new Date()).format("YYYYMMDD_HHmm");
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getMemoDataExport = () => {
        const styleTittle = {
            font: {sz: "10", bold: true},
            alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
            },
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                }
            },
            fill: {
                patternType: "solid",
                fgColor: {rgb: "ddebf7"}
            }
        };
        const styleCell = {
            font: {sz: "10"},
            alignment: {
                vertical: "top",
                horizontal: "top",
                wrapText: true,
            },
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                }
            },
        };

        const styleCell2 = {
            font: {sz: "10"},
            alignment: {
                vertical: "top",
                horizontal: "right",
                wrapText: true,
            },
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "bfbfbf" }
                }
            },
        };

        const multiDataSet = [
            {
                columns: [
                    {title: "講義ID", width: {wpx: 101}, style: styleTittle},
                    {title: "講義名", width: {wpx: 200}, style: styleTittle},
                    {title: "テーマ名", width: {wpx: 346}, style: styleTittle},
                    {title: "タイムライン", width: {wpx: 101}, style: styleTittle},
                    {title: "メモの内容", width: {wpx: 326}, style: styleTittle}
                ],
                data: [] as any[]
            }
        ];
        props.memos.forEach(item => {
            multiDataSet[0].data.push([
                {value: item.prg_per_id || "", style: styleCell},
                {value: item.prg_title || "", style: styleCell},
                {value: item.prg_theme || "", style: styleCell},
                {value: StringUtil.convertTimeIndexToTime(item.second) || "", style: styleCell2},
                {value: item.text || "", style: styleCell},
            ])
        });
        return multiDataSet;
    }

    const getMemoDataExportTxt = () => {
        let data = `■講義ID: \n${props.content.prg_per_id}\n■講義名: \n${props.content.prg_title}\n■テーマ名: \n${props.content.prg_theme}\n■講義メモ: \n`;

        const memosLength = props.memos.length;
        props.memos.forEach((item, index) => {
            data += `${item.text}`;
            if (index !== memosLength - 1) {
                data += "\n---\n";
            }
        });

        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `講義メモ_${props.content.prg_per_id}_${props.content.prg_title}_${currentDate}.txt`;
        link.href = url;
        link.click();
        handleClose();
    }

    return (
        <div className="mt-3">
            <a
                href="#"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <i className="bi bi-download mr-2"/>
                ダウンロード
            </a>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <ExcelFile element={<MenuItem onClick={handleClose} style={{whiteSpace: "normal"}}>Excel</MenuItem>}
                           filename={`講義メモ_${props.content.prg_per_id}_${props.content.prg_title}_${currentDate}`}>
                    <ExcelSheet dataSet={getMemoDataExport()} name={`memo`}>
                    </ExcelSheet>
                </ExcelFile>

                <MenuItem onClick={() => getMemoDataExportTxt()} style={{whiteSpace: "normal"}}>プレーンテキスト</MenuItem>
            </Menu>
        </div>
    )
}