import React from "react";
import "./LoadingScreen.sass";

export class LoadingScreen extends React.Component {
    render() {
        return <div id="loading-panel-screen" className="d-flex justify-content-center">
            <div className="loading-screen">
                <div className="loading-icon"/>
            </div>
        </div>;
    }
}