import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hotkeys";
import QualitySelector from "@silvermine/videojs-quality-selector";
import "@silvermine/videojs-quality-selector/dist/css/quality-selector.css";
import {isEqual} from "lodash";
import {isIOS, isMobileOrTablet, isIPhone} from "../../helper/utils";
import {Constant} from "../constant/Constant";
import {connect} from "react-redux";
import {StringUtil} from "../util/StringUtil";

const IsIOS = isIOS();
const IsIPhone = isIPhone();
QualitySelector(videojs);

interface IProps {
  src?: string;
  poster?: string;
  controls?: boolean;
  autoplay?: boolean;
  width?: any;
  height?: any;
  preload?: string;
  bigPlayButton?: boolean;
  bigPlayButtonCentered?: boolean;
  onReady?: any;
  onPlay?: any;
  onPause?: any;
  onTimeUpdate?: any;
  onSeeking?: any;
  onSeeked?: any;
  qualitySelected?: any;
  qualityRequested?: any;
  onEnd?: any;
  playbackRates?: number[];
  className?: string;
  onConfirmFullScreen?: any;
  isShowConfirmModal?: boolean;
  onConfirmPictureInPicture?: any;
  reloadVideoUrl?: any;
  thumbnails?: Thumbnails[];
  slides?: Slides[];
}

interface Player {
  el_: any;
  src?: any;
  poster?: any;
  dispose?: any;
  ready?: any;
  on?: any;
  currentTime?: any;
  off?: any;
  one?: any;
  bigPlayButton?: any;
  volume?: any,
  muted?: any,
  isFullscreen?: any,
  exitFullscreen?: any,
  requestFullscreen?: any,
  pause?: any,
  play?: any,
  isInPictureInPicture?: any;
  exitPictureInPicture?: any;
  error?: any;
  controlBar?: any;
  duration?: any;
}

declare global {
  interface Window {
    player?: any;
  }
}

interface Thumbnail{
  prg_per_id : number,
  thumb_id : number,
  thumb_type : string,
  thumb_time : number,
  thumb_face : boolean,
  thumbnail_url : string,
  thumb_file : string,

}

interface Thumbnails {
  attribute : Thumbnail
}

interface Slides {
  attribute : Slide
}

interface Slide {
  doc: string,
  index_order: string,
  index_time: number,
  index_title: string,
  index_type: number,
  parent: string,
  prg_per_id: number,
  slide_image_url: string,
}

interface ThumbnailPreview {
  thumb_time : number,
  thumbnail_url : string,
  order_type: number,
}

const IsMobileOrTablet = isMobileOrTablet();

class VideoJS extends React.Component<IProps> {
  playerId = `video-player`;
  player!: Player;
  refVideo: any;
  thumbnailList: ThumbnailPreview[] | undefined;

  static defaultProps = {
    src: "",
    poster: "",
    width: "100%",
    height: "100%",
    controls: true,
    autoplay: false,
    preload: "auto",
    playbackRates: [0.5, 1, 1.5, 2],
    className: "",
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    onReady: () => {},
    onPlay: () => {},
    onPause: () => {},
    onTimeUpdate: () => {},
    onSeeking: () => {},
    onSeeked: () => {},
    qualitySelected: () => {},
    qualityRequested: () => {},
    onEnd: () => {},
  };

  componentDidMount() {
    this.init_player(this.props);
    this.init_player_events(this.props);
    this.preventContextMenu();
    this.getThumbnailList();
  }

  componentWillUnmount() {
    this.refVideo && this.refVideo.removeEventListener("contextmenu");
    this.player && this.player.dispose();
  }

  preventContextMenu() {
    if (!this.refVideo) return;

    const absorbEvent_ = (event) => {
      const e = event || window.event;
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    }
    this.refVideo.ontouchstart = absorbEvent_;
    this.refVideo.ontouchmove = absorbEvent_;
    this.refVideo.ontouchend = absorbEvent_;
    this.refVideo.ontouchcancel = absorbEvent_;

    this.refVideo.addEventListener && this.refVideo.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    }, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(this.props.src, nextProps.src)) {
      this.player.pause();
      this.init_player(nextProps);
    }
    return true;
  }

  init_player(props) {
    const playerOptions = this.generate_player_options(props);
    const _player = videojs(
        document.querySelector(`#${this.playerId}`),
        playerOptions
    );
    _player.controlBar.addChild('QualitySelector');
    this.player = _player;
    this.player.src(props.src);
    this.player.poster(props.poster);
    const bigPlayButton = document.getElementsByClassName("vjs-big-play-button")[0];
    bigPlayButton.setAttribute("title", "ビデオを再生する");
    const muteButton = document.getElementsByClassName('vjs-mute-control')[0];
    muteButton.setAttribute("title", "ミュート");
    const vjsRemainingTime = document.querySelectorAll('.vjs-remaining-time.vjs-time-control')[0];
    vjsRemainingTime.setAttribute("translate", "no");

    const fullScreenButton = document.getElementsByClassName("vjs-fullscreen-control")[0];
    if (fullScreenButton) {
      const fullScreenButtonNew = fullScreenButton.cloneNode(true);
      (fullScreenButtonNew as HTMLButtonElement).setAttribute("title", "全画面表示");
      fullScreenButton?.parentNode?.replaceChild(fullScreenButtonNew, fullScreenButton);
      fullScreenButtonNew.addEventListener("click", (event) => {
        event.preventDefault();
        if (IsIPhone) {
          if (this.props.isShowConfirmModal) {
            if (!this.player.isFullscreen()) {
              this.props.onConfirmFullScreen();
            } else {
              this.player.exitFullscreen();
            }
          } else {
            if (!this.player.isFullscreen()) {
              this.player.requestFullscreen();
            } else {
              this.player.exitFullscreen();
            }
          }

        } else {
          const el = event.target as HTMLButtonElement
          let elem = document.getElementById("player-video");
          // @ts-ignore
          if(document.fullscreenElement || document.webkitCurrentFullScreenElement) {
            if (document.exitFullscreen) {
              document.exitFullscreen();
              el.setAttribute("title", "全画面表示");
              // @ts-ignore
            } else if (document.webkitExitFullscreen) { /* Safari */
              // @ts-ignore
              document.webkitExitFullscreen();
              el.setAttribute("title", "全画面表示");
            }
          } else {
            if (elem && elem.requestFullscreen) {
              elem.requestFullscreen();
              el.setAttribute("title", "全画面表示を終了");
              // @ts-ignore
            } else if (elem && elem.webkitRequestFullscreen) { /* Safari */
              // @ts-ignore
              elem.webkitRequestFullscreen();
              el.setAttribute("title", "全画面表示を終了");
            }
          }
        }
      });
    }

    if (this.props.isShowConfirmModal) {
      const pictureInPictureButton = document.getElementsByClassName("vjs-picture-in-picture-control")[0];
      if (pictureInPictureButton) {
        pictureInPictureButton.classList.remove("vjs-disabled");
        pictureInPictureButton.removeAttribute("disabled");
        const pictureInPictureButtonNew = pictureInPictureButton.cloneNode(true);
        pictureInPictureButton?.parentNode?.replaceChild(pictureInPictureButtonNew, pictureInPictureButton);
        pictureInPictureButtonNew.addEventListener("click", (event) => {
          event.preventDefault();
          if (!this.player.isInPictureInPicture()) {
            this.props.onConfirmPictureInPicture();
          } else {
            this.player.exitPictureInPicture();
          }
        });
      }
    }
  }


  generate_player_options(props) {
    let playerOptions = {
      controls: props.controls,
      autoplay: props.autoplay,
      preload: props.preload,
      width: props.width,
      height: props.height,
      bigPlayButton: props.bigPlayButton,
      playbackRates: props.playbackRates,
      playsinline: true,
      errorDisplay: false,
      html5: {
        nativeTextTracks: true
      },
      plugins: {
        hotkeys: {
          enableVolumeScroll: false,
          enableFullscreen: false,
          captureDocumentHotkeys: true,
          documentHotkeysFocusElementFilter: e => e.tagName.toLowerCase() === 'body',
          seekStep: Constant.SEEK_STEP_10_SECOND,
          playPauseKey: function (event, player) {
            return (event.which === Constant.KEYCODE_K);
          },
          forwardKey: function (event, player) {
            return (event.which === Constant.KEYCODE_L) || (event.which === Constant.KEYCODE_ARROW_RIGHT);
          },
          rewindKey: function (event, player) {
            return (event.which === Constant.KEYCODE_J) || (event.which === Constant.KEYCODE_ARROW_LEFT);
          },
        },
      },
    };
    return playerOptions;
  }

  init_player_events(props) {
    let currentTime = 0;
    let previousTime = 0;
    let position = 0;

    if (IsMobileOrTablet) {
      this.player.controlBar.progressControl.disable();
    }
    this.player.ready((e) => {
      if (IsIOS) {
        const promise = this.player.play();
        this.player.pause();
        if (promise !== undefined) {
          promise.then().catch(function(error) {
            const isIOSErrorNotAllow = error.toString().includes('NotAllowedError');
            if (isIOSErrorNotAllow) {
              const elms = document.getElementsByClassName('vjs-big-play-button');
              if (elms && elms.length) {
                elms[0].setAttribute("hidden", "true");
              }
            }
          });
        }
      }
      window.player = this.player;
    });

    this.player.on("loadedmetadata", () => {
      props.onReady(this.player);
    });

    this.player.on("play", () => {
      props.onPlay(this.player.currentTime());
      const vjs_playing = document.getElementsByClassName('vjs-button vjs-playing')[0];
      vjs_playing.setAttribute('title', '演奏する');
      const pictureInPicture = document.getElementsByClassName('vjs-picture-in-picture-control')[0];
      pictureInPicture.setAttribute('title', 'ピクチャーインピクチャー');
      const subCapsButton = document.querySelector('button.vjs-subs-caps-button');
      subCapsButton?.setAttribute('title', 'キャプション');
      const fullScreenButton = document.querySelector('button.vjs-fullscreen-control');
      fullScreenButton?.setAttribute('title', '全画面表示');
    });

    this.player.on("pause", () => {
      props.onPause(this.player.currentTime());
      const vjs_paused = document.getElementsByClassName('vjs-button vjs-paused')[0];
      vjs_paused.setAttribute('title', '休止');
      if (IsIOS) {
        const elms = document.getElementsByClassName('vjs-big-play-button');
        if (elms && elms.length) {
          elms[0].hasAttribute('hidden') && elms[0].removeAttribute("hidden");
        }
      }
    });

    this.player.on("timeupdate", (e) => {
      props.onTimeUpdate(this.player.currentTime());
      previousTime = currentTime;
      currentTime = this.player.currentTime();
      if (previousTime < currentTime) {
        position = previousTime;
        previousTime = currentTime;
      }
    });

    this.player.on("seeking", () => {
      this.player.off("timeupdate", () => {});
      this.player.one("seeked", () => {});
      props.onSeeking(this.player.currentTime());
    });

    this.player.on("seeked", () => {
      let completeTime = Math.floor(this.player.currentTime());
      props.onSeeked(position, completeTime);
    });

    this.player.on("volumechange", () => {
      const volumeElement = document.getElementsByClassName('vjs-mute-control')[0];
      if (this.player.volume() <= 0 || this.player.muted()) {
        volumeElement.setAttribute('title', 'ミュートを解除する');
      } else {
        volumeElement.setAttribute('title', 'ミュート');
      }
      const u = StringUtil.getCookieByName("u");
      localStorage.setItem(`volumePlayer_${u}` , this.player.volume());
    });

    this.player.on('fullscreenchange', () => {
      const fullScreenButton = document.querySelector('button.vjs-fullscreen-control');
      if (this.player.isFullscreen()) {
        fullScreenButton?.setAttribute('title', '全画面表示を終了');
      } else {
        fullScreenButton?.setAttribute('title', '全画面表示');
      }
    });

    this.player.on('enterpictureinpicture', () => {
      const pictureInPicture = document.getElementsByClassName('vjs-picture-in-picture-control')[0];
      pictureInPicture.setAttribute('title', 'ピクチャーインピクチャーを終了します');
    });

    this.player.on('leavepictureinpicture', () => {
      const pictureInPicture = document.getElementsByClassName('vjs-picture-in-picture-control')[0];
      pictureInPicture.setAttribute('title', 'ピクチャーインピクチャー');
    });

    this.player.on('qualitySelected', () => {
      props.qualitySelected && props.qualitySelected();
    });

    this.player.on('qualityRequested', () => {
      props.qualityRequested && props.qualityRequested();
    });

    this.player.on("ended", () => {
      props.onEnd();
    });

    this.player.on('error', () => {
      const error = this.player.error();
      if (error.code === MediaError.MEDIA_ERR_NETWORK) {
        this.props.reloadVideoUrl();
      }
    });


    this.player.on("loadeddata", () => {
      const videoSource = this.player.el_;
      const controlBar = videoSource.querySelector('.vjs-control-bar');

      if (!controlBar.querySelector(".vjs-mouse-display") && IsMobileOrTablet) {
        const vjsProgressHolder = controlBar.querySelector(".vjs-progress-holder");
        if (vjsProgressHolder) {
          const vjsMouseDisplay = document.createElement("div");
          vjsMouseDisplay.classList.add("vjs-mouse-display");
          const vjsTimeTooltip = document.createElement("div");
          vjsTimeTooltip.classList.add("vjs-time-tooltip");
          vjsTimeTooltip.setAttribute("aria-hidden", "true");
          vjsMouseDisplay.appendChild(vjsTimeTooltip);
          vjsProgressHolder.appendChild(vjsMouseDisplay);
        }
      }

      if (!controlBar.querySelector(".vjs-thumbnail")) {
        const vjsThumbnail = document.createElement('div');
        vjsThumbnail.classList.add("vjs-thumbnail");
        vjsThumbnail.style.display = "none";
        controlBar.appendChild(vjsThumbnail);

        this.thumbnailList?.forEach(thumbnail => {
          const imageThumbnail = document.createElement('img');
          imageThumbnail.src = thumbnail.thumbnail_url;
          imageThumbnail.style.display = "none";
          imageThumbnail.classList.add(`image-thumbnail-${thumbnail.thumb_time}`);
          vjsThumbnail.appendChild(imageThumbnail);
        });

        if (!IsMobileOrTablet) {
          controlBar.querySelector(".vjs-progress-control").addEventListener('mousemove', (event) => {
            let rect = event.currentTarget.getBoundingClientRect();
            let left = event.clientX - rect.left;
            // getting time
            let time = controlBar.querySelector('.vjs-mouse-display .vjs-time-tooltip').textContent;
            let temp = [];
            // format: 09
            if (/^\d+$/.test(time)) {
              // re-format to: 0:0:09
              time = '0:0:' + time;
            }
            // format: 1:09
            else if (/^\d+:\d+$/.test(time)) {
              // re-format to: 0:1:09
              time = '0:' + time;
            }
            temp = time.split(':');

            // calculating to get seconds
            time = (+temp[0]) * 60 * 60 + (+temp[1]) * 60 + (+temp[2]);

            vjsThumbnail.style.left = `${left}px`;
            this.showVjsThumbnail(time, vjsThumbnail, left, controlBar);
          });

          // mouse leaving the control bar
          controlBar.querySelector(".vjs-progress-control").addEventListener('mouseleave', function() {
            // hidding thumbnail
            const vjsThumbnail = controlBar.querySelector('.vjs-thumbnail');
            vjsThumbnail.style.display = 'none';
          });
        } else {
          controlBar.querySelector(".vjs-progress-control").addEventListener('touchmove', (event) => {
            const evt = (typeof event.originalEvent === 'undefined') ? event : event.originalEvent;
            const touch = evt.touches[0] || evt.changedTouches[0];
            const {time, leftProgressHolderWidth, vjsProgressHolderWidth} = this.getTimeTouchProgress(event);
            const vjsMouseDisplay = controlBar.querySelector(".vjs-mouse-display");
            const vjsTimeTooltip = controlBar.querySelector(".vjs-time-tooltip");

            let vjsMouseLeft = leftProgressHolderWidth;
            if (leftProgressHolderWidth < 0) {
              vjsMouseLeft = 0;
            }
            if (leftProgressHolderWidth > vjsProgressHolderWidth) {
              vjsMouseLeft = vjsProgressHolderWidth;
            }

            vjsMouseDisplay.style.left = `${vjsMouseLeft}px`;
            vjsTimeTooltip.style.right = `-25px`;
            vjsTimeTooltip.style.visibility = "visible";

            let timeTooltip;

            if (this.player.duration() >= 36000) {
              timeTooltip = new Date(time * 1000).toISOString().substring(11, 19);
            }else if (this.player.duration() >= 3600) {
              timeTooltip = new Date(time * 1000).toISOString().substring(12, 19);
            } else {
              timeTooltip = new Date(time * 1000).toISOString().substring(14, 19);
            }

            vjsTimeTooltip.textContent = timeTooltip;
            vjsMouseDisplay.style.display = 'block';
            vjsThumbnail.style.left = `${leftProgressHolderWidth + 10}px`;
            this.showVjsThumbnail(time, vjsThumbnail, leftProgressHolderWidth, controlBar);

            if (event.currentTarget !== document.elementFromPoint(touch.clientX, touch.clientY)?.closest(".vjs-progress-control")) {
              vjsThumbnail.style.display = 'none';
              vjsMouseDisplay.style.display = 'none';
              this.player.currentTime(time);
            }
          });

          controlBar.querySelector(".vjs-progress-control").addEventListener('touchend', (event) => {
            const {time} = this.getTimeTouchProgress(event);
            const vjsThumbnail = controlBar.querySelector('.vjs-thumbnail');
            vjsThumbnail.style.display = 'none';
            const vjsMouseDisplay = controlBar.querySelector('.vjs-mouse-display');
            vjsMouseDisplay.style.display = 'none';
            this.player.currentTime(time);
          });
        }
      }
    });
  }

  getTimeTouchProgress = (event) => {
    const evt = (typeof event.originalEvent === 'undefined') ? event : event.originalEvent;
    const touch = evt.touches[0] || evt.changedTouches[0];
    const x = touch.clientX;
    const vjsProgressHolderWidth = event.currentTarget.querySelector('.vjs-progress-holder').offsetWidth;
    let leftProgressHolderWidth = x - event.currentTarget.querySelector('.vjs-progress-holder').getBoundingClientRect().left;
    let percent = leftProgressHolderWidth / vjsProgressHolderWidth;

    if (percent < 0) {
      percent = 0;
    }
    if (percent > 1) {
      percent = 1;
    }
    const time = Math.trunc(percent * this.player.duration());
    return {
      time,
      leftProgressHolderWidth,
      vjsProgressHolderWidth,
    };
  }

  showVjsThumbnail = (time: number, vjsThumbnail: HTMLDivElement, leftProgressHolderWidth: number, controlBar) => {
    vjsThumbnail.querySelectorAll("img").forEach(item => {
      item.style.display = "none";
    })
    const thumbnail = this.thumbnailList?.find(item => item.thumb_time <= time);
    if (thumbnail) {
      const imageThumbnail = controlBar.querySelector(`.image-thumbnail-${thumbnail.thumb_time}`);
      imageThumbnail.style.display = 'block';
      vjsThumbnail.style.display = "block";
    }
  }

  getThumbnailList = () => {
    this.thumbnailList = [];
    this.props.thumbnails?.filter(thumbnail => thumbnail.attribute.thumb_type.toLowerCase() === "c").forEach(thumbnail => {
      const thumbnailUrl = `${process.env.REACT_APP_PROGRAM_THUMB_URL}${thumbnail.attribute.prg_per_id}/thumb_c/mdoc/${thumbnail.attribute.thumb_file}`;
      this.thumbnailList?.push({
        thumb_time : thumbnail.attribute.thumb_time,
        thumbnail_url : thumbnailUrl,
        order_type: 2,
      })
    })

    this.props.slides?.forEach(slide => {
      const slideImageUrl = `${process.env.REACT_APP_SLIDE_DOMAIN_URL}asp/data/${slide.attribute.prg_per_id}/doc/${slide.attribute.doc}`;
      this.thumbnailList?.push({
        thumb_time: slide.attribute.index_time,
        thumbnail_url: slideImageUrl,
        order_type: 1,
      });
    })

    this.thumbnailList?.sort((a, b) => a.order_type - b.order_type)
        .sort((a, b) => b.thumb_time - a.thumb_time);
  }

  render() {
    return (
      <video
        ref={(elm) => this.refVideo = elm}
        lang="en"
        id={this.playerId}
        crossOrigin="anonymous"
        preload="metadata"
        playsInline
        webkit-playsinline
        className={`video-js ${
          this.props.bigPlayButtonCentered ? "vjs-big-play-centered" : ""
        } ${this.props.className}`}
      />
    );
  }
}

export default connect(null, null, null, {forwardRef: true})(VideoJS);
