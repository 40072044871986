import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import VideoJS from '../videojs/Videojs';
import * as xmlRpcJsonServices from './xmlRpcJsonServices';
import _ from 'lodash';
import ControlsBar from "./ControlsBar";
import {Constant} from "../constant/Constant";
import Authentication from "./Authentication";
import {StringUtil} from "../util/StringUtil";
import "./Player.sass";
import RelatedContent from "./RelatedContent";
import PlayerAbstract from "./PlayerAbstract";
import ModalPopupFullScreen from "../popup/ModalPopup";
import ModalPopupPictureInPicture from "../popup/ModalPopup";
import {connect} from "react-redux";
import {checkIsComplete, increaseSessionTime} from "features/scorm/scormSlice";

interface IProps {
    params?: any;
    videoIndex?: any;
    seekPos?: any;
    seekFromTimeLine?: number;
    autoPlay?: boolean,
    isContainCur: boolean,
    subscription: object,
    onAuthenticationChanged: any,
    isWriting?: boolean,
    videoCtrlRef: any,
    poster: string,
    relatedContent: any,
    isShowLargeVideo: boolean,
    setShowLargeVideo: any,
    onSwapContentWhenEnlarge: any,
    checkIsComplete: any,
    increaseSessionTime: any,
}

interface IState {
    bitdata: any;
    playbackRate: any;
    playing: any;
    videoIndex: any;
    xmlrpc3iParams: any;
    isReady: boolean,
    isShowErrorSync: boolean,
    autoPlay: boolean,
    isShowRelatedContent: boolean,
    timeOutOpenRelatedContent: any,
    countdownSeconds: number,
    intervalCountdownSeconds: any,
    isShowConfirmFullScreen: boolean,
    isShowConfirmPictureInPicture: boolean,
}

interface Video {
    volume: any,
    addRemoteTextTrack: any,
    remoteTextTracks: any,
    currentTime: any,
    playbackRate: any,
    play: any;
    seeking: any;
}

interface TrackProps {
    kind: string;
    src: string;
    srcLang: string;
    default: boolean;
    label: string;
}

class Player extends PlayerAbstract<IProps, IState> {

    constructor(props) {
        super(props);
        this.controlsBarRef = React.createRef();
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let autoPlay = params.get('autoplay') === 'true';
        this.state = {
            bitdata: "",
            playbackRate: 1.0,
            playing: this.props.autoPlay,
            videoIndex: 0,
            xmlrpc3iParams: "",
            isReady: false,
            isShowErrorSync: false,
            autoPlay: autoPlay,
            isShowRelatedContent: false,
            timeOutOpenRelatedContent: {},
            countdownSeconds: 5,
            intervalCountdownSeconds: {},
            isShowConfirmFullScreen: false,
            isShowConfirmPictureInPicture: false,
        };
    }

    componentDidUpdate(previousPos, previousState) {
        if (this.props.seekFromTimeLine !== previousPos.seekFromTimeLine) {
            this.handleSeekToPos();
        }
        if (previousState.bitdata == "" || previousState.bitdata == undefined) {
            this.setState({bitdata: this.props.params?.tcbit?.bit_data});
        }
    }

    onPlayerReady = (player) => {
        this.videoPlayer = player;
        const u = StringUtil.getCookieByName("u");
        const volumePlayer = localStorage.getItem(`volumePlayer_${u}`);
        if (volumePlayer) {
            this.videoPlayer.volume(Number.parseFloat(volumePlayer));
        } else {
            this.videoPlayer.volume(0.5);
        }
        const subscriptionList = this.renderSubscriptionObject(this.props.subscription);
        subscriptionList.sort(function (a, b) {
            return Constant.SORT_ORDER_LANGUAGE.indexOf(b.srcLang) - Constant.SORT_ORDER_LANGUAGE.indexOf(a.srcLang);
        });
        subscriptionList.forEach(item => this.videoPlayer.addRemoteTextTrack(item, false));
        this.handleProgress();
        this.handleOnVideoReady();
    }

    handleProgress = () => {
        let playedPosition = this.videoPlayer.currentTime();
        let position = parseInt(playedPosition, 10);
        this.videoTime = position;
        let air_Id = this.props.params?.tcbit?.air_id;
        let data = this.props.params?.tcbit?.data;
        let play_Id = this.props.params?.tcbit?.play_id;
        let lo_Id = this.props.params?.tcbit?.lo_id;
        let currentBitdata = "";

        if (this.state.bitdata != undefined) {
            currentBitdata = this.state.bitdata;
        }

        // Update appBody video position for slider and memo
        this.props.videoIndex(position);
        this.setState({videoIndex: position})

        let previousPos = this.props.params?.tcbit?.current_position;
        let forcePos = this.props.seekPos;
        if (forcePos >= 0 && this.initialPage) {
            this.loadVideoToTime(forcePos);
            this.initialPage = false;
        } else if (previousPos != null && previousPos != 0 && this.initialPage) {
            if (this.videoPlayer.duration() && Math.abs(previousPos - this.videoPlayer.duration()) < 1) {
                this.loadVideoToTime(0);
            } else {
                this.loadVideoToTime(previousPos);
            }
            this.initialPage = false;
        }

        // check video is seeked update counter equal postion
        if (this.videoPlayer.seeking()) {
            this.counter = Math.floor(position / 10) * 10;
        }

        //reset counter when play video again
        let syncCounter = position - this.counter;
        let syncRateBase = 10;
        if (syncCounter < 0) {
            this.counter = 0;
            syncCounter = position;
        }

        if ((syncCounter >= syncRateBase) && position !== 0) {
            this.counter = Math.floor(position / 10) * 10;
            // Update Bitdata
            let pos = Math.floor(position / 10) - 1;
            let strs = currentBitdata.split('');
            // 9 より大きい数字にしない
            let m = (function (v) {
                return v < 9 ? v += 1 : v;
            })(+strs[pos]);
            strs[pos] = m.toString(10);
            currentBitdata = strs.join('');

            // Send data to server
            if (!this.isSeeking) {
                let params = {
                    playId: (play_Id) ? parseInt(play_Id, 10) : 0,
                    loId: (lo_Id) ? parseInt(lo_Id, 10) : 0,
                    airId: (air_Id) ? parseInt(air_Id, 10) : 0,
                    bitData: currentBitdata,
                    data: data,
                    currentPosition: position,
                };
                this.setState({xmlrpc3iParams: params})
                this.updateVideoPosition(params);
                this.setState({bitdata: currentBitdata});
            }
            this.isSeeking = false;
            this.isSeeked = false;
        }
    }

    // load video to the number of minutes saved
    loadVideoToTime = (time) => {
        const video  = document.getElementById('video-player_html5_api');
        if (video) {
            video.addEventListener('loadeddata', () => {
                this.videoPlayer.currentTime(time);
            })
        }
    }

    onEnd = () => {
        let bitDataLength = this.props.params?.tcbit?.bit_data.length;
        // get last value bit_data in server
        let bitDataPros = this.props.params?.tcbit?.bit_data[bitDataLength - 1];
        // get last value bit_data in current
        let bitDataState = this.state.bitdata[bitDataLength - 1];

        let playedPosition = this.videoPlayer.currentTime();
        let position = parseInt(playedPosition, 10);

        if (this.props.params?.content?.prg_duration - position === 1 && this.props.params?.content?.prg_duration - this.counter === 1) {
            position++;
        }

        let air_Id = this.props.params?.tcbit?.air_id;
        let data = this.props.params?.tcbit?.data;
        let play_Id = this.props.params?.tcbit?.play_id;
        let lo_Id = this.props.params?.tcbit?.lo_id;
        let currentBitdata = "";
        if (this.state.bitdata != undefined) {
            currentBitdata = this.state.bitdata;
        }

        let syncCounter = position - this.counter;
        let syncRateBase = 10;
        if (syncCounter < 0) {
            this.counter = 0;
            syncCounter = position;
        }

        if (syncCounter < syncRateBase && syncCounter !== 0 && !this.isSeeked) {
            // update counter when the video is running for 10 seconds
            this.counter = Math.floor((position + (position - this.counter)) / 10) * 10;
            let strs = currentBitdata.split('');
            // video is end get last position of the bitData
            let pos = strs.length - 1;
            let m = (function (v) {
                return v < 9 ? v += 1 : v;
            })(+strs[pos]);
            strs[pos] = m.toString(10);
            currentBitdata = strs.join('');
            let params = {
                playId: (play_Id) ? parseInt(play_Id, 10) : 0,
                loId: (lo_Id) ? parseInt(lo_Id, 10) : 0,
                airId: (air_Id) ? parseInt(air_Id, 10) : 0,
                bitData: currentBitdata,
                data: data,
                currentPosition: position,
            };
            this.setState({xmlrpc3iParams: params})
            this.updateVideoPosition(params);
            this.setState({bitdata: currentBitdata});
        }

        if (Object.keys(this.props.relatedContent).length !== 0) {
            // show related content
            this.setState({
                isShowRelatedContent: true
            }, () => {
                // set state countdownSeconds down 1 second
                let countdownSeconds = 5;
                this.setState({
                    countdownSeconds: countdownSeconds
                });
                if (countdownSeconds > 0) {
                    this.setState({
                        intervalCountdownSeconds: setInterval(() => {
                            countdownSeconds = countdownSeconds - 1;
                            this.setState({
                                countdownSeconds: countdownSeconds
                            });
                        }, 1000)
                    });
                }

                // open related content after 5 seconds
                this.setState({
                    timeOutOpenRelatedContent: setTimeout(() => {
                        window.location.href = this.openRelatedContent();
                    }, 5000)
                })
            })
        }
    }

    openRelatedContent = () => {
        let urlRelatedContent = StringUtil.getUrlRelatedContents(this.props.relatedContent.prg_per_id);
        let url = new URL(urlRelatedContent);
        let params = new URLSearchParams(url.search);
        params.set('autoplay', 'true');
        url.search = params.toString();
        return url.href;
    }

    handleSeekToPos = () => {
        let seekToPos = this.props.seekPos;
        if (seekToPos < 0) {
            seekToPos = 0
        }
        this.videoPlayer.currentTime(seekToPos);
        this.videoPlayer.play();
        this.setState({playing: true});
    }

    handleSetPlaybackRate = (playbackRate) => {
        this.videoPlayer.playbackRate(parseFloat(playbackRate));
        this.setState({playbackRate: parseFloat(playbackRate)});
    }

    qualitySelected = () => {
        if (!this.videoPlayer) return;
        this.videoPlayer.remoteTextTracks();
        const subscriptionList = this.renderSubscriptionObject(this.props.subscription);
        subscriptionList.sort(function (a, b) {
            return Constant.SORT_ORDER_LANGUAGE.indexOf(b.srcLang) - Constant.SORT_ORDER_LANGUAGE.indexOf(a.srcLang);
        });
        subscriptionList.forEach(item => {
            this.videoPlayer.addRemoteTextTrack({
                ...item,
                default: item.label === this.selectedLang
            }, false)
        });
    }

    renderSubscriptionObject = (subscription) => {
        let subscriptionList: TrackProps[] = [];
        if (subscription != undefined && subscription instanceof Array) {
            subscription.forEach(item => {
                subscriptionList.push({
                    kind: Constant.SUBSCRIPTION_KIND,
                    src: item.sourceUrl,
                    srcLang: item.languageName,
                    default: item?.languageCode?.toLowerCase() == Constant.JAPANESE_LANGUAGE_CODE,
                    label: item.languageName
                })
            });
        }
        return subscriptionList;
    };

    handleOnVideoReady = () => {
        this.setState({isReady: true});
        if (this.props.isContainCur) {
            //todo comment for feature autoplay video
            // this.setState({playing: true});
        }
    }

    onUpdateTime = (data, position) => {
        const second = data !== 1 ? Math.floor(data * position) : position;
        this.videoPlayer.currentTime(Number(second * 10));
        this.videoPlayer.play();
    }

    updateVideoPosition = (params) => {
        xmlRpcJsonServices.updateVideoPosition(params)
            .catch(() => this.setState({isShowErrorSync: true}));
        this.props.checkIsComplete(params.bitData);
        this.props.increaseSessionTime(10);
    }

    setShowRelatedContent = (isShowRelatedContent) => {
        this.setState({
            isShowRelatedContent: isShowRelatedContent
        })
    }

    largevideo = () => {
        this.props.setShowLargeVideo(!this.props.isShowLargeVideo);
        this.props.onSwapContentWhenEnlarge();
    }

    onConfirmFullScreen = () => {
        this.setState({
            isShowConfirmFullScreen: false
        });
        if (!this.videoPlayer.isFullscreen()) {
            this.videoPlayer.requestFullscreen();
        }
    }

    onConfirmPictureInPicture = () => {
        this.setState({
            isShowConfirmPictureInPicture: false
        });
        if (!this.videoPlayer.isInPictureInPicture()) {
            this.videoPlayer.requestPictureInPicture();
        }
    }

    isInPictureInPicture = () => {
        if (this.videoPlayer) {
            return this.videoPlayer.isInPictureInPicture();
        }
        return false;
    }

    showingTracks = (languageSrc, languageText) => {
        let tracks = this.videoPlayer.textTracks();
        for (let i = 0; i < tracks.length; i++) {
            let track = tracks[i];
            if (track.kind === 'subtitles' && (track.src === languageSrc || track.label === languageText)) {
                track.mode = 'showing';
            } else {
                track.mode = 'hidden';
            }
        }
    }

    render() {
        return (
            <>
                <div className={`player d-flex flex-column align-items-center ${this.props.isWriting ? "" : 'course-video'}`}>
                    <div className="progress rounded-0" role="progressbar" style={{'height': '5px'}}>
                        <ProgressBar bitdata={this.state.bitdata} onUpdateTime={this.onUpdateTime}/>
                    </div>
                    <div id="player-video" className="player-video" style={{width: '100%'}} ref={this.props.videoCtrlRef}>
                        <div className="embed-responsive embed-responsive-16by9">
                            <Authentication params={this.props.params}
                                            currentTime={this.videoTime}
                                            videoProgress={this.state.videoIndex}
                                            xmlrpc3iParams={this.state.xmlrpc3iParams}
                                            onAuthenticationChanged={this.props.onAuthenticationChanged}
                                            isPlaying={this.state.playing}
                                            isReady={this.state.isReady}
                                            isInPictureInPicture={this.isInPictureInPicture}
                            />

                            <div className="embed-responsive-item">
                                <VideoJS
                                    onTimeUpdate={this.handleProgress}
                                    onPlay={() => {
                                        this.setState({playing: true});
                                    }}
                                    onPause={() => this.setState({playing: false})}
                                    onReady={this.onPlayerReady}
                                    playbackRates={[]}
                                    onSeeking={() => this.isSeeking = true}
                                    onSeeked={(position, completeTime) => {
                                        this.isSeeking = false;
                                        this.videoTime = completeTime;
                                        this.isSeeked = true;
                                    }}
                                    src={this.renderVideoUrl(this.props.params)}
                                    qualitySelected={this.qualitySelected}
                                    qualityRequested={this.qualityRequested}
                                    onEnd={this.onEnd}
                                    poster={this.props.poster}
                                    autoplay={this.state.autoPlay}
                                    onConfirmFullScreen={() => {
                                        this.setState({isShowConfirmFullScreen: true});
                                    }}
                                    onConfirmPictureInPicture={() => {
                                        this.setState({isShowConfirmPictureInPicture: true});
                                    }}
                                    isShowConfirmModal={this.props.params?.telop !== undefined}
                                    reloadVideoUrl={this.reloadVideoUrl}
                                    thumbnails={this.props.params?.thumbnails?.item}
                                    slides={this.props.params?.slides?.item}
                                />
                            </div>
                            {
                                this.state.isShowRelatedContent &&
                                <RelatedContent relatedContent={this.props.relatedContent}
                                                openRelatedContent={this.openRelatedContent}
                                                timeOutOpenRelatedContent={this.state.timeOutOpenRelatedContent}
                                                setShowRelatedContent={this.setShowRelatedContent}
                                                countdownSeconds={this.state.countdownSeconds}
                                                intervalCountdownSeconds={this.state.intervalCountdownSeconds}/>
                            }
                        </div>
                        <ControlsBar
                            seekNext5Seconds={this.seekNext5Seconds}
                            seekNext10Seconds={this.seekNext10Seconds}
                            seekBack5Seconds={this.seekBack5Seconds}
                            seekBack10Seconds={this.seekBack10Seconds}
                            seekTo={this.handleSetPlaybackRate}
                            ref={this.controlsBarRef}
                            largevideo={this.largevideo}
                            isShowLargeVideo={this.props.isShowLargeVideo}
                            />
                    </div>
                </div>
                <Snackbar
                    open={this.state.isShowErrorSync}
                    onClose={() => this.setState({isShowErrorSync: false})}
                    autoHideDuration={5000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    children={<div className="alert alert-warning" style={{maxWidth: 350}}>受講データのサーバー登録に失敗しました。このアラートが連続して出る場合、通信環境に問題がある可能性があるため、ご利用中のネットワーク接続をご確認ください。</div>}
                />
                <ModalPopupFullScreen
                    isShow={this.state.isShowConfirmFullScreen}
                    title={"フルスクリーンモード"}
                    message={"フルスクリーンモードでは受講認証ボタンが表示されないため、受講認証ができません。フルスクリーンモードに切り替えますか？"}
                    positiveButton={"はい"}
                    negativeButton={"いいえ"}
                    onPositiveButtonClicked={this.onConfirmFullScreen}
                    onNegativeButtonClicked={() => {
                        this.setState({isShowConfirmFullScreen: false})
                    }}
                />
                <ModalPopupPictureInPicture
                    isShow={this.state.isShowConfirmPictureInPicture}
                    title={"ピクチャーインピクチャーモード"}
                    message={"ピクチャーインピクチャーモードでは受講認証ボタンが表示されないため、受講認証ができません。ピクチャーインピクチャーモードに切り替えますか？"}
                    positiveButton={"はい"}
                    negativeButton={"いいえ"}
                    onPositiveButtonClicked={this.onConfirmPictureInPicture}
                    onNegativeButtonClicked={() => {
                        this.setState({isShowConfirmPictureInPicture: false})
                    }}
                />
            </>
        );
    }
}

interface ProgressProps {
    bitdata: string,
    onUpdateTime?: any,
}

interface ProgressState {
    bitdata: string,
    fragment: any[]
}

class ProgressBar extends React.Component<ProgressProps, ProgressState> {
    constructor(props) {
        super(props);
        this.state = {bitdata: this.props.bitdata, fragment: []}
    }

    videoData = {
        len: 0,
        cell: 0,
        times: 0,
    }

    componentDidUpdate(previousPos, previousState) {
        if (this.props.bitdata !== previousPos.bitdata) {
            this.achievement();
        }
    }

    achievement = () => {

        let bitData = this.props.bitdata;
        if (bitData == undefined) {
            return
        }
        var strs = bitData.split('');
        var data = this.videoInfo(bitData);
        var classNameValue = '';
        var attributeValue = '';
        var value;
        for (let i = 0; i < data.len; i++) {
            var second = data.cell !== 1 ? Math.floor(data.cell * i) : i;
            attributeValue = second.toString();
            var bitAtSecond = strs[second];
            if (bitAtSecond !== '0') {
                classNameValue = 'achievement-line';
                value = <td data-second={attributeValue} onClick={()=> this.props.onUpdateTime(data.cell, i)} className={classNameValue} key={i}></td>;
            } else {
                value = <td data-second={attributeValue} onClick={()=> this.props.onUpdateTime(data.cell, i)} className="" key={i}></td>
            }
            this.state.fragment[i] = value;
        }
        this.setState({fragment: this.state.fragment});
    }

    videoInfo(bitData) {
        if (this.videoData.len == 0) {
            var strs = bitData.split('');
            var length = strs.length;
            var len = length < 640 ? length : 640;
            var cell = len < length ? length / len : 1;
            var times = _.range(0, len, cell).map(function (n) {
                return Math.floor(n);
            });
            this.videoData = {len: len, cell: cell, times: times};
        }
        return this.videoData;
    }

    render() {
        return <table style={{tableLayout: "fixed", width: '100%'}}>
            <tbody>
                <tr>{this.state.fragment}</tr>
            </tbody>
        </table>
    }
}

export default connect(null, {checkIsComplete, increaseSessionTime}, null, {forwardRef: true})(Player);
