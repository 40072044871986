import React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import "./Playlist.sass";
import axios from "axios";
import ModalAddItemToPlaylist from "../popup/ModalAddItemToPlaylist";
import {StringUtil} from "../util/StringUtil";
import ModalCreatePlayList from 'components/popup/ModalCreatePlayList';

interface IProps {
    prgID: string,
    messageAlert: any,
    setMessageAlert: any,
    isFavorite: boolean,
    setUrlFavoritePlaylist: any,
}

interface IState {
    isDropDownOpen: boolean,
    isFavorite: boolean,
    isShowModalAddItemToPlaylist: boolean,
    isShowModalCreatePlayList: boolean,
}

class DropdownPlaylist extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isDropDownOpen: false,
            isFavorite: false,
            isShowModalAddItemToPlaylist: false,
            isShowModalCreatePlayList: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.isFavorite !== prevProps.isFavorite) {
            if (this.props.isFavorite) {
                this.setState({
                    isFavorite: true
                })
            }
        }
    }

    addItemToFavorite = () => {
        const FAVORITE_PLAYLIST_TYPE = 3;
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        let dataRequest = {
            a,
            u,
            playlistType: FAVORITE_PLAYLIST_TYPE,
            prg_id: this.props.prgID
        };

        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/AppendContent?u=${u}&a=${a}&play_list_id=null&prg_id=${this.props.prgID}&play_list_type=${FAVORITE_PLAYLIST_TYPE}`,
            method: 'POST',
            data: dataRequest,
            headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then((res) => {
            this.setState({
                isFavorite: true
            });
            this.props.setMessageAlert({
                isShow: true,
                message: "お気に入り に追加しました",
                type: "success"
            }, 5000);
        }).catch((err) => {
        })
    }

    removeItemToFavorite = () => {
        const FAVORITE_PLAYLIST_TYPE = 3;
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        let dataRequest = {
            a,
            u,
            playlistType: FAVORITE_PLAYLIST_TYPE,
            prgIds: [this.props.prgID]
        };

        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/DeleteContent?u=${u}&a=${a}&prg_id=${this.props.prgID}&play_list_type=3`,
            method: 'POST',
            data: dataRequest,
            headers: { 'content-type': 'application/json; charset=UTF-8' },
        }).then((res) => {
            this.setState({
                isFavorite: false
            });
            this.props.setMessageAlert({
                isShow: true,
                message: "お気に入り から削除しました",
                type: "success"
            }, 5000);
        }).catch((err) => {
        })
    }

    showModalAddItemToPlaylist = () => {
        this.setState({
            isShowModalAddItemToPlaylist: true
        });
    }

    showModalCreatePlayList = () => {
        this.setState({
            isShowModalCreatePlayList: true
        });
    }

    setFavorite = () => {
        if (!this.state.isFavorite) {
            this.setState({
                isFavorite: true
            })
        }
    }

    render() {
        return (
            <>
                <Dropdown isOpen={this.state.isDropDownOpen}
                          toggle={() => {
                              this.setState({isDropDownOpen: !this.state.isDropDownOpen});
                          }}
                          style={{padding: 0}} className="dropdown-playlist">
                    <DropdownToggle nav style={{padding: 0}}>
                        <Tooltip
                            placement="right"
                            title="プレイリスト追加">
                            <a className="btn-swap">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                     height="16" fill="currentColor"
                                     className="bi bi-bookmark-plus" viewBox="0 0 16 16">
                                    <path
                                        d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4z"/>
                                </svg>
                            </a>
                        </Tooltip>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={this.state.isFavorite ? this.removeItemToFavorite : this.addItemToFavorite}>
                            {
                                this.state.isFavorite ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-heart-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                         height="16" fill="currentColor"
                                         className="bi bi-heart" viewBox="0 0 16 16">
                                        <path
                                            d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                    </svg>
                            }
                            &nbsp;お気に入りに追加</DropdownItem>
                        <DropdownItem onClick={this.showModalAddItemToPlaylist}>プレイリストに追加</DropdownItem>
                        <DropdownItem onClick={this.showModalCreatePlayList}>プレイリストを新規に作成</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <ModalAddItemToPlaylist
                    title={'プレイリストにコンテンツを追加'}
                    isShow={this.state.isShowModalAddItemToPlaylist}
                    onNegativeButtonClicked={() => {
                        this.setState({isShowModalAddItemToPlaylist: false})
                    }}
                    prgID={this.props.prgID}
                    setMessageAlert={this.props.setMessageAlert}
                    setFavorite={this.setFavorite}
                    setUrlFavoritePlaylist={this.props.setUrlFavoritePlaylist}/>
                <ModalCreatePlayList
                    title={'プレイリストを新規に作成'}
                    isShow={this.state.isShowModalCreatePlayList}
                    onNegativeButtonClicked={() => {
                        this.setState({isShowModalCreatePlayList: false})
                    }}
                    prgID={this.props.prgID}
                    setMessageAlert={this.props.setMessageAlert}/>
            </>
        );
    }
}

export default DropdownPlaylist;