import React from 'react';
import {StringUtil} from "../util/StringUtil";
import axios from "axios";
import {Constant} from "../constant/Constant";

interface Video {
    volume: any,
    addRemoteTextTrack: any,
    remoteTextTracks: any,
    currentTime: any,
    playbackRate: any,
    play: any;
    seeking: any;
    isFullscreen: any;
    requestFullscreen: any;
    isInPictureInPicture?: any;
    requestPictureInPicture?: any;
    textTracks: any;
    src: any;
    duration: any;
}

class PlayerAbstract<IProps, IState> extends React.Component<IProps, IState> {

    videoPlayer!: Video;
    initialPage = true;
    counter = 0;
    isSeeking = false;
    isSeeked = false;
    videoTime = 0;
    selectedLang = '';
    controlsBarRef: any;

    renderVideoUrl = (param) => {
        let sourceAkamaiList = param?.content?.strtoken?.item;
        if (!sourceAkamaiList) return [];
        const length = sourceAkamaiList.length;
        return sourceAkamaiList
            .sort((a, b) => a.attribute.weight - b.attribute.weight)
            .map((item, index) => {
                return {
                    src: `${item.attribute.host}${item.attribute.file}&ext=extract`,
                    type: 'video/mp4',
                    label: `${item.attribute.label}`,
                    selected: index + 1 === length,
                }
            })
    };

    qualitySelected = () => {
        if (!this.videoPlayer) return;
        this.videoPlayer.remoteTextTracks();
    }

    seekNext5Seconds = () => {
        this.isSeeking = true;
        let seekValue = 5;
        let position = this.videoPlayer.currentTime();
        let seekToPos = position + seekValue;
        this.videoPlayer.currentTime(seekToPos);
    }

    seekNext10Seconds = () => {
        this.isSeeking = true;
        let seekValue = 10;
        let position = this.videoPlayer.currentTime();
        let seekToPos = position + seekValue;
        this.videoPlayer.currentTime(seekToPos);
    }

    seekBack5Seconds = () => {
        this.isSeeking = true;
        let seekValue = -5;
        let position = this.videoPlayer.currentTime();
        let seekToPos = position + seekValue;
        if (seekToPos < 0) {
            seekToPos = 0
        }
        this.videoPlayer.currentTime(seekToPos);
    }

    seekBack10Seconds = () => {
        this.isSeeking = true;
        let seekValue = -10;
        let position = this.videoPlayer.currentTime();
        let seekToPos = position + seekValue;
        this.videoPlayer.currentTime(seekToPos);
    }

    qualityRequested = () => {
        if (!this.videoPlayer) return;
        // @ts-ignore
        const {el_: videoDOM} = this.videoPlayer;
        const controlBar = videoDOM.getElementsByClassName('vjs-control-bar');
        const ulDOM = controlBar[0] && controlBar[0].querySelector('.vjs-subs-caps-button ul.vjs-menu-content li.vjs-menu-item.vjs-selected')
        this.selectedLang = ulDOM && ulDOM.children[0].innerHTML;
    }


    reloadVideoUrl = () => {
        let userId = StringUtil.getCookieByName("u");
        let accessToken = StringUtil.getCookieByName("a");

        let broswerUrl = new URL(document.location.href);
        let cid = broswerUrl.searchParams.get("cid");
        let lid = broswerUrl.searchParams.get("lid");
        let cmplid = broswerUrl.searchParams.get("cmplid");
        let pid = broswerUrl.searchParams.get("pid");
        let sid = broswerUrl.searchParams.get("sid");

        let params:any = {
            cc: true,
            detail: true,
            ssl: true,
            strtoken: true,
            user: true,
            quality: true,
            autocc: true,
            format: "json",
            ext: "extract",
            a: accessToken,
            u: userId,
            prg: window.location.pathname.split("/")[2],
            cid,
            lid,
            cmplid,
            pid,
            sid,
        }

        if (!userId) {
            params.token = broswerUrl.searchParams.get("token");
        }

        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/GetPrgDetail`,
            method: "GET",
            params,
        })
        .then(res => {
            const currentTime = this.videoPlayer.currentTime();
            this.videoPlayer.src(this.renderVideoUrl(res.data.programdetail));
            this.videoPlayer.play();
            setTimeout(() => {
                this.videoPlayer.currentTime(currentTime);
                const video  = document.getElementById('video-player_html5_api');
                if (video) {
                    video.addEventListener('loadeddata', () => {
                        this.videoPlayer.currentTime(currentTime);
                    })
                }
            }, 1000);
        });
    }
}

export default PlayerAbstract;