import React from "react";
import Typography from '@material-ui/core/Typography';
import "./VideoTimeline.sass"
import {isIOS} from "../../helper/utils";
import {StringUtil} from "../util/StringUtil";

interface TimerInfo {
    index_time: number;
    index_title: string;
    index_type: number;
}
interface Attribute{
    attribute : TimerInfo;
}
interface IProps {
    slideItems: Attribute[],
    timeIndex?: number,
    updateActiveIndex: any,
    updateVideoTime?: any,
    onMouseEnterTimeline?: any,
    onMouseLeaveTimeline?: any,
}

interface IState {
    activeIndex?: number
}

class VideoTimeline extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activeIndex: 0
        };

    }

    convertTimeToIndex = (timeIndex) => {
        let activeIndex = 0;
        let min = Number.MAX_VALUE;
        this.props.slideItems?.forEach((item,index) => {
            let sub =  (timeIndex - item.attribute.index_time);
            if(sub >= 0 && sub <= min && item.attribute.index_type !== 1){
                min = sub;
                activeIndex = index;
            }
        });
        return activeIndex;
    };

    goToIndex = (index) => {
        this.setActiveIndex(index);
        setTimeout(function () {
            let top = document.getElementById('timeline-'+ index)?.offsetTop;
            if (top != null && window.screen.width >= 768) {
                // @ts-ignore
                document.getElementById('TimeTable').scrollTop = top;
            }
        },500);
    };

    setActiveIndex = (index) => {
        this.setState(prevState => ({
            activeIndex: index
        }))
    };

    render() {
        return <div className="video-timeline">
            <Typography variant="body1" className="mt-5">講義タイムテーブル</Typography>
            <ol id="TimeTable" className="list-group list-group-flush mt-2 pl-0 position-relative">
                {
                    this.props.slideItems?.map((value, index) => {
                        if (value.attribute.index_type !== 1) {
                            return <li key={index}
                                       data-target="#carouselExampleIndicators"
                                       data-slide-to={value.attribute.index_time}
                                       className={"list-group-item list-group-item-action " + ((index == this.state.activeIndex)?" gray-background" : "" )}
                                       id={'timeline-'+index}
                                       onClick={()=>{
                                           const timeIndex = isIOS() ? value.attribute.index_time + 1 : value.attribute.index_time;
                                           this.props.updateActiveIndex(timeIndex);
                                           this.props?.updateVideoTime(timeIndex)
                                       }}
                                       onMouseEnter={() => {
                                           const timeIndex = isIOS() ? value.attribute.index_time + 1 : value.attribute.index_time;
                                           this.props.onMouseEnterTimeline(timeIndex);
                                       }}
                                       onMouseLeave={() => {this.props.onMouseLeaveTimeline()}}>
                                <div className="row">
                                    <p className="col-3">{StringUtil.convertTimeIndexToTime(value.attribute.index_time)}</p>
                                    <p className="col-9 font-weight-bold">{value.attribute.index_title}</p>
                                </div>
                            </li>
                        }
                    })
                }
            </ol>
        </div>
    };
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if(this.props.timeIndex !== undefined){
            let targetIndex = this.convertTimeToIndex(this.props.timeIndex);
            if(targetIndex != prevState.activeIndex){
                this.goToIndex(targetIndex);
            }
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.timeIndex != nextProps.timeIndex ||
            this.state.activeIndex !== nextState.activeIndex
        );
    }

}

export default VideoTimeline;
