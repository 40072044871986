import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import {withTheme} from "@material-ui/styles";
import {ScormUrl} from "../video-description/VideoDescription";

interface Props {
    onClose: any,
    isShow: boolean,
    theme: any,
    scormUrlList: ScormUrl[],
    getUrlDownloadScormFile: (scormVersion: string, isHideBtnRegister: string, groupId?: number, projectId?: number, groupName?: string) => void,
}

const ModalDownloadScorm = (props: Props) => {
    const [groupProject, setGroupProject] = useState<string>();
    const [isHideBtnRegister, setHideBtnRegister] = useState<string>("false");
    const [scormVersion, setScormVersion] = useState<string>("SCORM_12");
    const handleDownloadScorm = () => {
        const group = Number.parseInt(groupProject?.split("-")[0] || "");
        const project = Number.parseInt(groupProject?.split("-")[1] || "");
        const groupName = props.scormUrlList.find(item => item.group_id === group)?.group_name;
        props.onClose();
        props.getUrlDownloadScormFile(scormVersion, isHideBtnRegister, group, project, groupName);
    }

    useEffect(() => {
        if (props.scormUrlList.length > 0) {
            setGroupProject(`${props.scormUrlList[0]?.group_id}-${props.scormUrlList[0]?.project_id}`);
        }

    }, [props.scormUrlList]);

    return <Modal isOpen={props.isShow} data-theme={props.theme?.palette?.type} className="modal-table-keyboard-shortcuts">
        <ModalHeader toggle={props.onClose}>
            SCORMファイルをダウンロード
        </ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="exampleSelect">
                    進捗管理グループ／研修
                </Label>
                <Input
                    id="grouptProjectSelect"
                    name="select"
                    type="select"
                    value={groupProject}
                    onChange={(event) => {
                        setGroupProject(event.target.value)
                    }}
                >
                    {
                        props.scormUrlList.map((scormUrl, index) => {
                            return <option value={`${scormUrl.group_id}-${scormUrl.project_id}`} key={index} selected={true}>{`【${scormUrl.group_name}】【${scormUrl.project_name}】`}</option>
                        })
                    }
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="exampleSelect">
                    新規ID発行ボタンの表示
                </Label>
                <Input
                    id="grouptSelect"
                    name="select"
                    type="select"
                    value={isHideBtnRegister}
                    onChange={(event) => {
                        setHideBtnRegister(event.target.value)
                    }}
                >
                    <option value="false">新規ID発行ボタンを表示する</option>
                    <option value="true">新規ID発行ボタンを表示しない</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="exampleSelect">
                    SCORMバージョン
                </Label>
                <Input
                    id="grouptSelect"
                    name="select"
                    type="select"
                    value={scormVersion}
                    onChange={(event) => {
                        setScormVersion(event.target.value)
                    }}
                >
                    <option value="SCORM_12">SCORM 1.2</option>
                    <option value="SCORM_2004">SCORM 2004</option>
                </Input>
            </FormGroup>
            <FormGroup className="d-flex justify-content-center">
                <Button className="mr-5" outline onClick={props.onClose}>
                    キャンセル
                </Button>
                <Button color="primary" onClick={handleDownloadScorm}>
                    ダウンロード
                </Button>
            </FormGroup>
        </ModalBody>
    </Modal>
}

export default withTheme(ModalDownloadScorm);
