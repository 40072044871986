import React from "react";
import AppBody from "../components/body/AppBody";
import "./Landing.sass";
import axios from 'axios';
import {Constant} from "../components/constant/Constant";
import {StringUtil} from "../components/util/StringUtil";
import {Link} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {LoadingScreen} from "../components/loading/LoadingScreen";
import {connect} from "react-redux";
import { checkIsComplete } from "features/scorm/scormSlice";

interface IProps {
  params: string;
  onRemoveSubtitle?: any;
  isScorm: boolean;
  prgIdScorm: string;
  cid: string;
  lid: string;
  cmplid: string;
  checkIsComplete: any;
}

interface IState {
  programDetail?: any,
  prgId: string,
  subscriptions: object,
  isContentFree: boolean,
  breadcrumbs: any,
  isShowLoading: boolean,
  token: string | null,
  noContent: boolean,
}

class Landing extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let prgId = this.props.isScorm ? this.props.prgIdScorm : this.props.params.split("/")[2];
    this.state = {
      programDetail: {},
      prgId: prgId,
      subscriptions: {},
      isContentFree: false,
      breadcrumbs: [],
      isShowLoading: true,
      token: null,
      noContent: false,
    }
  }

  componentDidMount() {
    let userId = this.getCookieByName("u");
    let accessToken = this.getCookieByName("a");

    let broswerUrl = new URL(document.location.href);
    let cid = broswerUrl.searchParams.get("cid");
    let lid = broswerUrl.searchParams.get("lid");
    let cmplid = broswerUrl.searchParams.get("cmplid");
    let pid = broswerUrl.searchParams.get("pid");
    let sid = broswerUrl.searchParams.get("sid");

    let params:any = {
        cc: true,
        detail: true,
        ssl: true,
        strtoken: true,
        user: true,
        quality: true,
        autocc: true,
        format: "json",
        ext: "extract",
        a: accessToken,
        u: userId,
        prg: this.state.prgId,
        cid,
        lid,
        cmplid,
        pid,
        sid,
        thumb: true,
    }

    if (!userId) {
        params.token = broswerUrl.searchParams.get("token");
    }

    if (window.tokenScorm && !userId) {
        params.token = window.tokenScorm;
    }

    if (this.props.isScorm) {
        params.cid = this.props.cid;
        params.lid = this.props.lid;
        params.cmplid = this.props.cmplid;
    }

    axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}/GetPrgDetail`,
        method: "GET",
        params,
    })
    .then(async res => {
        let data = res.data.programdetail;
        data = await this.redirectAirCumpus(data, params);
        let subscriptions :Object[] = [];
        if (res != undefined && res.data != undefined) {
          if (data.captioning) {
            data.captioning.item.forEach((item) => {
                if (item.attribute.is_hide_subtitle !== true) {
                    subscriptions.push({
                        sourceUrl: item.attribute.url.indexOf(Constant.HTTPS) >= 0 ? item.attribute.url : item.attribute.url.replace(Constant.HTTP, Constant.HTTPS),
                        languageName: item.attribute.label,
                    });
                }
            });
          }
        }
        this.setState({
          subscriptions: subscriptions,
          programDetail: data
        });
        this.appendMetaTagToHead('og:title', data.content.prg_title);
        this.appendMetaTagToHead('og:contents', data.content.prg_title);
        this.appendMetaTagToHead('og:description', data.content.prg_theme);
        this.appendMetaTagToHead('og:url', document.location.href);
        this.appendMetaTagToHead('og:site_name', 'ビジネス・ブレークスルー');
        let imgUrl;
        if (data.content.prg_per_id !== null) {
            imgUrl = process.env.REACT_APP_PROGRAM_THUMB_URL + data.content.prg_per_id + "/thumb_c/Cut00002.jpg";
        } else {
            imgUrl = process.env.REACT_APP_OGP_IMAGE_URL;
        }
        this.appendMetaTagToHead('og:image', imgUrl);
        if (data && data.tcbit && data.tcbit.bit_data) {
            this.props.checkIsComplete(data.tcbit.bit_data);
        }

        if (params.token && data.user.accept) {
            this.setState({
                isContentFree: true
            })
        }

        if (userId && data.user.accept && broswerUrl.searchParams.get("token")) {
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            this.setState({
                token: params.get("token"),
            });
            // params.delete("token");
            url.search = params.toString();
            window.history.pushState({}, "", url.href);
        }

        if (userId && !data.user?.accept && broswerUrl.searchParams.get("token")) {
            params.token = broswerUrl.searchParams.get("token");
            let res = await axios({
                url: `${process.env.REACT_APP_API_ENDPOINT}/GetPrgDetail`,
                method: "GET",
                params,
            });
            const data = res.data.programdetail;
            if (data.user.accept) {
                let programDetail = this.state.programDetail;
                programDetail.content.strtoken = data.content.strtoken;
                programDetail.content.ssltoken = data.content.ssltoken;
                this.setState({
                    isContentFree: true,
                    programDetail: programDetail
                });
            }
        }
    }).catch(err => {
        if (params.token && err.response?.status === 400) {
            sessionStorage.setItem('messageAlert', JSON.stringify({
                isShow: true,
                message: `<p>このコンテンツは、現在無料視聴の対象ではありません。</p>
                            <p>他の無料視聴コンテンツはこちらをご覧ください。</p>
                            <a href="${process.env.REACT_APP_AIR_SEARCH_ENDPOINT}">AirSearchのHome</a>`,
                type: "success"
            }));
            if (!this.props.isScorm) {
                window.location.href = window.location.pathname;
            }
        }
        if(err.response?.status === 500){
            this.setState({
                noContent: true,
            });
        }
        if(err.response?.status === 400 && err.response?.data?.programdetail?.error?.message?.startsWith("There are no videos.")){
            this.setState({
                noContent: true,
            });
        }
    }).finally(() => {
        this.setState({
            isShowLoading: false,
        });
        this.setBreadcrumbs();
    });
  }

  redirectAirCumpus = async (programdetail, params) => {
    if (programdetail?.user?.learnings?.item?.length === 1 && !params.cmplid && !params.pid) {
        let checkAirSearchAvailableParam = {
            u: params.u,
            a: params.a,
        }
        let checkAirSearchAvailable = await axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/CheckAirSearchAvailable`,
            method: "GET",
            params: checkAirSearchAvailableParam,
        });
        if (!checkAirSearchAvailable.data?.status) {
            const {attribute: {pack_code, c_sbj_id, c_lec_id, cmpl_id, course_code}} = programdetail?.user?.learnings?.item[0];
            let url = new URL(window.location.href);
            let urlSearchParams = new URLSearchParams(url.search);
            if (cmpl_id) {
                params.cmplid = cmpl_id;
                params.lid = c_lec_id;
                params.cid = course_code;
                urlSearchParams.set("cmplid", cmpl_id);
                urlSearchParams.set("lid", c_lec_id);
                urlSearchParams.set("cid", course_code);
            } else {
                params.pid = pack_code;
                params.sid = c_sbj_id;
                params.lid = c_lec_id;
                urlSearchParams.set("pid", pack_code);
                urlSearchParams.set("sid", c_sbj_id);
                urlSearchParams.set("lid", c_lec_id);
            }
            url.search = urlSearchParams.toString();
            window.history.pushState({}, "", url.href);

            let response = await axios({
                url: `${process.env.REACT_APP_API_ENDPOINT}/GetPrgDetail`,
                method: "GET",
                params,
            })
            return response.data.programdetail;
        }
    }
    return programdetail;
  }

  setBreadcrumbs = () => {
      let url = new URL(window.location.href);
      let searchParams = new URLSearchParams(url.search);
      let urlBack = searchParams.get("urlBack") || "";
      if (!urlBack) {
          urlBack = sessionStorage.getItem("urlBack" + window.location.href) || "";
      }

      if (urlBack) {
          searchParams.delete("urlBack");
          url.search = searchParams.toString();
          window.history.pushState({}, "", url.toString());
          const breadcrumbs = [
              <Link target="_blank" href={process.env.REACT_APP_AIR_SEARCH_ENDPOINT} style={{color: "#4b566b"}}>
                  <i className="czi-home"/>Home</Link>
          ];

          let urlBreadcrumbs = new URL(urlBack);
          let keyword = urlBreadcrumbs.searchParams.get("keyword");
          let typeSearch = urlBreadcrumbs.searchParams.get("typeSearch") || "";

          if (keyword) {
              breadcrumbs.push(<Link target="_blank" href={urlBack}>{`”${keyword}”の検索結果`}</Link>);
          } else if(urlBreadcrumbs.toString().includes("/svlAirSearch/search")) {
              let catName = urlBreadcrumbs.searchParams.get("catName") || "";
              let subCatName = urlBreadcrumbs.searchParams.get("subCatName") || "";

              if (catName) {
                  switch (typeSearch) {
                      case "IS_WATCHING":
                          let urlIsWatching = process.env.REACT_APP_AIR_SEARCH_ENDPOINT + `search?catName=${catName}&isParent=true&typeSearch=IS_WATCHING`;
                          breadcrumbs.push(this.createBreadcrumbsLink(urlIsWatching, catName));
                          break;
                      case "PLAYLIST_DETAIL":
                          breadcrumbs.push(this.createBreadcrumbsLink(process.env.REACT_APP_AIR_SEARCH_ENDPOINT + "my-playlist", catName));
                          break;
                      case "FOLLOWING_DETAIL":
                          let isAssign = urlBreadcrumbs.searchParams.get("assign") === "true";
                          let pathName = "my-followingList";
                          if (isAssign) {
                              pathName = "my-assign"
                          }
                          breadcrumbs.push(this.createBreadcrumbsLink(process.env.REACT_APP_AIR_SEARCH_ENDPOINT + pathName, catName));
                          break;
                      default:
                          let urlCategory = process.env.REACT_APP_AIR_SEARCH_ENDPOINT + `search?catName=${catName}&isParent=true`;
                          breadcrumbs.push(this.createBreadcrumbsLink(urlCategory, catName));
                  }
              }
              if (subCatName) {
                  breadcrumbs.push(<Link target="_blank" href={urlBack}>{subCatName}</Link>);
              }
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/getAllMemos?onlyFavorite=true")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "お気に入りスライド一覧"));
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/getAllMemos")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "講義メモ一覧"));
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/my-playlist")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "マイプレイリスト"));
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/my-assign")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "アサイン中のプレイリスト"));
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/my-followingList")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "フォロー中のプレイリスト"));
          } else if (urlBreadcrumbs.toString().includes("/svlAirSearch/my-archive")) {
              breadcrumbs.push(this.createBreadcrumbsLink(urlBack, "アーカイブしたプレイリスト"));
          }

          breadcrumbs.push(<Typography >{this.state.programDetail?.content?.prg_title}</Typography>);
          this.setState({breadcrumbs: breadcrumbs});
          sessionStorage.setItem("urlBack" + window.location.href, urlBack);
      }
  }

  createBreadcrumbsLink = (url, text) => {
      return <Link target="_blank" href={url}>{text}</Link>;
  }

  appendMetaTagToHead = (propertyValue, contentValue) => {
    const meta = document.createElement('meta');
    meta.setAttribute('property', propertyValue);
    meta.setAttribute('content', contentValue);
    document.head.appendChild(meta);
  }

  renderSubscriptionApi = (sourceUrl: any)=>{
     return StringUtil.stringFormat(process.env.REACT_APP_API_ENDPOINT + sourceUrl.replace(Constant.AC_PATH, Constant.STRING_EMPTY));
  }
  getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';').shift();
  }

  onRemoveSubtitle = () => {
    let userId = this.getCookieByName("u");
    let accessToken = this.getCookieByName("a");

    let broswerUrl = new URL(document.location.href);
    let cid = broswerUrl.searchParams.get("cid");
    let lid = broswerUrl.searchParams.get("lid");
    let cmplid = broswerUrl.searchParams.get("cmplid");
    let pid = broswerUrl.searchParams.get("pid");
    let sid = broswerUrl.searchParams.get("sid");

    const params = {
      cc: true,
      detail: true,
      ssl: true,
      strtoken: true,
      user: true,
      quality: true,
      autocc: true,
      format: "json",
      ext: "extract",
      a: accessToken,
      u: userId,
      prg: this.state.prgId,
      cid,
      lid,
      cmplid,
      pid,
      sid,
      thumb: true,
    }
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/GetPrgDetail`,
      method: "GET",
      params,
    })
    .then(res => {
        const data = res.data.programdetail;
        let subscriptions :Object[] = [];
        if (res != undefined && res.data != undefined) {
          if (data.captioning) {
            data.captioning.item.forEach((item) => {
                if (item.attribute.is_hide_subtitle !== true) {
                    subscriptions.push({
                        sourceUrl: item.attribute.url.indexOf(Constant.HTTPS) >= 0 ? item.attribute.url : item.attribute.url.replace(Constant.HTTP, Constant.HTTPS),
                        languageName: item.attribute.label,
                    });
                }
            });
          }
        }
        this.setState({
          subscriptions: subscriptions,
          programDetail: data
        });
    });
  }

  render() {
     return <>
         <AppBody
             params = {this.state.programDetail}
             prgID={this.state.prgId}
             subscriptions={this.state.subscriptions}
             onRemoveSubtitle={this.onRemoveSubtitle}
             isContentFree={this.state.isContentFree}
             breadcrumbs={this.state.breadcrumbs}
             isShowLoading={this.state.isShowLoading}
             token={this.state.token}
             noContent={this.state.noContent}/>
         {this.state.isShowLoading && <LoadingScreen/>}
     </>;
  }
}

const mapStateToProps = (state) => ({
    isScorm: state.scorm.isScorm,
    prgIdScorm: state.scorm.prgIdScorm,
    cid: state.scorm.cid,
    lid: state.scorm.lid,
    cmplid: state.scorm.cmplid,
});
export default connect(mapStateToProps, {checkIsComplete}, null, {forwardRef: true})(Landing);
