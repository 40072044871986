import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {StringUtil} from "../../components/util/StringUtil";

export interface SettingSliceState {
    isHideNumberFavorite: boolean,
    fontSizeSubtitle: string,
}

const userId = StringUtil.getCookieByName("u");
const hideNumberFavorite = `hideNumberFavorite_${userId}`;
const fontSizeSubtitle = `fontSizeSubtitle_${userId}`;

const initialState: SettingSliceState = {
    isHideNumberFavorite: localStorage.getItem(hideNumberFavorite) ? localStorage.getItem(hideNumberFavorite) === 'true' : false,
    fontSizeSubtitle: localStorage.getItem(fontSizeSubtitle) || '22',
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setHideNumberFavorite: (state, action: PayloadAction<boolean>) => {
            state.isHideNumberFavorite = action.payload;
            localStorage.setItem(hideNumberFavorite, String(action.payload))
        },
        setFontSizeSubtitle: (state, action: PayloadAction<string>) => {
            state.fontSizeSubtitle = action.payload;
            localStorage.setItem(fontSizeSubtitle, action.payload)
        },
    },
})

export const { setHideNumberFavorite, setFontSizeSubtitle } = settingSlice.actions

export default settingSlice.reducer