import React from "react";
import "./AppBody.sass";
import "./dark-theme.sass";
import Paper from '@material-ui/core/Paper';
import {withTheme} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Player from '../player/player';
import VideoDescription, {ScormUrl} from "../video-description/VideoDescription";
import VideoTimeline from "../video-timeline/VideoTimeline";
import VideoSlider from "../video-slider/VideoSlider";
import Header from "../header/Header";
import Authentication from "../authentication/Authentication";
import Learnings from "../learnings/Learnings";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import {isMobile} from "../../helper/utils";
import SwitchTheme from "./SwitchTheme";
import CommentExtend from "../comment-extend/CommentExtend";
import IconButton from "@material-ui/core/IconButton";
import SliderDownload from "../video-slider/SliderDownload";
import {Theme, ThemeContext} from '../../ThemeContext';
import {Constant} from "../constant/Constant";
import ModalKeyboardShortcuts from "../popup/ModalKeyboardShortcuts";
import PlayerFree from "../player/PlayerFree";
import DropdownPlaylist from "../playlist/DropdownPlaylist";
import {UncontrolledAlert} from "reactstrap";
import {Breadcrumbs} from "@material-ui/core";
import axios from "axios";
import ModalLoginScorm from "../popup/ModalLoginScorm";
import {connect} from "react-redux";

const ISMobile = isMobile();

interface IProps {
    params?: any,
    prgID: string,
    subscriptions: object,
    onRemoveSubtitle?: any,
    theme?: any,
    isContentFree: boolean,
    breadcrumbs?: any,
    isShowLoading: boolean,
    token: string | null,
    isScorm: boolean,
    fontSizeSubtitle: string,
    noContent: boolean,
    groupId: string,
    projectId: string,
    isHideBtnRegister: boolean
}

interface IState {
    isSwapLayout: boolean,
    timeIndex: number,
    isAuthentication: boolean,
    manualMode: boolean,
    seekTime: number,
    videoAuth: boolean,
    seekFromTimeLine: number,
    autoPlay: boolean,
    isContainCur: boolean
    authentication: string,
    isWriting: boolean,
    videoDesRef?: any,
    slideList?: any,
    isShowQuickComment: boolean,
    lecCommentLength: any,
    isShowModalKeyboardShortcuts: boolean | false,
    relatedContent: any,
    isShowLargeVideo: boolean,
    messageAlert: any,
    urlFavoritePlaylist: string,
    isShowBtnLoginRegister: boolean,
    isShowModalLoginScorm: boolean | false,
    registerScormUrl: ScormUrl | null,
}

class AppBody extends React.Component<IProps, IState> {
    videoDesWrapRef: any;
    videoDesRef: any;
    videoCtrlRef: any;
    videoSliderRef: any;
    commendExtendRef: any;
    tmpFavoriteList: any;
    playerRef: any;
    commentExtendRef: any;
    contentRef: any;
    contentLargeVideoRef: any;
    videoSliderClassRef: any;
    authentication: Authentication;

    static contextType = ThemeContext;

    constructor(props) {
        super(props);
        let authentication = new Authentication();
        this.authentication = authentication;
        let isAuthentication = authentication.checkCookieHaveUsernameAndToken();
        let url = new URL(document.location.href);
        let current = url.searchParams.get("cur");
        let isContainCur = !(current === null || current === undefined)
        let currentNum = -1;
        this.videoDesWrapRef = React.createRef();
        this.videoDesRef = React.createRef();
        this.videoCtrlRef = React.createRef();
        this.videoSliderRef = React.createRef();
        this.commendExtendRef = React.createRef();
        this.playerRef = React.createRef();
        this.commentExtendRef = React.createRef();
        this.contentRef = React.createRef();
        this.contentLargeVideoRef = React.createRef();
        this.videoSliderClassRef = React.createRef();
        if (current != null) {
            currentNum = current === "" ? 0 : parseInt(current);
        }
        this.state = {
            isSwapLayout: false,
            timeIndex: currentNum,
            isAuthentication: isAuthentication,
            manualMode: false,
            seekTime: currentNum,
            videoAuth: false,
            seekFromTimeLine: 0,
            autoPlay: false,
            isContainCur: isContainCur,
            authentication: this.props.params?.tcbit?.data,
            isWriting: false,
            isShowQuickComment: false,
            lecCommentLength: 0,
            isShowModalKeyboardShortcuts: false,
            relatedContent: {},
            isShowLargeVideo: false,
            messageAlert: {
                isShow: false,
                message: "",
                type: "success"
            },
            urlFavoritePlaylist: "",
            isShowBtnLoginRegister: false,
            isShowModalLoginScorm: false,
            registerScormUrl: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params !== this.props.params) {
            this.setState({videoAuth: this.checkVideoAuth(nextProps)});
            this.setState({slideList: nextProps.params ? nextProps.params.slides?.item : []});

            // Set browser tab title
            // Set browser tab title
            let title = `${nextProps.params.content?.lec_name} | ${nextProps.params.content?.prg_theme} ${nextProps.params.content?.prg_title} | Aoba-BBT AirSearch`;
            // Replace br tag with space
            title = title.replace(/<br\s*\/?>/gi, " ");
            // Remove all html tag
            title = title.replace(/(<([^>]+)>)/gi, "");
            document.title = title;
        }
    }

    componentDidMount() {
        window.addEventListener('keyup', (event) => {
            if (document.activeElement?.tagName !== 'TEXTAREA' && document.activeElement?.tagName !== 'INPUT') {
                event.preventDefault();
                if (event.keyCode === Constant.KEYCODE_F) {
                    this.onSwapElement();
                }

                if (event.keyCode === Constant.KEYCODE_D) {
                    let themeContext = this.context;
                    sessionStorage.setItem("ThemeContext", themeContext.theme === Theme.Light ? Theme.Dark : Theme.Light);
                    themeContext.setTheme(themeContext.theme === Theme.Light ? Theme.Dark : Theme.Light);
                }

                if (event.keyCode === Constant.KEYCODE_COMMA) {
                    let playbackRate = this.playerRef.current.state.playbackRate - 0.1;
                    if (playbackRate >= 0.8) {
                        playbackRate = parseFloat(playbackRate.toFixed(1));
                        this.playerRef.current.videoPlayer.playbackRate(playbackRate);
                        this.playerRef.current.setState({playbackRate: playbackRate});
                        this.playerRef.current.controlsBarRef.current.setState({seekBarIndex: playbackRate});
                    }
                }

                if (event.keyCode === Constant.KEYCODE_PERIOD) {
                    let playbackRate = this.playerRef.current.state.playbackRate + 0.1;
                    if (playbackRate <= 2) {
                        playbackRate = parseFloat(playbackRate.toFixed(1));
                        this.playerRef.current.videoPlayer.playbackRate(playbackRate);
                        this.playerRef.current.setState({playbackRate: playbackRate});
                        this.playerRef.current.controlsBarRef.current.setState({seekBarIndex: playbackRate});
                    }
                }

                if (event.keyCode === Constant.KEYCODE_I) {
                    if (this.state.isAuthentication) {
                        if (!this.state.isShowQuickComment) {
                            this.onToggleQuickComment();
                        }
                        if (this.state.isShowQuickComment) {
                            this.commentExtendRef.current.focusTextarea();
                            return false;
                        }
                    }
                }

                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }

                if (event.keyCode === Constant.KEYCODE_ESC) {
                    if (this.state.isShowQuickComment) {
                        this.onToggleQuickComment();
                    }
                }
            }
        });

        this.themeContext();

        if (sessionStorage.getItem("messageAlert")) {
            let messageAlert = JSON.parse(sessionStorage.getItem("messageAlert") as string);
            this.setMessageAlert(messageAlert);
            sessionStorage.removeItem("messageAlert");
        }

        if (!ISMobile) {
            this.largevideo();
        }

        this.setShowBtnLoginRegister();
        if (this.props.isScorm) {
            this.getRegisterScormUrl();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.updateFavoriteListToSlides(undefined);
        if (prevProps.params !== this.props.params) {
            if (this.props.params?.slides?.item?.length > 0 && !ISMobile) {
                this.largevideo();
            }
        }
    }

    themeContext() {
        let themeContext = this.context;
        if (sessionStorage.getItem("ThemeContext")) {
            themeContext.setTheme(sessionStorage.getItem("ThemeContext"));
        } else {
            window
                .matchMedia("(prefers-color-scheme: dark)")
                .addEventListener("change", (e) => {
                    const colorScheme = e.matches ? Theme.Dark : Theme.Light;
                    themeContext.setTheme(colorScheme);
                });

            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                themeContext.setTheme(Theme.Dark);
            }
        }
    }

    swapElements(obj1, obj2) {
        let parent2 = obj2.parentNode;
        let next2 = obj2.nextSibling;
        if (next2 === obj1) {
            parent2.insertBefore(obj1, obj2);
        } else {
            obj1.parentNode.insertBefore(obj2, obj1);

            if (next2) {
                parent2.insertBefore(obj1, next2);
            } else {
                parent2.appendChild(obj1);
            }
        }
    }

    checkVideoAuth = (props) => {
        let tcbit = props.params?.tcbit;
        let user = props.params?.user;
        let userAccept = props.params?.user?.accept;

        if ((tcbit !== "" && tcbit !== undefined) || (user !== "" && userAccept === true)) {
            return true;
        } else {
            return false;
        }
    }

    updateActiveTimeIndex = (timeIndex) => {

        this.setState(prevState => ({
            timeIndex: timeIndex,
            manualMode: false
        }))
    };

    renderProgramImageUrl = (thumbnail) => {
        return thumbnail;
    };

    getCookieByName = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    }

    switchToManualMode = (manualMode) => {
        this.setState({manualMode: true});
    };

    updateVideoTime = (time) => {
        //update video to watch from the time value
        this.setState({seekTime: time, seekFromTimeLine: (this.state.seekFromTimeLine + 1)})
    };

    onTextChange = (value) => {
        this.setState({isWriting: value})
    }

    onSliderRequestAddANewMemos = (newMemo) => {
        this.tmpFavoriteList.push({
            seconds: newMemo.index_time
        })
        this.videoDesRef.current.addNewMemosFromSlide(newMemo);
    }

    onSliderRequestDeleteANewMemos = (newMemo) => {
        this.tmpFavoriteList = this.tmpFavoriteList.filter((item) => {
            return Number.parseInt(item.seconds) != Number.parseInt(newMemo.index_time)
        })
        this.videoDesRef.current.deleteMemosFromSlide(newMemo);
    }

    onSwapElement = () => {
        const videoCtrlDom = this.videoCtrlRef.current;
        const videoSliderDom = this.videoSliderRef.current;
        const currentVideoCtrlHeight = videoCtrlDom.clientHeight;
        const currentSliderWidth = videoSliderDom.clientWidth;
        const currentSliderHeight = videoSliderDom.clientHeight;
        const newSliderWidth = Math.floor(currentVideoCtrlHeight / currentSliderHeight * currentSliderWidth);
        if (!this.state.isSwapLayout) {

            videoSliderDom.style.width = `100%`;
            videoCtrlDom.classList.add("video-slider-swaped");

        }
        else {
            videoCtrlDom.classList.remove("video-slider-swaped");
            videoSliderDom.style.width = `auto`;
        }
        this.swapElements(videoCtrlDom, videoSliderDom);
        this.videoSliderClassRef.current.updateSliderImageSize();
        this.setState({isSwapLayout: !this.state.isSwapLayout});
        }

    updateFavoriteListToSlides = (favoriteList) => {
        let currentFavoriteList;
        if (favoriteList != undefined) {
            this.tmpFavoriteList = favoriteList;
            currentFavoriteList = favoriteList;
        } else {
            currentFavoriteList = this.tmpFavoriteList;
        }
        let slideList = this.state.slideList;
        if (slideList != undefined && currentFavoriteList != undefined) {
            let favoriteMap = new Map(currentFavoriteList.map(item => [item.seconds, item]));
            let isUpdate = false;
            slideList = slideList.map(item => {
                if ((!item.attribute.isFavorite) && favoriteMap.get(item.attribute?.index_time)) {
                    item.attribute.isFavorite = true;
                    item.attribute.favorite_num ++;
                    isUpdate = true;
                } else if (item.attribute.isFavorite && favoriteMap.get(item.attribute?.index_time) == undefined) {
                    item.attribute.isFavorite = false;
                    item.attribute.favorite_num --;
                    isUpdate = true;
                }
                return item;
            })
            if (isUpdate) {
                this.setState({slideList: slideList})
            }

        }
    }

    updateLecCommentLength = (value) => {
        this.setState({lecCommentLength: value})
    }

    onToggleQuickComment = () => {
        this.setState({isShowQuickComment: !this.state.isShowQuickComment});
        const commendExtendDom = this.commendExtendRef.current;
        if (commendExtendDom && !commendExtendDom.innerHTML) {
            const videoDesDom = this.videoDesWrapRef.current;
            const listComment = videoDesDom.querySelector("#flatListForExtend");
            commendExtendDom.appendChild(listComment);
        }
    }

    setRelatedContent = (relatedContents) => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        if (params.has("cid") || params.has("lid") || params.has("cmplid") || this.props.isScorm) {
            return;
        }
        if (relatedContents.length > 1) {
            relatedContents.forEach((content, index) => {
                if (this.props.prgID == content.prg_per_id && index != relatedContents.length - 1) {
                    this.setState({
                        relatedContent: relatedContents[index + 1]
                    });
                }
            });
        }
    }

    showModalKeyboardShortcuts = () => {
        this.setState({isShowModalKeyboardShortcuts: true});
    }

    showModalLoginScorm = () => {
        this.setState({isShowModalLoginScorm: true});
    }

    setShowLargeVideo = (isShowLargeVideo) => {
        this.setState({
            isShowLargeVideo: isShowLargeVideo
        })
    }

    onSwapContentWhenEnlarge = () => {
        const contentDom = this.contentRef.current;
        const contentLargeVideoDom = this.contentLargeVideoRef.current;
        this.swapElements(contentDom, contentLargeVideoDom);
    }

    largevideo = () => {
        this.setShowLargeVideo(!this.state.isShowLargeVideo);
        this.onSwapContentWhenEnlarge();
    }

    onMouseEnterTimeline = (timeIndex) => {
        this.videoSliderClassRef.current.onMouseEnterTimeline(timeIndex);
    }

    onMouseLeaveTimeline = () => {
        this.videoSliderClassRef.current.onMouseLeaveTimeline();
    }

    setMessageAlert = (messageAlert, timeout? : number) => {
        this.setState({
            messageAlert: messageAlert
        });
        let appBody = this;
        if (timeout) {
            setTimeout(function () {
                appBody.setState({
                    messageAlert: {
                        isShow: false,
                        message: "",
                        type: "success"
                    }
                });
            }, timeout);
        }
    }

    setUrlFavoritePlaylist = (url) => {
        this.setState({
            urlFavoritePlaylist: url
        })
    }

    setShowBtnLoginRegister = () => {
        this.setState({
            isShowBtnLoginRegister: !this.authentication.checkCookieHaveUsernameAndToken()
        })
    }

    getRegisterBtn = () => {
        if (this.props.isScorm) {
            if (!this.props.isHideBtnRegister) {
                return (this.state.registerScormUrl?.scorm_url && <a href={this.state.registerScormUrl.scorm_url} className="btn btn-register" target="_blank">
                    受講登録がまだの方<br/>
                    発行済IDの有効期限を延長する方
                </a>);
            }
            return null;
        } else {
            return <a href="https://www.bbt757.com/svlAirSearch/search?catName=BBT%E3%81%8B%E3%82%89%E3%81%AE%E3%81%94%E6%A1%88%E5%86%85&subCatName=AirSearch%E3%81%AE%E3%81%8A%E7%94%B3%E8%BE%BC%E3%81%BF&isParent=false&sortKey=CUSTOM&sortOrder="
                      className="btn btn-register">受講登録がまだの方はこちら</a>
        }
    }

    isAirCampus = () => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        return params.has("cid") || params.has("lid") || params.has("cmplid");
    }

    getRegisterScormUrl = () => {
        if (this.props.groupId && this.props.projectId) {
            let urlGetScormUrl = process.env.REACT_APP_API_ENDPOINT + `/GetScormUrl?group_id=${this.props.groupId}&project_id=${this.props.projectId}`;
            axios.get(urlGetScormUrl)
                .then(res => {
                    let data = res.data;
                    if (data.item?.length > 0) {
                        this.setState({
                            registerScormUrl: data.item[0],
                        })
                    }
                })
        }
    }

    render() {
        return (
            <Paper elevation={0} className={`app-body ${this.state.isShowLargeVideo ? 'show-large-video' : ''}`} data-theme={this.props.theme?.palette?.type}>
                <Header showModalKeyboardShortcuts={this.showModalKeyboardShortcuts}
                        showModalLoginScorm={this.showModalLoginScorm}
                        urlFavoritePlaylist={this.state.urlFavoritePlaylist}
                        isContentFree={this.props.isContentFree}/>
                <section>
                    <div className="container-fluid p-rl0">
                        <div className="row">
                            {
                                !this.state.isShowQuickComment ? ((this.props.params?.slides) ? null : <div className="col-md-2"/>) : null
                            }
                            <div className={this.state.isShowQuickComment ? "col-md-9" : "col-md-8"}>
                                <Learnings items={this.props.params?.user?.learnings?.item}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`${this.state.isShowLargeVideo ? (this.state.isShowQuickComment ? 'col-md-9' : 'col-md-12') : ''} ${this.props.params?.slides ? (this.state.isShowQuickComment ? 'col-md-6' : 'col-md-8') :  (this.state.isShowQuickComment ? 'col-md-9' : 'col-md-8 mx-auto')} pb-3 p-rl0 card-img`}>
                                {
                                    (new Date() > new Date(this.props.params?.content?.expiration_date)) ?
                                        <span className="wapper-img position-relative">
                                            <img id={"videoMainThumb"} src={this.renderProgramImageUrl(this.props.params?.content?.thumbnail)}/>
                                            <div className="wapper-btn-player position-absolute">
                                                <div className="wapper-btn-player-center">
                                                    <p className="mb-2" style={{color: "#fff", fontSize: "14px"}} >このコンテンツの配信は終了しました。</p>
                                                </div>
                                            </div>
                                        </span>
                                        : <>
                                            {
                                                (this.state.isAuthentication && this.state.videoAuth) ?
                                                    <Player videoCtrlRef={this.videoCtrlRef}
                                                            params={this.props.params} isContainCur={this.state.isContainCur}
                                                            videoIndex={this.updateActiveTimeIndex} seekPos={this.state.seekTime}
                                                            seekFromTimeLine={this.state.seekFromTimeLine}
                                                            autoPlay={this.state.autoPlay}
                                                            subscription={this.props.subscriptions}
                                                            isWriting={this.state.isWriting}
                                                            onAuthenticationChanged={(text) => this.setState({authentication: text})}
                                                            poster={this.props.params?.content?.thumbnail}
                                                            ref={this.playerRef}
                                                            relatedContent={this.state.relatedContent}
                                                            isShowLargeVideo={this.state.isShowLargeVideo}
                                                            setShowLargeVideo={this.setShowLargeVideo}
                                                            onSwapContentWhenEnlarge={this.onSwapContentWhenEnlarge}
                                                    />
                                                    : (!this.props.isContentFree &&
                                                    <span className="wapper-img position-relative">
                                                        <img id={"videoMainThumb"} src={this.renderProgramImageUrl(this.props.params?.content?.thumbnail)}/>

                                                        {
                                                            (this.state.isShowBtnLoginRegister && !this.props.isShowLoading) &&
                                                            <div className="wapper-btn-player position-absolute">
                                                                <div className="wapper-btn-player-center">
                                                                    {
                                                                        this.props.isScorm ?
                                                                            <a href="#" className="btn btn-login" onClick={this.showModalLoginScorm}>ログインして視聴</a>
                                                                            : <a href={this.authentication.getLoginPage()} className="btn btn-login">ログインして視聴</a>
                                                                    }
                                                                    {
                                                                        !this.isAirCampus() && this.getRegisterBtn()
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            (!this.state.videoAuth && !this.props.isShowLoading && this.state.isAuthentication && !this.props.noContent) &&
                                                            <div className="wapper-btn-player position-absolute">
                                                                <div className="wapper-btn-player-center">
                                                                    <p className="mb-2" style={{color: "#fff", fontSize: "14px"}} >ログイン中のアカウントではこの講義のご視聴ができません。</p>
                                                                    <div className="text-center">
                                                                        {
                                                                            this.props.isScorm ?
                                                                                <a href="#" className="btn btn-login" onClick={this.showModalLoginScorm}>別アカウントでログイン</a>
                                                                                : <a onClick={this.authentication.logoutRedirectLoginPage} className="btn btn-login">別アカウントでログイン</a>
                                                                        }
                                                                        <p style={{color: "#fff", fontSize: "12px"}} >※現在のアカウントからログアウトします</p>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            !this.isAirCampus() && this.getRegisterBtn()
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {
                                                            (!this.state.videoAuth && !this.props.isShowLoading && this.state.isAuthentication && this.props.noContent) &&
                                                            <div className="wapper-btn-player position-absolute">
                                                                <p className="mb-2" style={{color: "#fff", fontSize: "14px"}} >動画の準備ができておりません。
                                                                    配信時間をご確認ください。配信時間を過ぎても視聴できない場合は、サポートにお問合せください。</p>
                                                            </div>
                                                        }

                                                    </span>)
                                            }

                                            {
                                                this.props.isContentFree &&
                                                <PlayerFree
                                                    params={this.props.params}
                                                    videoIndex={this.updateActiveTimeIndex}
                                                    seekPos={this.state.seekTime}
                                                    onAuthenticationChanged={(text) => this.setState({authentication: text})}
                                                    videoCtrlRef={this.videoCtrlRef}
                                                    poster={this.props.params?.content?.thumbnail}
                                                    isContainCur={this.state.isContainCur}
                                                    seekFromTimeLine={this.state.seekFromTimeLine}
                                                    ref={this.playerRef}
                                                    isWriting={this.state.isWriting}/>
                                            }
                                        </>
                                }
                                <div id="content">
                                    <div ref={this.contentRef} className="col-12">
                                        <div className="row mt-3">
                                            <div className="col-sm-12">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <Typography variant="h5">
                                                            {this.props.params?.content?.prg_title}
                                                        </Typography>
                                                        <Breadcrumbs separator={<i className="czi-arrow-right"/>} className={"mb-2"}
                                                            aria-label="breadcrumb">
                                                            {this.props.breadcrumbs}
                                                        </Breadcrumbs>
                                                    </div>
                                                    {
                                                        (!this.props.params?.slides && !this.props.isContentFree) ?
                                                            <div className="slider-download-not-slide">
                                                                <SliderDownload items={this.props.params?.materials?.item || []}/>
                                                            </div>
                                                            : null

                                                    }
                                                    {
                                                        this.state.isAuthentication ?
                                                            <DropdownPlaylist prgID={this.props.prgID}
                                                                              messageAlert={this.state.messageAlert}
                                                                              setMessageAlert={this.setMessageAlert}
                                                                              isFavorite={this.props.params?.content?.is_favorite}
                                                                              setUrlFavoritePlaylist={this.setUrlFavoritePlaylist}/>
                                                            : null
                                                    }
                                                    <SwitchTheme/>
                                                    {
                                                        (this.props.isContentFree && !ISMobile) || (this.state.isAuthentication && this.state.videoAuth && this.props.params?.slides && !ISMobile) ?
                                                            <Tooltip
                                                                placement="right"
                                                                title="動画とスライドの入れ替え">
                                                                <a className="btn-swap ml-2" onClick={this.onSwapElement}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                         height="16" fill="currentColor"
                                                                         className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                        <path fillRule="evenodd"
                                                                              d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                                                                    </svg>
                                                                </a>
                                                            </Tooltip> : null
                                                    }
                                                    {
                                                        (this.props.isContentFree && !ISMobile) || (this.state.isAuthentication && this.state.videoAuth && !ISMobile) ?
                                                            <Tooltip
                                                                placement="right"
                                                                title={`${this.state.isShowLargeVideo ? "縮小表示" : "拡大表示"}`}>
                                                                <a className="btn-swap ml-2" onClick={this.largevideo}>
                                                                    {
                                                                        this.state.isShowLargeVideo ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-easel3" viewBox="0 0 16 16">
                                                                                <path fillRule="evenodd" d="M8.5 13.134V12h5a1.5 1.5 0 0 0 1.5-1.5V2h.5a.5.5 0 0 0 0-1H.5a.5.5 0 0 0 0 1H1v8.5A1.5 1.5 0 0 0 2.5 12h5v1.134a1 1 0 1 0 1 0ZM2 2v8.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V2H2Z"/>
                                                                            </svg> :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-easel3-fill" viewBox="0 0 16 16">
                                                                                <path d="M8.5 12v1.134a1 1 0 1 1-1 0V12h-5A1.5 1.5 0 0 1 1 10.5V3h14v7.5a1.5 1.5 0 0 1-1.5 1.5h-5Zm7-10a.5.5 0 0 0 0-1H.5a.5.5 0 0 0 0 1h15Z"/>
                                                                            </svg>
                                                                    }
                                                                </a>
                                                            </Tooltip> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    dangerouslySetInnerHTML={{__html: this.props.params?.content?.prg_theme}}
                                                />
                                            </div>
                                        </div>
                                        <div ref={this.videoDesWrapRef}>
                                            <VideoDescription
                                                params={this.props.params}
                                                prgID={this.props.prgID}
                                                timeIndex={this.state.timeIndex}
                                                videoAuth={this.state.videoAuth}
                                                name={this.props.params?.content?.prg_title}
                                                onClickTextArea={() => this.onTextChange(true)}
                                                onBlurTextArea={() => this.onTextChange(false)}
                                                updateVideoTime={this.updateVideoTime}
                                                onRemoveSubtitle={this.props.onRemoveSubtitle}
                                                authentication={this.state.authentication ? this.state.authentication : this.props.params?.tcbit?.data}
                                                ref={this.videoDesRef}
                                                transferFavoriteList={this.updateFavoriteListToSlides}
                                                updateLecCommentLength={this.updateLecCommentLength}
                                                setRelatedContent={this.setRelatedContent}
                                                isAuthentication={this.state.isAuthentication}
                                                updateActiveIndex={this.updateActiveTimeIndex}
                                                handleClickOnFavoriteIcon={this.videoSliderClassRef?.current?.handleClickOnFavoriteIcon}
                                                subscriptions={this.props.subscriptions}
                                                showingTracks={this.playerRef?.current?.showingTracks}
                                                token={this.props.token}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${this.state.isShowQuickComment ? "col-md-6" : "col-md-8"}`} id="content-largevideo" hidden={!this.state.isShowLargeVideo}>
                                <div ref={this.contentLargeVideoRef}>
                                </div>
                            </div>
                            {
                                (this.props.params?.slides) ?
                                    <div className={`${this.state.isShowQuickComment ? 'col-md-3' : 'col-md-4'}`}>
                                        <div ref={this.videoSliderRef}>
                                            <VideoSlider
                                                slideItems={this.state.slideList}
                                                programId={this.props.params.content?.prg_per_id}
                                                timeIndex={this.state.timeIndex} manualMode={this.state.manualMode}
                                                onSwitchSlideManual={this.switchToManualMode}
                                                viewPermission={this.state.videoAuth}
                                                documentUrls={this.props.params?.materials?.item}
                                                addNewMemo={this.onSliderRequestAddANewMemos}
                                                deleteMemo={this.onSliderRequestDeleteANewMemos}
                                                isContentFree={this.props.isContentFree}
                                                ref={this.videoSliderClassRef}
                                            />
                                        </div>
                                        <VideoTimeline
                                            slideItems={this.props.params ? this.props.params.slides?.item : []}
                                            timeIndex={this.state.timeIndex}
                                            updateActiveIndex={this.updateActiveTimeIndex}
                                            updateVideoTime={this.updateVideoTime}
                                            onMouseEnterTimeline={this.onMouseEnterTimeline}
                                            onMouseLeaveTimeline={this.onMouseLeaveTimeline}
                                        />
                                    </div>
                                    : <div hidden={true}></div>
                            }
                            {!ISMobile ? (
                              <div className="col-md-3" hidden={!this.state.isShowQuickComment}>
                                <CommentExtend ref={this.commentExtendRef}
                                  onToggle={this.onToggleQuickComment}
                                  lecCommentLength={this.state.lecCommentLength}
                                  addMemMo={(value) => this.videoDesRef.current.onCreateMemo(value)}>
                                    <div ref={this.commendExtendRef}></div>
                                </CommentExtend>
                                </div>) : null
                            }
                        </div>
                        {!ISMobile && this.state.isAuthentication && this.state.videoAuth? (
                            <div
                              data-open={this.state.isShowQuickComment}
                              data-no-slide={!this.props.params?.slides}
                              className="comment-extend__button-toggle">
                                <Tooltip
                                  placement="left"
                                  title="メモを記入する">
                                    <IconButton
                                      onClick={this.onToggleQuickComment}
                                      size="medium"
                                      color="default"
                                      aria-label="upload picture"
                                      component="span">
                                        <FontAwesomeIcon size="xs" icon={faPen}/>
                                    </IconButton>
                                </Tooltip>
                            </div>) : null
                        }
                        <ModalKeyboardShortcuts
                            isShow={this.state.isShowModalKeyboardShortcuts}
                            title={'キーボードショートカット'}
                            onNegativeButtonClicked={() => {
                                this.setState({isShowModalKeyboardShortcuts: false})
                            }}
                        />
                        <UncontrolledAlert hidden={!this.state.messageAlert.isShow} color={this.state.messageAlert.type} style={{position: 'fixed', top: '100px', left: '50%', transform: 'translate(-50%, -50%)'}}>
                            <span dangerouslySetInnerHTML={{__html: this.state.messageAlert.message}}/>
                        </UncontrolledAlert>
                        <ModalLoginScorm
                            isShow={this.state.isShowModalLoginScorm}
                            title={'ログイン'}
                            onNegativeButtonClicked={() => {
                                this.setState({isShowModalLoginScorm: false})
                            }}
                        />
                    </div>
                </section>
                {
                    this.props.fontSizeSubtitle &&
                    <style>
                        {
                            `video::-webkit-media-text-track-display {
                                font-size: ${this.props.fontSizeSubtitle}px;
                            }
                            @media (max-width: 768px) {
                                video::-webkit-media-text-track-display {
                                    font-size: ${Number.parseInt(this.props.fontSizeSubtitle) - Constant.FONTSIZE_SUBTITLE_MOBILE}px;
                                }
                            }
                            `
                        }
                    </style>
                }
                <footer>
                    <div className="footer__nav">
                        <div className="footer__nav__item"><a href="https://aoba-bbt.com/"
                                                              target="_blank">株式会社Aoba-BBT</a>
                        </div>
                        <div className="footer__nav__item"><a href="https://www.bbt757.com/privacy.html"
                                                              target="_blank">プライバシーポリシー</a>
                        </div>
                        <div className="footer__nav__item"><a href="https://www.bbt757.com/agreement.html"
                                                              target="_blank">利用規約</a></div>
                        { this.props.isScorm && <div className="footer__nav__item"><span className="scorm-text-footer">SCORM利用</span></div> }
                        <div className="footer__nav__item">
                            <div className="footer__copyright">
                                <div>© 2002-{new Date().getFullYear()} Aoba-BBT, Inc.</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => ({
    isScorm: state.scorm.isScorm,
    groupId: state.scorm.groupId,
    projectId: state.scorm.projectId,
    fontSizeSubtitle: state.setting.fontSizeSubtitle,
    isHideBtnRegister: state.scorm.isHideBtnRegister
});
export default connect(mapStateToProps, null, null, {forwardRef: true})(withTheme(AppBody));
