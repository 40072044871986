import React, {Component} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {Constant} from "../constant/Constant";

interface IProps {
    prgTitle: string,
    thumbnail: string,
    prgTheme: string,
    prgDuration: string,
    prgPerId: string,
    urlRelatedContents: string,
}

interface IState {
}

class ContentItem extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {
        return(
            <div className="col-xl-3 col-lg-6 col-md-4 col-sm-6 px-2 mb-3 itemAdd">
                <div className="card product-card card-static pb-3 prePerId">
                    <a className="card-img-top d-block overflow-hidden text-center img-wrapper image-time-watched"
                       href={this.props.urlRelatedContents}>
                        <img className="img-fit-content"
                             src={this.props.thumbnail}
                             alt="Product"/>
                        <br/>
                        <span className="program-duration">{this.props.prgDuration}</span>
                    </a>
                    <div className="card-body py-2 pl-0">
                        <div>
                            <h3 className="product-title font-size-sm d-inline-block font-weight-bold col-11 px-0">
                                <a href={this.props.urlRelatedContents}>{this.props.prgTitle}</a>
                            </h3>
                        </div>
                        <div>
                            <Tooltip
                                placement="bottom"
                                arrow
                                title={<span dangerouslySetInnerHTML={{__html: this.props.prgTheme}}></span>}>
                                <h3 className="product-title font-size-sm theme-text post__items" dangerouslySetInnerHTML={{__html: this.props.prgTheme}}></h3>
                            </Tooltip>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default ContentItem;