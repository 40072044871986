import * as React from 'react';
import {Theme, useTheme} from '../../ThemeContext';
import Tooltip from "@material-ui/core/Tooltip";

const SwitchTheme = () => {
    const { theme, setTheme } = useTheme();
    return (
        <Tooltip
            placement="right"
            title="集中モード">
            <a
                className="btn-swap ml-2"
                onClick={() => {
                    sessionStorage.setItem("ThemeContext", theme === Theme.Light ? Theme.Dark : Theme.Light);
                    setTheme(theme === Theme.Light ? Theme.Dark : Theme.Light);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-circle-half" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
                </svg>
            </a>
        </Tooltip>
    )
}

export default SwitchTheme;
