import XmlRpcJson from '../../helper/xmlrpc';

export const updateVideoPosition = (params) => {
    let paramsDetail = {
        play_Id: params.playId,
        lo_Id: params.loId,
        air_Id: (params.airId),
        bit_Data: params.bitData,
        data: params.data,
        current_Position: params.currentPosition,
        multi: true,
        attend: true
    };

    const promise = new Promise((resolve, reject) => {
        XmlRpcJson
            .executeXmlRpcJson('acex.AuthSynch', paramsDetail)
            .then(() => resolve(params.bitData))
            .catch(ex => reject(ex))
    });
    return promise;
};
