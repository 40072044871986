import React from 'react';
import {withTheme} from '@material-ui/styles';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface IProps {
    title: String,
    message: String,
    positiveButton: String,
    negativeButton: String,
    onPositiveButtonClicked: any,
    onNegativeButtonClicked: any,
    isShow: boolean,
    theme: any,
}

interface IState {
}

class ModalPopup extends React.Component<IProps, IState> {

    render() {
        return(
            <>
                <Modal isOpen={this.props.isShow} data-theme={this.props.theme?.palette?.type}>
                    <ModalHeader toggle={this.props.onNegativeButtonClicked}>
                        {this.props.title}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.message}
                    </ModalBody>
                    <ModalFooter className={'d-flex justify-content-around'}>
                        <Button color="primary" onClick={this.props.onPositiveButtonClicked}  style={{width: '7.125rem'}}>{this.props.positiveButton}</Button>{' '}
                        <Button color="secondary" onClick={this.props.onNegativeButtonClicked}>{this.props.negativeButton}</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default withTheme(ModalPopup);
