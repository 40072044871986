import React, {useState} from 'react';
import {withTheme} from '@material-ui/styles';
import {Alert, Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import './ModalKeyboardShortcuts.sass';
import {Formik} from "formik";
import axios from "axios";
import sha1 from "js-sha1";
import {StringUtil} from "../util/StringUtil";
import "./ModalLoginScorm.sass";

interface IProps {
    title: String,
    onNegativeButtonClicked: any,
    isShow: boolean,
    theme: any,
}

const ModalLoginScorm = (props: IProps) => {

    const [error, setError] = useState('');
    const submitForm = async (values: { userId: string, pass: string, saveAuth:boolean }, {setSubmitting}: { setSubmitting: Function }) => {
        try {
            let dataStartAuth = {"methodName": "ac.StartAuth", "userId": values.userId}
            let startAuthRes = await axios.post(process.env.REACT_APP_API_AUTH || '', dataStartAuth);
            let nonce = startAuthRes.data.nonce;
            let realm = startAuthRes.data.realm;

            let dataExecuteAuth = {
                "methodName": "ac.ExecuteAuth",
                "loginId": values.userId,
                "nonce": nonce,
                "realm": realm,
                "response": createExecuteAuthResponse(values.pass, nonce, realm),
            }
            let executeAuthRes = await axios.post(process.env.REACT_APP_API_AUTH || '', dataExecuteAuth);
            let userId = executeAuthRes.data.userId;
            let authToken = executeAuthRes.data.authToken;
            StringUtil.setCookies("u", userId, values.saveAuth);
            StringUtil.setCookies("a", authToken, values.saveAuth);
            setError("");
            props.onNegativeButtonClicked();
            window.isReloadScorm = true;
            window.location.reload();
        } catch (ex: any) {
            setError("ユーザーIDまたは、パスワードが違います。");
            setSubmitting(false);
        }
    };

    const createExecuteAuthResponse = (password: String, nonce: String, realm: String): String => {
        let secret = sha1(`${realm}:${password}`);
        return sha1(`${secret}:${nonce}`);
    }

    return(
        <>
            <Modal isOpen={props.isShow} data-theme={props.theme?.palette?.type} className="modal-login-scorm">
                <ModalHeader toggle={props.onNegativeButtonClicked}>
                    {props.title}
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{userId: '', pass: '', saveAuth: false}}
                        validate={values => {
                            const errors: { userId?: string, pass?: string, saveAuth?: boolean } = {};
                            return errors;
                        }}
                        onSubmit={submitForm}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                            <Form className={"form"} onSubmit={handleSubmit} autoComplete="off">

                                {error && <Alert color="danger">
                                    {error}
                                </Alert>}

                                <FormGroup>
                                    <Label for="userId">ユーザーID</Label>
                                    <Input type="text" name="userId" id="userId" autoComplete="off"
                                           onChange={handleChange} onBlur={handleBlur} value={values.userId}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="pass">パスワード</Label>
                                    <Input type="text" name="pass" id="pass" autoComplete="off"
                                           onChange={handleChange} onBlur={handleBlur} value={values.pass}/>
                                </FormGroup>

                                <FormGroup>
                                    <Input type="checkbox" name="saveAuth" id="saveAuth" className="ml-0"
                                           onChange={handleChange} onBlur={handleBlur} checked={values.saveAuth}/>
                                    <Label for="saveAuth" className="ml-3">ユーザーID・パスワードを保存</Label>
                                </FormGroup>

                                <Button color="primary" className="mt-4 w-25" type="submit"
                                        disabled={isSubmitting}>ログイン</Button>
                                <Label style={{ float: "right", marginTop: "48px", fontSize: "12px", color: "#999" }}>Version 186-20240425</Label>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    )
}

export default withTheme(ModalLoginScorm);
