import {configureStore} from '@reduxjs/toolkit'
import scormReducer from './features/scorm/scormSlice'
import settingReducer from './features/setting/settingSlice'

export function makeStore() {
    return configureStore({
        reducer: {
            scorm: scormReducer,
            setting: settingReducer
        },
    })
}

const store = makeStore()
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
