import React from "react";
import ReactExport from 'react-data-export';
import {StringUtil} from "../util/StringUtil";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps {
    subtitleList: any;
    params?: any;
    subscriptionSelected: string,
}

interface IState {
}

class DownloadExcel extends React.Component<IProps, IState> {
     getDataExport () {
         const styleTittle = {
             font: {sz: "16", bold: true},
             alignment: {
                 vertical: "center",
                 horizontal: "center",
                 wrapText: true,
             },
             border: {
                 top: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 bottom: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 left: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 right: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 }
             },
             fill: {
                 patternType: "solid",
                 fgColor: {rgb: "ddebf7"}
             }
         };
         const styleCell = {
             font: {sz: "12"},
             alignment: {
                 vertical: "top",
                 horizontal: "top",
                 wrapText: true,
             },
             border: {
                 top: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 bottom: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 left: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 },
                 right: {
                     style: "thin",
                     color: { rgb: "bfbfbf" }
                 }
             },
         };
         const multiDataSet = [
             {
                 columns: [
                     {title: "タイム", width: {wpx: 165}, style: styleTittle},
                     {title: "内容", width: {wpx: 1000}, style: styleTittle},
                 ],
                 data: [] as any[]
             }
         ];
         this.props.subtitleList.forEach(item => {
             multiDataSet[0].data.push([
                 {value: `${StringUtil.srtTimestamp(item.from)} - ${StringUtil.srtTimestamp(item.to)}`, style: styleCell},
                 {value: item.text, style: styleCell},
             ])
         });
        return multiDataSet;
    }

    render() {
        return (
            <ExcelFile element={<button className="btn btn-primary rounded-pill float-right fs-14 buttonIndexer mr-3 mb-2">字幕をダウンロードする</button>}
                       filename={`${this.props.params?.content?.prg_title}_${this.props.subscriptionSelected}の字幕`}>
                <ExcelSheet dataSet={this.getDataExport()} name={`${this.props.params?.content?.prg_title}_${this.props.subscriptionSelected}の字幕`}>
                </ExcelSheet>

            </ExcelFile>
        );
    }
}

export default DownloadExcel;