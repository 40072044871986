import React, {RefObject} from "react";
import "./CommentExtend.sass";
import Typography from "@material-ui/core/Typography";
import {Paper} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReceipt, faTimes} from "@fortawesome/free-solid-svg-icons";
import emptyCommentImage from "../../assets/image/guide-g.png";
import {Constant} from "../constant/Constant";

const MAX_LENGTH = 255;

interface IProps {
  onToggle?: any,
  addMemMo?: any,
  lecCommentLength: any,
}

interface IState {
  isShow: boolean,
  isOpenModal: boolean,
  newMemo: string
}

class CommentExtend extends React.Component<IProps, IState> {
  commendExtendListRef: any;
  textareaRef: any;
  isComposing: boolean = false;

  constructor(props: IProps) {
    super(props);
    this.commendExtendListRef = React.createRef();
    this.textareaRef = React.createRef();
    this.state = {
      isShow: true,
      isOpenModal: false,
      newMemo: ''
    };
  }

  hiddenMe = () => {
    this.setState({isShow: false})
  }

  toggleModal = () => {
    this.setState({
      isOpenModal: !this.state.isOpenModal
    })
  }

  onChangeText = ({target: {value}}) => {
    this.setState({newMemo: value})
  }

  onAdd = () => {
    this.props.addMemMo(this.state.newMemo);
    this.setState({newMemo: ''});
    setTimeout(this.scrollToBottomCommendExtendList, 1000);
  }

  scrollToBottomCommendExtendList = () => {
    const commendExtendListDom = this.commendExtendListRef.current;
    commendExtendListDom.scrollTop = commendExtendListDom.scrollHeight;
  }

  focusTextarea() {
    this.textareaRef.current.focus();
  }

  summitMemo = (event) => {
    if ((event.ctrlKey || event.shiftKey || event.metaKey) && event.keyCode == Constant.KEYCODE_ENTER) {
      this.onAdd();
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const escPressed = event.keyCode === Constant.KEYCODE_ESC;
    if (!this.isComposing && escPressed) {
      event.preventDefault();
      this.props.onToggle();
    }
  }

  render() {
    const remainingLength = MAX_LENGTH - (this.state.newMemo? this.state.newMemo.length : 0)
    return (
      <Paper className="comment-extend">
        <div className="comment-extend_list" ref={this.commendExtendListRef} data-empty={!this.props.lecCommentLength}>
          <div className="comment-empty" hidden={!!this.props.lecCommentLength}>
            <img src={emptyCommentImage} alt="emptyCommentImage"/>
            <div>現在メモは登録されていません</div>
          </div>
          <div hidden={!this.props.lecCommentLength}>
            {this.props.children}
          </div>
        </div>
        <div className="block-input">
          <div className="block-input__header">
            <strong>メモを記入する</strong>
            <span className="close-comment-extend" onClick={this.props.onToggle}>
              <FontAwesomeIcon icon={faTimes}/>
            </span>
          </div>
          <div className="block-input__body mt-3">
            <textarea ref={this.textareaRef}
              className="form-control"
              rows={5}
              placeholder={`${MAX_LENGTH} characters remaining`}
              onChange={this.onChangeText}
              value={this.state.newMemo}
              onKeyUp={this.summitMemo}
              onKeyDown={this.onKeyDown}
              onCompositionStart={() => { this.isComposing = true }}
              onCompositionEnd={() => { this.isComposing = false }}
            />
            <div className="actions mt-2">
              {
                remainingLength > 0 ?
                  <Typography className="mt-1" variant="body2">残り{remainingLength}文字</Typography>
                  :
                  <Typography className="mt-1" variant="body2" color="error">文字数オーバー</Typography>
              }
              <button type="button"
                      disabled={!this.state.newMemo || this.state.newMemo.length > MAX_LENGTH}
                      className="btn btn-primary rounded-pill float-right fs-14"
                      onClick={this.onAdd}>
                保存
              </button>
            </div>
          </div>
        </div>
      </Paper>
    )
  };
}

export default CommentExtend;
