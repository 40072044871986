import React from "react";
import CryptoJS from "crypto-js";

import * as xmlRpcJsonServices from './xmlRpcJsonServices';
import {text} from "@fortawesome/fontawesome-svg-core";

interface IProps {
    params?: any,
    videoProgress: number,
    xmlrpc3iParams: any,
    onAuthenticationChanged: any,
    isPlaying: boolean,
    isReady: boolean;
    currentTime?: any;
    isInPictureInPicture: () => boolean;
}

interface IState {
    choices: String[],
    dispStr: string,
    currentAuthentication: string,
    isShowAuthenticationSuccess: boolean
}


class Authentication extends React.Component<IProps, IState> {

    telopItems: {choices_str,disp_time,disp_str}[] = [];
    choiceList: {}[] = [];
    correctValue = <div></div>;
    // Flag check is attendant check is displaying or not
    isDisplaying = false;
    // User answered attendant check or not
    isAnswered = false;
    // Display flag
    inboundDisplay = false;

    choices = "";
    dispStr = "";

    constructor(props) {
        super(props);
        this.state = {
            choices: [],
            dispStr: "",
            currentAuthentication: this.props.xmlrpc3iParams?.data,
            isShowAuthenticationSuccess: false
        }
    }

    componentDidUpdate(previousPos, nextProps) {
        if ((this.props.params?.telop !== undefined)) {
            var telop = this.props.params?.telop;
            if (this.telopItems.length === 0 ) {
                for (var i = 0; i < telop.item.length; i++) {
                    var item = telop.item[i];
                    if (item.attribute.choices_str !== '' && item.attribute.disp_str !== '' && item.attribute.disp_time !== '') {
                        let tempItem={choices_str:"",disp_str:"",disp_time:""};
                        tempItem.choices_str = this.AESDecrypt(item.attribute.choices_str);
                        tempItem.disp_str = this.AESDecrypt(item.attribute.disp_str);
                        tempItem.disp_time = this.AESDecrypt(item.attribute.disp_time);
                        this.telopItems[i] = tempItem;
                    }
                }
            }

            if (this.props.videoProgress !== previousPos.videoProgress) {

                if (this.checkInbound(this.props.videoProgress)) {
                    if (!this.inboundDisplay && !this.isDisplaying && !this.isAnswered) {
                        this.inboundDisplay = true;
                        if (!this.isDisplaying && !this.isAnswered) {
                            this.displayAuthentication();
                        }
                    }
                } else {
                    this.inboundDisplay = false;
                    this.isAnswered = false;
                }
            }

            // this.displayAuthentication();
        }
    }

    checkInbound(position): boolean {
        if (!this.state.currentAuthentication) {
            this.setState({currentAuthentication: this.props.xmlrpc3iParams?.data})
        }
        
        if (this.state.currentAuthentication && !this.props.isInPictureInPicture()) {
            let authens = this.state.currentAuthentication?.split(";");
           
            for(let i=0; i< this.telopItems.length; i++){
                let rangeTime = 30;
                let lower = parseInt(this.telopItems[i].disp_time) - rangeTime;
                let upper = parseInt(this.telopItems[i].disp_time) + rangeTime;
                if(  position > lower && position < upper){
                    if (authens) {
                        for (let i = 0; i < authens.length; i++) {
                            let item = authens[i]?.split(",");
                            if (item[0] == "I" && lower <= parseInt(item[2]) && parseInt(item[2]) <= upper) {
                                return false;
                            }
                        }
                    }

                    this.choices = this.telopItems[i].choices_str;
                    this.dispStr = this.telopItems[i].disp_str;
                    return true;
                }
            }
            return false;
        } else {
            return false
        }
    }

    handleClick(e) {
        if (e.target.value === this.dispStr) {
            // Set display and answered flag
            this.isAnswered = true;
            this.inboundDisplay = false;

            /* Sent data to server  */
            let data = this.state.currentAuthentication;
            if (!data) {
                data = this.props.xmlrpc3iParams?.data
            }
            let ioId = this.props.params.tcbit?.lo_id;
            data += 'I,' + e.target.value + ',' +  parseInt(this.props.currentTime, 10) + ';';
            this.props.onAuthenticationChanged(data);
            this.setState({currentAuthentication: data, isShowAuthenticationSuccess: true});
            setTimeout(() => {
                this.setState({isShowAuthenticationSuccess: false});
            }, 5000);
            let params = {
                playId: (this.props.xmlrpc3iParams.playId),
                loId: ioId,
                airId: 0,
                bitData: this.props.xmlrpc3iParams.bitData,
                data: data,
                currentPosition: parseInt(this.props.currentTime, 10),
            };
            xmlRpcJsonServices.updateVideoPosition(params);
        }
    }

    displayAuthentication = () => {
        this.choiceList = [];
        this.correctValue = <div></div>;
        this.correctValue = (
            <span className="text-acblack">
                <button type="button" translate="no" className="btn btn-authentication">{this.dispStr}</button>
                <span className="please-press">を押してください。</span>
            </span>
        )

        for (let i = 0; i < this.choices.length; i++) {
            this.choiceList.push(
                <button type="button" onClick={e => this.handleClick(e)} translate="no"
                className="btn btn-authentication" value={this.choices.substr(i,1)} >
                    {this.choices.substr(i,1)}
                </button>
            )
        }
    }

    AESDecrypt(cipher) {
        let key = CryptoJS.enc.Hex.parse(CryptoJS.MD5('ad0e2JIl').toString(CryptoJS.enc.Hex));
        let cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(cipher)
        });

        let decrypt = this.hexToAscii(CryptoJS.AES.decrypt(cipherParams, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding }).toString());
        return decrypt.substr(0, decrypt.length - decrypt.substr(-1).charCodeAt(0));
    }

    hexToAscii(str1) {
        let hex = str1.toString();
        return decodeURIComponent(hex.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&'));
    }

    render() {
        if (this.inboundDisplay && this.props.isReady && this.props.isPlaying) {
            // If user not yet press answer -> appear attendant again when rewind to check position
            if (this.isAnswered) {
                this.isDisplaying = true;
            }

            return <div className="authn-character">

                {this.correctValue}
                <div className="d-flex authn-choice-list">
                    {this.choiceList}
                </div>
            </div>
        } else if (this.state.isShowAuthenticationSuccess) {
            return <div className="authn-character">
                <text style={{fontSize: 20, color: '#fff', textAlign: "center", width: '100%', display: "block", padding: "20px 0"}}>受講認証しました</text>
            </div>
        } else {
            return null;
        }
    }
}

export default Authentication;

