import React from 'react';
import './Subtitle.sass';
import Subscription from "./Subscription";
import {Constant} from "../constant/Constant";
const loading_icon = require("../../assets/image/Loading.gif");

interface IProps {
    subscriptions: any,
    onRequestSubtitles: any,
    messageUpload: boolean,
    loading: boolean,
    disabled: boolean,
    messageUploadFail: boolean,
    isUploading: boolean,
    updateVideoTime: any,
    showingTracks?: any,
    params?: any,
    isVideoProcessing: boolean,
}

interface IState {
}

class Subtitle extends React.Component<IProps, IState> {

    render() {
        const {loading} = this.props;

        return (
            <div className="mt-4 mb-4 border-0">
                {
                    this.props.subscriptions.length > 0 ?
                         <Subscription subscriptions={this.props.subscriptions}
                                       updateVideoTime={this.props.updateVideoTime}
                                       showingTracks={this.props.showingTracks}
                                       params={this.props.params}/> :
                        <div>
                            {loading &&
                            <div>
                                <img src={loading_icon} className="loading"/><span
                                className="message-success">{this.props.isUploading ? Constant.MESSAGE_LOADING : Constant.MESSAGE_DELETE_LOADING}</span>
                            </div>
                            }
                            {(this.props.messageUpload) ?
                                <div>
                                    <p className="message-success">{this.props.isUploading ? Constant.MESSAGE_SUCCESS : Constant.MESSAGE_DELETE_SUCCESS}</p>
                                </div>
                                : <div aria-disabled={true}></div>
                            }
                            {(this.props.messageUploadFail && !this.props.disabled) ?
                                <div>
                                    <p className="message-fail">{Constant.MESSAGE_FAIL}</p>
                                </div>
                                : <div aria-disabled={true}></div>
                            }
                            {(this.props.isVideoProcessing) ?
                                <div>
                                    <p className="message-success">現在字幕を作成しています。しばらくお待ちください。
                                        <br/>※リクエストした受講者には処理が完了するとメールで通知が送信されます。</p>
                                </div>
                                : <div aria-disabled={true}></div>
                            }
                            <div className="row no-gutters mt-2">
                                <button type="button" onClick={() => {this.props.onRequestSubtitles("auto")}} disabled={this.props.disabled || this.props.loading || this.props.isVideoProcessing}
                                        className="btn btn-primary rounded-pill float-right fs-14 buttonIndexer mr-3 mb-2">
                                    字幕をリクエストする
                                </button>
                            </div>
                        </div>

                }

            </div>
        );
    }
}

export default Subtitle;