import React from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Authentication from "../authentication/Authentication";
import {Constant} from "../constant/Constant";
import axios from "axios";
import {StringUtil} from "../util/StringUtil";
import {connect} from "react-redux";

const no_user_image_icon = require("../../assets/image/no-user-image.png");
const logo_icon = require("../../assets/image/logo.svg");
const as_logo_icon = require("../../assets/image/as-logo.svg");

interface IProps {
    showModalKeyboardShortcuts?: any,
    urlFavoritePlaylist: string,
    showModalLoginScorm: any,
    isScorm: boolean,
    isContentFree: boolean,
}

interface Istate {
    dropdownOpen: boolean,
    authentication: Authentication,
    url: string
    isAuthentication: boolean,
    username: string,
    locationHref: string,
    linkRegistration: string,
    urlAvatarImg: string
}

class Header extends React.Component<IProps, Istate> {
    authentication!: Authentication;
    constructor(props) {
        super(props);
        this.authentication = new Authentication();
        let urlLogin = this.authentication.getLoginPage();
        let isAuthentication = this.authentication.checkCookieHaveUsernameAndToken();
        this.state = {
            dropdownOpen: false,
            authentication: this.authentication,
            url: urlLogin,
            isAuthentication: isAuthentication,
            username: Constant.LOGIN,
            locationHref: window.location.href,
            linkRegistration: String(process.env.REACT_APP_REGISTER_URL),
            urlAvatarImg: ''
        };
    }

    componentDidMount() {
        this.authentication.checkAuthentication((response) => {
            this.setState({username: response.data?.userName_ja})
        });
        this.getUrlAvatarImg();
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    };

    getUrlAvatarImg() {

        let u = new Authentication().getCookieByName("u");
        let a = new Authentication().getCookieByName("a");
        axios.get(StringUtil.stringFormat(process.env.REACT_APP_API_ENDPOINT + Constant.GET_AVATAR_IMG ,u ,a )).then((res) => {
            this.setState({
                urlAvatarImg: res.data?.participant?.imageUrl.replace(Constant.HTTP, Constant.HTTPS)
            })
        }).catch((err) => {
        })
    }

    openOldVersionCourseScreen() {
        let u = new Authentication().getCookieByName("u");
        let a = new Authentication().getCookieByName("a");
        let pathname = window.location.pathname + window.location.search;
        let url = process.env.REACT_APP_DOMAIN_OLD_VERSION + pathname;
        axios.put(process.env.REACT_APP_PUT_DEFAULT_PLAYER_LINK + `?u=${u}&a=${a}&enable=0`).finally(() => {
            window.location.href = url;
        });
    }

    getLogoImage() {
        let broswerUrl = new URL(document.location.href);
        let cmplid = broswerUrl.searchParams.get("cmplid");
        if (cmplid) {
            return String(logo_icon);
        } else if (this.props.isScorm) {
            return "./as-logo.svg";
        }
        return String(as_logo_icon);
    }

    getHrefLogo() {
        let broswerUrl = new URL(document.location.href);
        let cmplid = broswerUrl.searchParams.get("cmplid");
        if (cmplid) {
            return "#";
        }
        return process.env.REACT_APP_AIR_SEARCH_ENDPOINT;
    }

    logoutScorm = () => {
        StringUtil.deleteCookies("u");
        StringUtil.deleteCookies("a");
        window.isReloadScorm = true;
        window.location.reload();
    }

    isUrlHasTokenParam = () => {
        let broswerUrl = new URL(document.location.href);
        let token = broswerUrl.searchParams.get("token");
        if (token) {
            return true;
        }
        return false;
    }

    render() {
        return <header className="sticky-top">
            <h1 className="header__logo"><a href={this.getHrefLogo()}>
                <img src={this.getLogoImage()} alt="AirCampus Mail"/></a>
            </h1>
            <Dropdown className={"header__user"} toggle={this.toggle} isOpen={this.state.dropdownOpen} nav>
                {
                    this.state.isAuthentication ?
                        <DropdownToggle nav caret>
                            {
                                this.state.urlAvatarImg != '' ?
                                    <img src={this.state.urlAvatarImg} alt={""}/>
                                    : <img src={String(no_user_image_icon)} alt={""}/>
                            }
                            <span>{this.state.username}</span>
                        </DropdownToggle> :
                        <div className="position-relative">
                            {
                                window.prgIdScorm ?
                                    <a className={"d-inline p-0"} href="#" onClick={this.props.showModalLoginScorm}>{Constant.LOGIN}</a>
                                    : <a className={"d-inline p-0"} href={this.state.url}>{Constant.LOGIN}</a>
                            }
                        </div>
                }
                <DropdownMenu right={true}>
                    {
                        this.state.isAuthentication ?
                        <>
                            <a href={this.state.linkRegistration} target="_blank" className="px-0">
                                <DropdownItem><i className="bi bi-person mr-1"/>{Constant.ACCOUNT_FROM}</DropdownItem>
                            </a>
                            <a hidden={this.props.urlFavoritePlaylist === null || this.props.urlFavoritePlaylist === ""} href={this.props.urlFavoritePlaylist} target="_blank" className="px-0">
                                <DropdownItem><i className="bi bi-heart mr-1" />お気に入り講義一覧</DropdownItem>
                            </a>
                            <a href={`${process.env.REACT_APP_AIR_SEARCH_ENDPOINT}getAllMemos?onlyFavorite=true&pageSize=30`} target="_blank" className="px-0">
                                <DropdownItem><i className="bi bi-bookmark-star mr-1" />お気に入りスライド一覧</DropdownItem>
                            </a>
                            <a href={`${process.env.REACT_APP_AIR_SEARCH_ENDPOINT}getAllMemos?pageSize=30`} target="_blank" className="px-0">
                                <DropdownItem><i className="bi bi-journal-bookmark mr-1" />メモ一覧</DropdownItem>
                            </a>
                            {
                                !this.props.isScorm &&
                                <a href={process.env.REACT_APP_REQUEST_CONTENT} target="_blank" className="px-0">
                                    <DropdownItem><i className="bi bi-lightbulb mr-1"/>{Constant.REQUEST_PLANNING_CONTENT}</DropdownItem>
                                </a>
                            }
                            <DropdownItem hidden={this.props.showModalKeyboardShortcuts === null} onClick={this.props.showModalKeyboardShortcuts}>
                                <i className="bi bi-keyboard mr-1"/>{Constant.KEYBOARD_SHORTCUTS}</DropdownItem>
                            <DropdownItem onClick={window.prgIdScorm ? this.logoutScorm : this.state.authentication.logout}>
                                <i className="bi bi-box-arrow-right mr-1"/>{Constant.LOGOUT}</DropdownItem>
                        </>: ""
                    }
                </DropdownMenu>
            </Dropdown>
        </header>

    }
}

const mapStateToProps = (state) => ({
    isScorm: state.scorm.isScorm
});
export default connect(mapStateToProps, null, null, {forwardRef: true})(Header);
