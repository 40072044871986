import React from 'react';
import {withTheme} from '@material-ui/styles';
import {Modal, ModalBody, ModalHeader, Table, Button} from 'reactstrap';
import axios from "axios";
import {StringUtil} from "../util/StringUtil";

interface IProps {
    title: String,
    onNegativeButtonClicked: any,
    isShow: boolean,
    theme: any,
    prgID: string,
    setMessageAlert: any,
    setFavorite: any,
    setUrlFavoritePlaylist: any,
}

interface IState {
    airSearchPlayList: any
}

class ModalAddItemToPlaylist extends React.Component<IProps, IState> {

    selectPlaylistRef: any;
    addPlaylistErrorMes: any;

    constructor(props) {
        super(props);
        this.selectPlaylistRef = React.createRef();
        this.addPlaylistErrorMes = React.createRef();
        this.state = {
            airSearchPlayList: []
        }
    }

    componentDidMount() {
        const FAVORITE_PLAYLIST_TYPE = 3;
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        let params = {
            a,
            u,
            play_list_only: true,
            archive_playlist: false
        }
        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/GetAirSearchPlayList`,
            method: 'GET',
            params
        }).then((res) => {
            const data = res.data;
            this.setState({
                airSearchPlayList: data.items
            });
            data.items.forEach(item => {
                if (item.play_list_type == 3) {
                    let urlFavoritePlaylist = `${process.env.REACT_APP_AIR_SEARCH_ENDPOINT}search?typeSearch=PLAYLIST_DETAIL&catName=%E3%83%9E%E3%82%A4%E3%83%97%E3%83%AC%E3%82%A4%E3%83%AA%E3%82%B9%E3%83%88&subCatName=%E3%81%8A%E6%B0%97%E3%81%AB%E5%85%A5%E3%82%8A&playlistId=${item.play_list_id}`;
                    this.props.setUrlFavoritePlaylist(urlFavoritePlaylist);
                    return;
                }
            })
        }).catch((err) => {
        })
    }

    renderAirSearchPlayList = () => {
        return this.state.airSearchPlayList.map((item, index) => {
            return <option key={index} value={item.play_list_id}>{item.play_list_name}</option>;
        });
    }

    addItemToPlaylist = () => {
        let playListId = this.selectPlaylistRef.current.value;
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        let playListName = this.selectPlaylistRef.current.options[this.selectPlaylistRef.current.selectedIndex].text;
        let dataRequest = {
            a,
            u,
            prg_id: this.props.prgID,
            play_list_id: playListId
        };

        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/AppendContent?u=${u}&a=${a}&play_list_id=${playListId}&prg_id=${this.props.prgID}`,
            method: 'POST',
            data: dataRequest,
            headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then((res) => {
            this.props.onNegativeButtonClicked();
            this.props.setMessageAlert({
                isShow: true,
                message: `${playListName} に追加しました`,
                type: "success"
            }, 5000);
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 302) {
                    this.addPlaylistErrorMes.current.innerText = `このコンテンツは、既に${playListName}に存在しています。`;
                }
            }
        })
    }

    render() {
        return(
            <Modal isOpen={this.props.isShow} data-theme={this.props.theme?.palette?.type} className="modal-add-item-to-playlist">
                <ModalHeader toggle={this.props.onNegativeButtonClicked}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <span>プレイリストを選択してください</span>
                        <div className="btn-group col-12">
                            <select className="form-control" ref={this.selectPlaylistRef} >
                                {this.renderAirSearchPlayList()}
                            </select>
                        </div>
                        <span ref={this.addPlaylistErrorMes} style={{color: "red"}}></span>
                    </div>
                    <Button color="primary" size="block" onClick={this.addItemToPlaylist}>追加</Button>
                </ModalBody>
            </Modal>
        )
    }
}

export default withTheme(ModalAddItemToPlaylist);
