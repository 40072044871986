import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ScormUrl} from "./VideoDescription";
import {useState} from "react";
import ModalDownloadScorm from "../popup/ModalDownloadScorm";

interface IProps {
    scormUrlList: ScormUrl[],
    getUrlDownloadScormFile: (scormVersion: string, isHideBtnRegister: string, groupId?: number, projectId?: number, groupName?: string) => void,
}

export default function DownloadScormFile(props: IProps) {
    const [isShow, setShow] = useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    return (
        <div className="mt-3">
            <a
                href="#"
                id="basic-button"
                onClick={handleClick}>
                <i className="bi bi-download mr-2"/>
                SCORMファイルをダウンロード
            </a>
            <ModalDownloadScorm isShow={isShow} onClose={handleClose} scormUrlList={props.scormUrlList} getUrlDownloadScormFile={props.getUrlDownloadScormFile} />
        </div>
    );
}