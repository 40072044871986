import React from 'react';
import {withTheme} from '@material-ui/styles';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {StringUtil} from "../util/StringUtil";
import axios from "axios";

interface IProps {
    title: String,
    onNegativeButtonClicked: any,
    isShow: boolean,
    theme: any,
    prgID: string,
    setMessageAlert: any,
}

interface IState {
}

class ModalCreatePlayList extends React.Component<IProps, IState> {

    playListNameRef: any;
    addPlaylistErrorMes: any;

    constructor(props) {
        super(props);
        this.playListNameRef = React.createRef();
        this.addPlaylistErrorMes = React.createRef();
        this.state = {}
    }

    createAndAddItemToPlaylist = () => {
        let playListName = this.playListNameRef.current.value;
        if (playListName) {
            const CUSTOM_PLAY_lIST_TYPE = 5;
            let u = StringUtil.getCookieByName("u");
            let a = StringUtil.getCookieByName("a");
            const params = new URLSearchParams()
            if (typeof u === "string") {
                params.append("u", u);
            }
            if (a != null) {
                params.append("a", a);
            }
            params.append('play_list_type', String(CUSTOM_PLAY_lIST_TYPE));
            params.append('play_list_name', playListName);

            axios({
                url: `${process.env.REACT_APP_API_ENDPOINT}/CreatePlayList`,
                method: 'POST',
                params,
                headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            }).then((res) => {
                let data = res.data;
                let dataRequest = {
                    a,
                    u,
                    prg_id: this.props.prgID,
                    play_list_id: data.play_list_id
                };

                axios({
                    url: `${process.env.REACT_APP_API_ENDPOINT}/AppendContent?u=${u}&a=${a}&play_list_id=${data.play_list_id}&prg_id=${this.props.prgID}`,
                    method: 'POST',
                    data: dataRequest,
                    headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                }).then((res) => {
                    this.props.onNegativeButtonClicked();
                    this.props.setMessageAlert({
                        isShow: true,
                        message: `${playListName} に追加しました`,
                        type: "success"
                    }, 5000);
                }).catch((err) => {
                })
            }).catch((err) => {
                if (err.response) {
                    if (err.response.status === 302) {
                        this.addPlaylistErrorMes.current.innerText = `入力したプレイリスト名が存在しているので、他のプレイリスト名を入力してください。`;
                    }
                }
            })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isShow} data-theme={this.props.theme?.palette?.type}
                   className="modal-add-item-to-playlist">
                <ModalHeader toggle={this.props.onNegativeButtonClicked}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label htmlFor="play-list-name">プレイリスト名</label>
                        <input className="form-control" type="text" ref={this.playListNameRef}
                               placeholder="プレイリスト名を入力してください"/>
                        <span ref={this.addPlaylistErrorMes} style={{color: "red"}}></span>
                    </div>
                    <Button color="primary" size="block" onClick={this.createAndAddItemToPlaylist}>追加</Button>
                </ModalBody>
            </Modal>
        )
    }
}

export default withTheme(ModalCreatePlayList);
