import type {PayloadAction} from '@reduxjs/toolkit'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {Constant} from "../../components/constant/Constant";

export interface ScormSliceState {
    isScorm: boolean,
    prgIdScorm: string,
    cid: string,
    lid: string,
    cmplid: string,
    groupId: string,
    projectId: string,
    sessionTime: number,
    isComplete: boolean,
    totalTime: number,
    isHideBtnRegister: boolean,
}

export interface ScormLearningCourse {
    cid: string,
    lid: string,
    cmplid: string,
}


const initialState: ScormSliceState = {
    isScorm: false,
    prgIdScorm: '',
    cid: window.cid || '',
    lid: window.lid || '',
    cmplid: window.cmplid || '',
    groupId: window.groupId || '',
    projectId: window.projectId || '',
    sessionTime: 0,
    isComplete: false,
    totalTime: 0,
    isHideBtnRegister: window.isHideBtnRegister === "true",
}

if (sessionStorage.getItem(Constant.SCORM_LEARNING_COURSE)) {
    const scormLearningCourse = JSON.parse(sessionStorage.getItem(Constant.SCORM_LEARNING_COURSE) || "") as ScormLearningCourse;
    initialState.cid = scormLearningCourse.cid;
    initialState.lid = scormLearningCourse.lid;
    initialState.cmplid = scormLearningCourse.cmplid;
    sessionStorage.removeItem(Constant.SCORM_LEARNING_COURSE);
}

export const initializeData = createAsyncThunk(
    'data/initializeData',
    async () => {
        const params:any = {
            prgIdEncrypt: window.prgIdScorm,
        }
        let res = await axios.post(`${process.env.REACT_APP_AIR_SEARCH_ENDPOINT}api/program/decrypt-prg-id`, params)
        let data =  res.data;
        return data.prgId;
    }
);

export const settingSlice = createSlice({
    name: 'scorm',
    initialState,
    reducers: {
        setIsScorm: (state, action: PayloadAction<boolean>) => {
            state.isScorm = action.payload;
        },
        increaseSessionTime: (state, action: PayloadAction<number>) => {
            state.sessionTime += action.payload;
            window.sessionTimeScorm = state.sessionTime;
        },
        checkIsComplete: (state, action: PayloadAction<string>) => {
            const bitDataWatched = action.payload.replaceAll("0", "").length;
            const totalTime = bitDataWatched * 10;
            let percent = (bitDataWatched / action.payload.length) * 100;
            const isComplete = percent >= 90;
            if (isComplete && !state.isComplete && window.setCompleted) {
                window.setCompleted();
            }
            state.isComplete = isComplete;
            state.totalTime = totalTime;
            window.isComplete = isComplete;
            window.totalTimeScorm = totalTime;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initializeData.fulfilled, (state, action) => {
                state.isScorm = true;
                state.prgIdScorm = action.payload;
            })
    },
})

export const { setIsScorm, increaseSessionTime, checkIsComplete } = settingSlice.actions

export default settingSlice.reducer