import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch,} from "react-router-dom";
import AuthRoute from "common/AuthRoute";
import UnAuthRoute from "common/UnAuthRoute";

import Landing from "landing/Landing";
import Login from "login/Login";
import Dashboard from "dashboard/DashBoard";
import {useSelector, useDispatch} from "react-redux";
import {AppDispatch, RootState} from "./store";
import {initializeData} from "./features/scorm/scormSlice";

const Routes = () => {

  const dispatch = useDispatch<AppDispatch>()
  const isScorm = useSelector((state: RootState) => state.scorm.isScorm)
  const prgIdScorm = useSelector((state: RootState) => state.scorm.prgIdScorm)

  useEffect(() => {
    if (window.prgIdScorm) {
      dispatch(initializeData());
    }
  })

  return <Router>
    <Switch>
      {
        !window.prgIdScorm &&
        <UnAuthRoute exact path="/content/*">
          <Landing params={ window.location.pathname }/>
        </UnAuthRoute>
      }
      <UnAuthRoute exact path="/login">
        <Login />
      </UnAuthRoute>
      <AuthRoute path="/dashboard">
        <Dashboard />
      </AuthRoute>
      {
          isScorm &&
          <UnAuthRoute exact path="*">
            <Landing params={ prgIdScorm }/>
          </UnAuthRoute>
      }
    </Switch>
  </Router>
}

export default Routes;
