import React from "react";
import "./App.sass";
import { ThemeContext, Theme } from './ThemeContext';
import Routes from "Routes";
import {createMuiTheme, MuiThemeProvider, ThemeOptions} from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from './store'

const THEME_MAP = {
    'dark': {
        palette: {
            type: "dark",
            background: {
                paper: "#222"
            }
        }
    },
    'light': {
        palette: {
            type: "light",
        }
    },
}

declare global {
    interface Window {
        prgIdScorm: string;
        cid: string;
        lid: string;
        cmplid: string;
        groupId: string;
        projectId: string;
        tokenScorm: string;
        scormVersion: string;
        sessionTimeScorm: number;
        isComplete: boolean;
        isReloadScorm: boolean;
        isHideBtnRegister: string;
        setCompleted: () => void;
        totalTimeScorm: number;
    }
}

const App: React.FC<unknown> = () => {
    const [theme, setTheme] = React.useState(Theme.Light);
    const muiTheme = createMuiTheme(THEME_MAP[theme] as ThemeOptions);
    return (
        <Provider store={store}>
            <ThemeContext.Provider value={{ theme, setTheme }}>
                <MuiThemeProvider theme={muiTheme}>
                    <Routes />
                </MuiThemeProvider>
            </ThemeContext.Provider>
        </Provider>
    )
}

export default App;
