import React from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, TabPane} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {StringUtil} from "../util/StringUtil";

interface IProps {
    item: any,
    onDeleteCallback: any,
    onUpdateCallback: any,
    onClickTextArea?: any
    onBlurTextArea?: any,
    onUpdateTab?: any,
    isOpenTabIndex? : any,
    updateVideoTime?: any,
    isNew: boolean,
}

interface IState {
    isEditing: boolean,
    isDropDownOpen: boolean,
    editingText: string
}

class MemoItem extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isDropDownOpen : false,
            editingText: this.props.item.text,
        };
    }

    onClickEdit = (item) => {
        this.setState({
            isEditing: true,
            editingText: item.text
        })
        this.props.onUpdateTab(item);
    };

    getCookieByName = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    };

    onUpdateMemo = () => {
        let newItem = this.props.item;
        newItem.text = this.state.editingText;
        this.props.onUpdateCallback(newItem);
        this.setState({
            isEditing: false
        });

        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        const params = new URLSearchParams()
        params.append('u', u + '');
        params.append('a', a + '');
        params.append('type', '10');
        params.append('commentId', newItem.comment_id);
        params.append('message', newItem.text);
        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/UpdateLecComment`,
            method: 'POST',
            data: params,
            headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then((res) => {
           this.props.onUpdateTab({...this.props.item, comment_id: null});
        }).catch((err) => {
            console.log(err);
        })
    };

    onDeleteMemo = () => {
        this.props.onDeleteCallback(this.props.item);
    };

    render() {
        const { item  } = this.props;
        return (
            <div className={`memo-item column ${this.props.isNew ? 'new' : ''}`}>
                <div className='row d-flex justify-content-between flex-nowrap'>
                    <div className="media">
                        <div className="media-image">
                            <img
                                src={item.image}
                                className="align-self-center mr-3" alt="..."/>
                        </div>
                        <div className="media-body" style={{width: '100%'}}>
                            <div onClick={() => this.props.updateVideoTime(item.second)}><a href="#">{StringUtil.convertTimeIndexToTime(item.second)}</a></div>
                            { this.props.isOpenTabIndex === item.comment_id
                                ? <div style={{width: '100%'}}>
                                    <textarea className="form-control mt-4"
                                              id="editText"
                                              rows={2}
                                              value={this.state.editingText}
                                              onClick={this.props.onClickTextArea}
                                              onBlur={this.props.onBlurTextArea}
                                              onChange={(text) => {this.setState({editingText: text.target.value})}}>
                                    </textarea>
                                    <span style={{color:'#f20a11'}} className="mt-2">
                                        {this.state.editingText.length > 255 ? 'Exceeded characters limit' : ''}
                                    </span>
                                    <div className='row d-flex justify-content-end' style={{marginTop: 12, marginRight: 20}}>
                                        <button type="button"
                                                className="btn btn-link fs-14"
                                                onClick={() => {
                                                    this.setState({isEditing: false, editingText: this.props.item.text });
                                                    this.props.onUpdateTab({...item, comment_id: null});
                                                }}>
                                            キャンセル
                                        </button>
                                        <button type="button"
                                                disabled={this.state.editingText.length > 225 || this.state.editingText === ''}
                                                className="btn btn-primary rounded-pill fs-14"
                                                onClick={this.onUpdateMemo}
                                        >保存
                                        </button>
                                    </div>
                                </div>
                                : <div className="mt-2" style={{wordBreak: "break-word"}}>{item.text}</div>}
                        </div>
                    </div>
                    {
                        this.props.isOpenTabIndex !== item.comment_id && (
                            <Dropdown isOpen={this.state.isDropDownOpen}
                                    toggle={() => {
                                        this.setState({isDropDownOpen: !this.state.isDropDownOpen});
                                    }}
                                    style={{padding: "0 5px 0 0"}}>
                                <DropdownToggle nav className="dropdown-memo-item">
                                    <FontAwesomeIcon icon={faEllipsisV} style={{color: '#ced4da'}}/>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={() => this.onClickEdit(item)}><FontAwesomeIcon icon={faPen}/> 編集</DropdownItem>
                                    <DropdownItem onClick={this.onDeleteMemo}><FontAwesomeIcon icon={faTrash}/> 削除</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default MemoItem;
