import axios from "axios";

const XML_RPC_JSON = `${process.env.REACT_APP_API_ENDPOINT}/xmlrpc/json`;

const executeXmlRpcJson = (methodName, params) => {
  let headers =  {
    "Content-Type": "application/json; charset=utf-8",
  };

  return new Promise((resolve, reject) => {
    axios({
      url: XML_RPC_JSON,
      method: "POST",
      headers,
      data: {
        ...params,
        methodName,
      },
    })
        .then(res => resolve(res))
        .catch(error => reject(error))
  })
}

export default {
  executeXmlRpcJson
}
