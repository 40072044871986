import React from "react";
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import "./Learnings.sass";
import {withTheme} from '@material-ui/styles';
import {connect} from "react-redux";
import {ScormLearningCourse} from "../../features/scorm/scormSlice";
import {Constant} from "../constant/Constant";

interface IProps {
    items: any,
    theme: any,
    isScorm: boolean,
}

interface IState {
    isShow: boolean,
    isOpenModal: boolean,
}

class Learnings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isShow: true,
            isOpenModal: false,
        };
    }

    hiddenMe = () => {
        this.setState({isShow: false})
    }

    toggleModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }

    handleClickLearningCourse = (event: React.MouseEvent, item: any) => {
        event.preventDefault();
        const {attribute: {pack_code, c_sbj_id, c_lec_id, cmpl_id, course_code}} = item;
        const params = cmpl_id ? `?cmplid=${cmpl_id}&lid=${c_lec_id}&cid=${course_code}` : `?pid=${pack_code}&sid=${c_sbj_id}&lid=${c_lec_id}`;
        if (this.props.isScorm) {
            if (cmpl_id && c_lec_id && course_code) {
                const scormLearningCourse: ScormLearningCourse = {
                    cid: course_code,
                    lid: c_lec_id,
                    cmplid: cmpl_id,
                }
                sessionStorage.setItem(Constant.SCORM_LEARNING_COURSE, JSON.stringify(scormLearningCourse));
                window.isReloadScorm = true;
                window.location.reload();
            }
        } else {
            window.location.href = params;
        }
    }

    render() {
        return (
            <>
                {
                    this.props.items && this.props.items.length ?
                        <>
                            <div style={{
                                backgroundColor: blue[50],
                                display: this.state.isShow ? '' : 'none',
                            }} className="learning-container">
                                <div className="learning-content d-flex flex-row align-items-center justify-content-between">
                                    <span className="">このコンテンツを講義として受講したい場合はこちらからコースを選択してください。</span>
                                    <div className="learning-actions">
                                        <Button onClick={this.toggleModal} variant="contained" color="primary">
                                            選択
                                        </Button>

                                        <Button onClick={this.hiddenMe} variant="contained" color="default">
                                            閉じる
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Modal size="md" isOpen={this.state.isOpenModal} data-theme={this.props.theme?.palette?.type}>
                                <ModalHeader>受講可能な講義</ModalHeader>
                                <ModalBody>
                                    <div className="learning-course-list">
                                        <ul>
                                            {this.props.items.map((item, index) => {
                                                const {attribute: {attendance_req, course_name, pack_name}} = item;
                                                return (
                                                    <li key={index}>
                                                        <a href="#" onClick={(event) => { this.handleClickLearningCourse(event, item) }}>
                                                            {attendance_req ? <strong>【認証有】</strong> : null}
                                                            {course_name ? course_name : pack_name}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={this.toggleModal} color="default">キャンセル</Button>
                                </ModalFooter>
                            </Modal>
                        </> : null
                }
            </>
        )
    };
}

const mapStateToProps = (state) => ({
    isScorm: state.scorm.isScorm
});
export default connect(mapStateToProps, null, null, {forwardRef: true})(withTheme(Learnings));
