import React, {useState} from "react";
import {Container, Row, Button, Col, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, Alert} from "reactstrap";
import {useHistory} from "react-router-dom";
import {Formik} from 'formik';
import "./Login.sass";
import axios from "axios";

const Login = () => {
    const [error, setError] = useState('');
    const history = useHistory();
    const submitForm = async (values: { username: string, token: string }, {setSubmitting}: { setSubmitting: Function }) => {
        try {
            // let data = await callLogin(values.username, values.token);
            let data = {"methodName": "ac.CheckAuth", "u": values.username, "a": values.token}
            // @ts-ignore
            axios.post(process.env.REACT_APP_API_AUTH, data).then((response) => {

                if (response != undefined && response.data?.success) {
                    setCookies("u", values.username);
                    setCookies("a", values.token);

                }
                setSubmitting(false);
                let currentUrl = window.location.href;
                let url = new URL(currentUrl);
                let redirectUrl = url.searchParams.get("continue");
                if (redirectUrl != null) {
                    window.location.href = redirectUrl;
                }

            })

            // history.push('/dashboard')
        } catch (ex: any) {
            setError(ex.message);
            setSubmitting(false);
        }
    };
    const setCookies = (name, value) => {
        let myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 12);
        document.cookie = (name + "=" + value + ";expires=" + myDate  + ";domain="+ window.location.hostname +";path=/");
    };


    return (
        <Container className="p-0">
            <Row className="mt-5 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 d-flex justify-content-center">
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="d-flex justify-content-center">
                                <h2>Log in</h2>
                            </CardTitle>
                            <Formik
                                initialValues={{username: '', token: ''}}
                                validate={values => {
                                    const errors: { username?: string, token?: string } = {};
                                    // if (!values.username) {
                                    //     errors.username = 'Required';
                                    // } else if (
                                    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
                                    // ) {
                                    //     errors.username = 'Invalid email address';
                                    // }
                                    //
                                    // if (!values.token) {
                                    //     errors.token = 'Required'
                                    // }
                                    return errors;
                                }}
                                onSubmit={submitForm}>
                                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                                    <Form className={"form"} onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Username</Label>
                                            <Input type="text" name="username"
                                                   onChange={handleChange} onBlur={handleBlur} value={values.username}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">Token</Label>
                                            <Input type="text" name="token"
                                                   onChange={handleChange} onBlur={handleBlur} value={values.token}/>
                                        </FormGroup>

                                        {error && <Alert color="danger">
                                            {error}
                                        </Alert>}
                                        <Button color="primary" className="mt-4 w-25" type="submit"
                                                disabled={isSubmitting}>Login</Button>
                                    </Form>
                                )}
                            </Formik>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;