import React from 'react';
import {withTheme} from '@material-ui/styles';
import {Modal, ModalBody, ModalHeader, Table} from 'reactstrap';
import './ModalKeyboardShortcuts.sass';

interface IProps {
    title: String,
    onNegativeButtonClicked: any,
    isShow: boolean,
    theme: any,
}

interface IState {
}

class ModalKeyboardShortcuts extends React.Component<IProps, IState> {

    render() {
        return(
            <>
                <Modal isOpen={this.props.isShow} data-theme={this.props.theme?.palette?.type} className="modal-table-keyboard-shortcuts">
                    <ModalHeader toggle={this.props.onNegativeButtonClicked}>
                        {this.props.title}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <div className="table-keyboard-shortcuts reproduction">
                                    <Table bordered>
                                        <tbody>
                                        <tr>
                                            <td>再生 / 一時停止</td>
                                            <td className="shortcut-key">k</td>
                                        </tr>
                                        <tr>
                                            <td>10秒巻戻し</td>
                                            <td className="shortcut-key">j or ←</td>
                                        </tr>
                                        <tr>
                                            <td>10秒早送り</td>
                                            <td className="shortcut-key">l or →</td>
                                        </tr>
                                        <tr>
                                            <td>音量を上げる</td>
                                            <td className="shortcut-key">↑</td>
                                        </tr>
                                        <tr>
                                            <td>音量を下げる</td>
                                            <td className="shortcut-key">↓</td>
                                        </tr>
                                        <tr>
                                            <td>ミュートの切り替え</td>
                                            <td className="shortcut-key">m</td>
                                        </tr>
                                        <tr>
                                            <td>再生速度を上げる</td>
                                            <td className="shortcut-key">&gt;</td>
                                        </tr>
                                        <tr>
                                            <td>再生速度を下げる</td>
                                            <td className="shortcut-key">&lt;</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="table-keyboard-shortcuts screen">
                                    <Table bordered>
                                        <tbody>
                                        <tr>
                                            <td>動画とスライドの入れ替え</td>
                                            <td className="shortcut-key">f</td>
                                        </tr>
                                        <tr>
                                            <td>集中モード切り替え</td>
                                            <td className="shortcut-key">d</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="table-keyboard-shortcuts note">
                                    <Table bordered>
                                        <tbody>
                                        <tr>
                                            <td>メモの入力開始</td>
                                            <td className="shortcut-key">i</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3}>メモの保存</td>
                                            <td className="shortcut-key">Ctrl + Enter</td>
                                        </tr>
                                        <tr>
                                            <td className="shortcut-key">Shift + Enter</td>
                                        </tr>
                                        <tr>
                                            <td className="shortcut-key">Cmd + Enter</td>
                                        </tr>
                                        <tr>
                                            <td>メモの入力終了</td>
                                            <td className="shortcut-key">Esc</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default withTheme(ModalKeyboardShortcuts);
