

export class Constant {
    public static get GET_SUBSCRIPTION_API():string {return "/GetSubtitles?prg_id={0}&language={1}&u={2}&a={3}";}
    public static get GET_SUBSCRIPTION_LANGUAGES_SUPPORT():string {return "/GetSubtitlesLanguageList?prg_id={0}&u={1}&a={2}";}
    public static get STREAM_VIDEO_API():string {return "/video/streamVideo?videoUrl=";}
    public static get JAPANESE_LANGUAGE_CODE():string {return "ja-JP";}
    public static get ENGLISH_LANGUAGE_CODE():string {return "en-US";}
    public static get SUBSCRIPTION_KIND():string {return "subtitles";}
    public static get UP_LOAD_VIDEO():string {return "/UploadVideo?u={0}&a={1}";}
    public static get DELETE_SUBTITLE():string {return "/DeleteSubtitles?prg_id={0}&u={1}&a={2}";}
    public static get VIDEO_INDEXER():string {return "自動音声字幕と自動翻訳字幕を生成";}
    public static get MESSAGE_SUCCESS():string {return "Upload Successfully（ブラウザを閉じて大丈夫です）";}
    public static get MESSAGE_LOADING():string {return "Uploading（ブラウザを閉じないでください）";}
    public static get MESSAGE_RESPONSE():string {return "success"}
    public static get MESSAGE_FAIL():string {return "エラーが発生しました。もう一度登録してください。"}
    public static get S3_SIGN():string {return "https://s3."}
    public static get AIR_SEARCH_PATH():string {return "/svlAirSearch"}
    public static get STRING_EMPTY():string {return ""}
    public static get BR():string {return "<br>"}
    public static get MAX_LENGTH():number {return 200}
    public static get CHECK_IS_ADMIN():string {return "/CheckAdminPermission";}
    public static get CHECK_USER_PERMISSION():string {return "/CheckUserPermission";}
    public static get AC_PATH():string {return "/svlAC"}
    public static get JAPANESE_LANGUAGE():string {return "日本語";}
    public static get ENGLISH_LANGUAGE():string {return "英語";}
    public static get ACCOUNT_FROM():string {return "登録情報";}
    public static get LOGOUT():string {return "ログアウト";}
    public static get LOGIN():string {return "ログイン";}
    public static get GET_AVATAR_IMG():string {return "/GetPresence3?format=json&u={0}&a={1}";}
    public static get HTTP():string {return "http";}
    public static get HTTPS():string {return "https";}
    public static get PLAYER_OLD_VERSION_TEXT():string {return "以前のAirSearch TOPへ";}
    public static get VIDEO_DELETE_INDEXER():string {return "字幕を削除";}
    public static get MESSAGE_DELETE_LOADING():string {return "Delete（ブラウザを閉じないでください）";}
    public static get MESSAGE_DELETE_SUCCESS():string {return "Delete Successfully（ブラウザを閉じて大丈夫です）";}
    public static get DELETE_MEMO_CONFIRM_MESSAGE():string {return "このメモを削除しますか";}
    public static get DELETE_MEMO_CONFIRM_TITLE():string {return "学習メモ削除";}
    public static get DELETE_MEMO_CONFIRM_BUTTON_TEXT():string {return "削除します";}
    public static get CANCEL_BUTTON_TEXT():string {return "キャンセル";}
    public static get FAVORITE_MEMO_TYPE(): number {return 40;}
    public static get NORMAL_MEMO_TYPE(): number {return 10;}
    public static get REQUEST_PLANNING_CONTENT(): string{return "コンテンツ企画の要望";}
    public static get CONTENT_PLAYER_OLD_VERSION_TEXT():string {return "以前の受講画面を開く";}
    public static get KEYCODE_K(): number {return 75}
    public static get KEYCODE_J(): number {return 74}
    public static get KEYCODE_L(): number {return 76}
    public static get KEYCODE_F(): number {return 70}
    public static get KEYCODE_D(): number {return 68}
    public static get KEYCODE_ARROW_LEFT(): number {return 37}
    public static get KEYCODE_ARROW_RIGHT(): number {return 39}
    public static get KEYCODE_I(): number {return 73}
    public static get KEYCODE_ENTER(): number {return 13}
    public static get SEEK_STEP_10_SECOND(): number {return 10}
    public static get KEYCODE_COMMA(): number {return 188}
    public static get KEYCODE_PERIOD(): number {return 190}
    public static get KEYCODE_ESC(): number {return 27}
    public static get KEYBOARD_SHORTCUTS():string {return "キーボードショートカット";}
    public static get WIDTH_HEIGHT_RATIO(): number {return 16 / 9};
    public static get AUTO_LANGUAGE_CODE():string {return "auto";}
    public static get SPANISH_LANGUAGE_CODE():string {return "es-ES";}
    public static get FRENCH_LANGUAGE_CODE():string {return "fr-FR";}
    public static get GERMAN_LANGUAGE_CODE():string {return "de-DE";}
    public static get ITALIAN_LANGUAGE_CODE():string {return "it-IT";}
    public static get MANDARIN_LANGUAGE_CODE():string {return "zh-Hans";}
    public static get RUSSIAN_LANGUAGE_CODE():string {return "ru-RU";}
    public static get PORTUGUESE_LANGUAGE_CODE():string {return "pt-BR";}
    public static get AUTO_LANGUAGE():string {return "自動検出";}
    public static get SPANISH_LANGUAGE():string {return "スペイン語";}
    public static get FRENCH_LANGUAGE():string {return "フランス語";}
    public static get GERMAN_LANGUAGE():string {return "ドイツ語";}
    public static get ITALIAN_LANGUAGE():string {return "イタリア語";}
    public static get MANDARIN_LANGUAGE():string {return "北京語";}
    public static get RUSSIAN_LANGUAGE():string {return "ロシア語";}
    public static get PORTUGUESE_LANGUAGE():string {return "ポルトガル語";}
    public static get SORT_ORDER_LANGUAGE() {return ['English(Google)', 'English(MSVI)', 'English', 'English(自動生成)', 'Japanese(Google)', 'Japanese(MSVI)', 'Japanese', '日本語(自動生成)'];}
    public static get FONTSIZE_SUBTITLE_MOBILE(): number {return 10}
    public static get URL_REGEX(): RegExp {return /https?:\/\/[^\s<>&]+/g}
    public static get SCORM_LEARNING_COURSE() {return "scormLearningCourse";}
}
