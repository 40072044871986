import React from 'react';
import axios from "axios";
import {StringUtil} from "../util/StringUtil";
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

type EChartsOption = echarts.EChartsOption;

interface IState {
    haveBitData: boolean,
    bitData: number[],
    option: EChartsOption,
}

interface IProps {
    prgID: string;
    isAdmin: boolean;
}

class ChartBitData extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            haveBitData: false,
            bitData: [],
            option: {},
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.isAdmin && this.props.isAdmin !== prevProps.isAdmin) {
            this.getBitDataSummary();
        }
    }

    getBitDataSummary = () => {
        let userId = StringUtil.getCookieByName("u");
        let accessToken = StringUtil.getCookieByName("a");
        let params: any = {
            prg_per_id: this.props.prgID,
            u: userId,
            a: accessToken
        };
        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/GetBitDataSummary`,
            method: "GET",
            params,
        }).then(response => {
            if (response.data?.success === true && response.data?.bit_data) {
                let arrBitData = response.data?.bit_data?.bit_data.split(",");
                if (typeof arrBitData != 'undefined' && arrBitData.length > 0) {
                    let seriesData = [] as number[];
                    let xAxisData = [] as number[];
                    for (let i = 1; i <= arrBitData.length; i++) {
                        let times = Number(`${arrBitData[i - 1]}`);
                        seriesData.push(times);
                        xAxisData.push((i - 1) * 10)
                    }
                    let option: EChartsOption = {
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (params) {
                                let axisValue = params[0].axisValue;
                                axisValue = Number.parseInt(axisValue);
                                let axisLabel = `${StringUtil.formatTime(axisValue)} - ${StringUtil.formatTime(axisValue + 10)}`;
                                return `${axisLabel}<br/>
                                        ${params[0].seriesName}: ${params[0].value}<br/>
                                        ${(typeof params[1].value === "number") 
                                        ? `${params[1].seriesName}: ${params[1].value.toFixed(2)}` : ''}`;
                            },
                            axisPointer: {
                                type: 'line',
                            }
                        },
                        grid: {
                            left: '0',
                            right: '15px',
                            bottom: '0',
                            top: '15px',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            data: xAxisData,
                            axisLabel: {
                                formatter: function (value) {
                                    return StringUtil.secondsToHHMMStringJp(value);
                                }
                            }
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: 'Times',
                                type: 'line',
                                showSymbol: false,
                                data: seriesData,
                                lineStyle: {
                                    width: 2,
                                    color: '#8FC97A',
                                }
                            },
                            {
                                name: 'Moving Average',
                                type: 'line',
                                data: this.calculateMA(6, seriesData),
                                smooth: true,
                                showSymbol: false,
                                lineStyle: {
                                    width: 2,
                                    color: '#5470C6',
                                }
                            },
                        ]
                    };
                    this.setState({
                        haveBitData: true,
                        bitData: arrBitData.map(str => Number(str)),
                        option: option,
                    })
                }

            }
        })
    }

     calculateMA = (period, data) => {
        let result = [] as any[];
        for (let i = 0, len = data.length; i < len; i++) {
            if (i < period) {
                result.push('-');
                continue;
            }
            let sum = 0;
            for (let j = 0; j < period; j++) {
                sum += +data[i - j];
            }
            result.push(sum / period);
        }
        return result;
    }

    handleLogarithmicCheckboxChange = (event) => {
        let option = this.state.option;
        if (event.target.checked) {
            option = {...option,
                yAxis: {
                    type: 'log',
                }
            }
        } else {
            option = {...option,
                yAxis: {
                    type: 'value',
                }
            }
        }
        this.setState({
            option: option,
        })
    }

    render() {
        return (
            <>
                {
                    this.state.haveBitData &&
                    <div className="form-group mt-4">
                        <div className="text-right">
                            <input type="checkbox" onChange={this.handleLogarithmicCheckboxChange}/> Logarithmic Axis
                        </div>
                        <ReactEcharts
                            option={this.state.option}
                            style={{height: '250px', width: '100%'}}
                            className={'react_for_echarts'}
                        />
                    </div>
                }
            </>
        );
    }
}

export default ChartBitData;