import React from "react";
import {cloneDeep} from "lodash";
import './VideoSlider.sass';

import {Carousel, CarouselControl, CarouselItem,} from 'reactstrap';
import Authentication from "../authentication/Authentication";
import ModalPopup from "../popup/ModalPopup";
import {Constant} from "../constant/Constant";
import SliderDownload from "./SliderDownload";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import {StringUtil} from "../util/StringUtil";
import { connect } from "react-redux";

interface IProps {
    programId?: string,
    slideItems: Attribute[],
    timeIndex?: any,
    onSwitchSlideManual?: any,
    manualMode: boolean,
    viewPermission?: boolean,
    documentUrls?: any,
    addNewMemo?: any,
    deleteMemo?: any,
    isContentFree: boolean,
    isHideNumberFavorite: boolean,
}

interface Slider {
    doc: string,
    index_time: number,
    prg_per_id: string,
    index_title: string,
    url: string,
    active?: boolean,
    isFavorite: boolean,
    index_type: number,
    favorite_num: number
}

interface Attribute {
    index: number;
    attribute: Slider;
}

interface IState {
    animating: boolean,
    activeIndex: number,
    autoSlide: boolean,
    slidesList: any,
    isShowDeleteMemoPopup: boolean | false,
    favoriteSlideHover: number,
    slidesListIndex: number,
}

class VideoSlider extends React.Component<IProps, IState> {
    slideItemActive: HTMLImageElement | undefined;

    constructor(props: IProps) {
        super(props);
        this.state = {
            animating: false,
            activeIndex: 0,
            autoSlide: !this.props.manualMode,
            slidesList: this.props.slideItems,
            isShowDeleteMemoPopup: false,
            favoriteSlideHover: -1,
            slidesListIndex: -1,
        };
    }

    setActiveIndex = (index) => {
        this.setState(prevState => ({
            activeIndex: index,
            animating: prevState.animating
        }))
    };

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.props.slideItems.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setActiveIndex(nextIndex);
        this.props.onSwitchSlideManual();
        this.setState({autoSlide: false})
    };

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.slideItems.length - 1 : this.state.activeIndex - 1;
        this.setActiveIndex(nextIndex);
        this.setState({autoSlide: false})
        this.props.onSwitchSlideManual();
    };
    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setActiveIndex(newIndex);
        this.props.onSwitchSlideManual();
    };

    setAnimating = (animating) => {
        this.setState(prevState => ({
            activeIndex: prevState.activeIndex,
            animating: animating
        }))
    };

    convertTimeToIndex = (timeIndex) => {
        let activeIndex = 0;
        let min = Number.MAX_VALUE;
        this.props.slideItems?.forEach((item, index) => {
            let sub = (timeIndex - item.attribute.index_time);
            if (sub >= 0 && sub <= min && item.attribute.index_type !== 2) {
                min = sub;
                activeIndex = index;
            }
        });
        return activeIndex;
    };
    handleAutoSlide = () => {
        let autoSlideMode = true
        this.setState({autoSlide: autoSlideMode});
        let targetIndex = this.convertTimeToIndex(this.props.timeIndex);
        this.goToIndex(targetIndex);
    }

    handleClickOnFavoriteIcon = (slidesListIndex?: number) => {
        let tmpSlidesList = cloneDeep(this.state.slidesList);
        let itemUpdate = tmpSlidesList[(slidesListIndex != undefined) ? slidesListIndex : this.state.activeIndex];
        if (itemUpdate.attribute.isFavorite) {
            this.setState({
                isShowDeleteMemoPopup: true
            })
            if (slidesListIndex != undefined) {
                this.setState({
                    slidesListIndex: slidesListIndex,
                })
            }
        } else {
            itemUpdate.attribute.isFavorite = true;
            if (itemUpdate.attribute.index_type === 1) {
                let activeIndex = 0;
                let min = Number.MAX_VALUE;
                tmpSlidesList.forEach((item,index) => {
                    let sub =  (this.props.timeIndex - item.attribute.index_time);
                    if(sub >= 0 && sub <= min && item.attribute.index_type !== 1){
                        min = sub;
                        activeIndex = index;
                    }
                });
                let itemUpdateParent = tmpSlidesList[activeIndex];
                itemUpdate.attribute.index_title = itemUpdateParent.attribute.index_title;
            }
            this.props.addNewMemo(cloneDeep(itemUpdate.attribute));
            itemUpdate.attribute.favorite_num ++;
        }
        this.setState({
            slidesList: tmpSlidesList
        })
    }

    onConfirmDeleteFavoriteMemo = () => {
        let tmpSlidesList = cloneDeep(this.state.slidesList);
        let itemUpdate = tmpSlidesList[(this.state.slidesListIndex != undefined && this.state.slidesListIndex !== -1) ? this.state.slidesListIndex : this.state.activeIndex];
        if (itemUpdate) {
            itemUpdate.attribute.isFavorite = false;
            this.props.deleteMemo(cloneDeep(itemUpdate.attribute));
            this.setState({
                slidesList: tmpSlidesList,
                isShowDeleteMemoPopup: false
            })
            itemUpdate.attribute.favorite_num --;
            this.setState({
                slidesListIndex: -1,
            })
        }
    }

    renderImageUrl = (doc) => {
        return `https://aaa.bbt757.com/asp/data/${this.props.programId}/ldoc/${doc}`;
    };

    onMouseEnterTimeline = (timeIndex) => {
        let index = this.convertTimeToIndex(timeIndex);
        let imageUrl = this.renderImageUrl(this.state.slidesList[index].attribute.doc);
        let slideImage = document.querySelector(".carousel .slide-image-wrapper.carousel-item.active .slide-image") as HTMLImageElement;
        slideImage.dataset.src = slideImage.src;
        slideImage.src = imageUrl;
        this.slideItemActive = slideImage;
        this.setState({
            favoriteSlideHover: index
        });
    }

    onMouseLeaveTimeline = () => {
        if (this.slideItemActive) {
            this.slideItemActive.src = this.slideItemActive.dataset.src as string;
        }
        this.setState({
            favoriteSlideHover: -1
        });
    }

    setContentFavoriteSlideEle = (index) => {
        let isAuthentication = new Authentication().checkCookieHaveUsernameAndToken();
        return <>
            <i className={`bi bi-bookmark-fill ${this.state.slidesList[index].attribute.isFavorite ? "favorite-icon" : "not-favorite-icon"}`}
               hidden={!isAuthentication}
               onClick={() => this.handleClickOnFavoriteIcon(undefined)}
            />
            {
                (this.state.slidesList[index].attribute.favorite_num >= 0 && !this.props.isHideNumberFavorite) &&
                <Tooltip title={`${this.state.slidesList[index].attribute.favorite_num}人がこのスライドをブックマークしています`}
                placement="left">
                    <span className="favorite-num" onClick={() => this.handleClickOnFavoriteIcon(undefined)}>
                        <span>{`${this.state.slidesList[index].attribute.favorite_num < 100 ?
                            this.state.slidesList[index].attribute.favorite_num : '99+'}`}</span>
                    </span>
                </Tooltip>
            }
        </>
    }

    updateFavoriteNum = () => {
        let u = StringUtil.getCookieByName("u");
        let a = StringUtil.getCookieByName("a");
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetFavoriteSlide?u=${u}&a=${a}&prg_id=${this.props.programId}`)
            .then(res => {
                const listFavoriteNum = res.data?.items;
                let favoriteNumMap = new Map(listFavoriteNum.map(item => [item.index_time, item.favorite_num]));
                let slidesList = this.state.slidesList;
                if (slidesList != undefined && favoriteNumMap != undefined) {
                    slidesList = slidesList.map(item => {
                        if (favoriteNumMap.has(item.attribute.index_time)) {
                            item.attribute.favorite_num = favoriteNumMap.get(item.attribute.index_time);
                        } else {
                            item.attribute.favorite_num = 0;
                        }
                        return item;
                    });
                    this.setState({
                        slidesList: slidesList
                    });
                }
            }).catch(err => {
        })
    }

    render() {

        let isAuthentication = new Authentication().checkCookieHaveUsernameAndToken();
        {
            if (this.props.slideItems !== undefined && this.props.slideItems.length > 0) {
                this.props.slideItems.map((item, index) => {
                    item.index = index
                });
                return (
                    <div className="position-relative">
                        <Carousel
                            className={`${(this.props.isContentFree || !isAuthentication) ? 'slide-free' : ''}`}
                            activeIndex={this.state.activeIndex}
                            interval={false}
                            autoPlay={false}
                            next={this.next}
                            previous={this.previous}
                            keyboard={false}
                            slide={this.state.slidesList[this.state.activeIndex].attribute.index_type !== 1}>
                            {
                                this.state.slidesList?.map((item, index) => {
                                    return (
                                        <CarouselItem
                                            onExiting={() => this.setAnimating(true)}
                                            onExited={() => this.setAnimating(false)}
                                            className={"slide-image-wrapper"}
                                            key={item.index}>
                                            <img src={this.renderImageUrl(item.attribute.doc)}
                                                 alt={item.attribute.index_title}
                                                 className={"d-block slide-image " + (this.props.isContentFree || (isAuthentication && this.props.viewPermission) ? "" : " filterBlur2")}/>
                                        </CarouselItem>
                                    );
                                })
                            }
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
                        </Carousel>
                        <span className="favorite-slide">
                            {
                                this.state.favoriteSlideHover >= 0 ? this.setContentFavoriteSlideEle(this.state.favoriteSlideHover) :
                                    this.setContentFavoriteSlideEle(this.state.activeIndex)
                            }
                        </span>
                        { (isAuthentication) &&
                        <div className="mt-2">
                            <SliderDownload items={this.props.documentUrls || []}/>
                        </div>
                        }
                        {
                            (!this.state.autoSlide) ?
                                <button type="button"
                                        className="btn btn-primary rounded-pill float-right fs-14 blue-play-button"
                                        onClick={this.handleAutoSlide}>自動スクロール再開
                                </button>
                                : <div hidden={true}></div>
                        }
                        <ModalPopup
                            isShow={this.state.isShowDeleteMemoPopup}
                            title={"スライドのお気に入りを削除"}
                            message={"このスライドのお気に入りを削除しますか？"}
                            positiveButton={Constant.DELETE_MEMO_CONFIRM_BUTTON_TEXT}
                            negativeButton={Constant.CANCEL_BUTTON_TEXT}
                            onPositiveButtonClicked={this.onConfirmDeleteFavoriteMemo}
                            onNegativeButtonClicked={() => {
                                this.setState({isShowDeleteMemoPopup: false})
                            }}
                        />
                    </div>
                );
            } else {
                return <div/>;
            }
        }
    };
    checkRatioNinePerSixTeen (imageRatio) {
        if(imageRatio >= 0.5 && imageRatio <= 0.6){
            return true;
        }
        return false;
    }
    updateSliderImageSize() {
        let slideImageWrapper = document.querySelectorAll('.slide-image-wrapper');
        let widthWrapperDiv = document.getElementsByClassName("slide-image-wrapper active")[0].clientWidth;
        slideImageWrapper.forEach((value, key) => {
            if (value instanceof HTMLElement) {
                let height = widthWrapperDiv / Constant.WIDTH_HEIGHT_RATIO;
                let imageFitContent:any = value.querySelector('.slide-image');
                imageFitContent.style.height = height + "px";
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.timeIndex !== undefined) {
            // let prevTimeIncex = this.convertTimeToIndex(prevProps.timeIndex);
            let targetIndex = this.convertTimeToIndex(this.props.timeIndex);
            if (targetIndex != prevState.activeIndex && !this.props.manualMode && this.state.autoSlide) {
                this.goToIndex(targetIndex);
            }
        }
    }
    componentDidMount() {
        this.updateSliderImageSize();
        window.addEventListener('resize', () => {
            this.updateSliderImageSize();
        });
        this.updateFavoriteNum();
    }
}

const mapStateToProps = (state) => ({
    isHideNumberFavorite: state.setting.isHideNumberFavorite
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(VideoSlider);
