import React from 'react';
import VideoJS from 'components/videojs/Videojs';
import ControlsBar from './ControlsBar';
import Snackbar from '@material-ui/core/Snackbar';
import PlayerAbstract from "./PlayerAbstract";

interface IProps {
    params?: any;
    videoIndex?: any;
    seekPos?: any;
    seekFromTimeLine?: number;
    autoPlay?: boolean,
    isContainCur: boolean,
    onAuthenticationChanged: any,
    isWriting?: boolean,
    videoCtrlRef: any,
    poster: string
}

interface IState {
    playbackRate: any;
    playing: any;
    videoIndex: any;
    isReady: boolean,
    isShowErrorSync: boolean,
}

class PlayerFree extends PlayerAbstract<IProps, IState> {

    constructor(props) {
        super(props);
        this.controlsBarRef = React.createRef();
        this.state = {
            playbackRate: 1.0,
            playing: this.props.autoPlay,
            videoIndex: 0,
            isReady: false,
            isShowErrorSync: false,
        };
    }

    componentDidUpdate(previousPos, previousState) {
        if (this.props.seekFromTimeLine !== previousPos.seekFromTimeLine) {
            this.handleSeekToPos();
        }
    }

    handleSeekToPos = () => {
        let seekToPos = this.props.seekPos;
        if (seekToPos < 0) {
            seekToPos = 0
        }
        this.videoPlayer.currentTime(seekToPos);
        this.videoPlayer.play();
        this.setState({playing: true});
    }

    handleSetPlaybackRate = (playbackRate) => {
        this.videoPlayer.playbackRate(parseFloat(playbackRate));
        this.setState({playbackRate: parseFloat(playbackRate)});
    }

    onPlayerReady = (player) => {
        this.videoPlayer = player;
        this.videoPlayer.volume(0.5);
        this.handleOnVideoReady();
    }

    handleOnVideoReady = () => {
        this.setState({isReady: true});
        if (this.props.isContainCur) {
            //todo comment for feature autoplay video
            // this.setState({playing: true});
        }
    }

    handleProgress = () => {
        let playedPosition = this.videoPlayer.currentTime();
        let position = parseInt(playedPosition, 10);
        this.videoTime = position;

        // Update appBody video position for slider and memo
        this.props.videoIndex(position);
        this.setState({videoIndex: position})

        // check video is seeked update counter equal postion
        if (this.videoPlayer.seeking()) {
            this.counter = Math.floor(position / 10) * 10;
        }
    }

    render() {
        return (
            <>
                <div className={`player d-flex flex-column align-items-center ${this.props.isWriting ? "" : 'course-video'}`}>
                    <div id="player-video" className="player-video" style={{width: '100%'}} ref={this.props.videoCtrlRef}>
                        <div className="embed-responsive embed-responsive-16by9">

                            <div className="embed-responsive-item">
                                <VideoJS
                                    onTimeUpdate={this.handleProgress}
                                    onPlay={() => {
                                        this.setState({playing: true});
                                    }}
                                    onPause={() => this.setState({playing: false})}
                                    onReady={this.onPlayerReady}
                                    playbackRates={[]}
                                    onSeeking={() => this.isSeeking = true}
                                    onSeeked={(position, completeTime) => {
                                        this.isSeeking = false;
                                        this.videoTime = completeTime;
                                        this.isSeeked = true;
                                    }}
                                    src={this.renderVideoUrl(this.props.params)}
                                    qualitySelected={this.qualitySelected}
                                    qualityRequested={this.qualityRequested}
                                    onEnd={function () {return}}
                                    poster={this.props.poster}
                                    reloadVideoUrl={this.reloadVideoUrl}
                                    thumbnails={this.props.params?.thumbnails?.item}
                                    slides={this.props.params?.slides?.item}
                                />
                            </div>
                        </div>
                        <ControlsBar
                            seekNext5Seconds={this.seekNext5Seconds}
                            seekNext10Seconds={this.seekNext10Seconds}
                            seekBack5Seconds={this.seekBack5Seconds}
                            seekBack10Seconds={this.seekBack10Seconds}
                            seekTo={this.handleSetPlaybackRate}
                            ref={this.controlsBarRef}
                            />
                    </div>
                </div>
                <Snackbar
                    open={this.state.isShowErrorSync}
                    onClose={() => this.setState({isShowErrorSync: false})}
                    autoHideDuration={5000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    children={<div className="alert alert-warning" style={{maxWidth: 350}}>受講データのサーバー登録に失敗しました。このアラートが連続して出る場合、通信環境に問題がある可能性があるため、ご利用中のネットワーク接続をご確認ください。</div>}
                />
            </>
        );
    }
}

export default PlayerFree;