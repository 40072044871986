import axios from 'axios';
import {Constant} from "../constant/Constant";


class Authentication {

    checkAuthentication = (callback?:any) => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        if (u != undefined && u.trim() != "" && a != undefined && a.trim() != "") {
            let data = {
                "methodName": "ac.CheckAuth",
                "u": u,
                "a": a
            };
            // @ts-ignore
            axios.post(process.env.REACT_APP_API_AUTH, data).then((response) => {
                if (response.status != 200 || response.data.success == false) {
                    this.logout();
                }else{
                    if(callback){
                        callback(response);
                    }
                }
            })
        }
    };

    checkCookieHaveUsernameAndToken = () => {
        let cookies = document.cookie.split(";");
        let count = 0;
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            let value = eqPos > -1 ? cookie.substr(eqPos, cookie.length) : cookie;
            if (name != undefined && (name.trim() === "a" || name.trim() === "u") && value !== undefined && value.trim() != "") {
                count++;
            }
        }
        if (count == 2) {
            return true;
        } else {
            return false
        }
    };

    getLoginPage = () => {
        const host = window.location.host;
        if (host.indexOf('14.177.235.12') >= 0 || host.indexOf('172.16.10.224') >= 0 || host.indexOf('localhost') >= 0) {
            return `/login?continue=${window.location.href}`
        }
        return process.env.REACT_APP_API_AUTH_PAGE + "?continue=" + window.location.href;
    };


    logout = () => {
        let logoutUrl = process.env.REACT_APP_LOGOUT_PAGE  + "?continue=" + encodeURIComponent(window.location.href);
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        // window.location.reload();
        window.location.href = logoutUrl;
    }

    logoutRedirectLoginPage = () => {
        let loginPageUrl = process.env.REACT_APP_API_AUTH_PAGE + "?continue=" + window.location.href;
        let logoutUrl = process.env.REACT_APP_LOGOUT_PAGE  + "?continue=" + encodeURIComponent(loginPageUrl);
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        window.location.href = logoutUrl;
    }


    getCookieByName = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    }

    checkIsAdmin = (callback?:any) => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        axios.get(process.env.REACT_APP_API_ENDPOINT + Constant.CHECK_USER_PERMISSION + `?u=${u}&a=${a}`)
            .then((response) => {
                if(response.data.success === true){
                    if(callback){
                        const permission = {
                            update: response.data.role === "admin",
                            view: response.data.role === "admin" || response.data.role === "user"
                        }
                        callback(permission);
                    }
                }
            }).catch(err => {})
    }
}

export default Authentication;