export class StringUtil {
    public static stringFormat = (str: string, ...args: any[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    public static isEmptyOrSpaces = (str) =>
        (!str || str.length === 0 || /^\s*$/.test(str))

    public static getUrlRelatedContents = (prgPerId) => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        params.delete("cid");
        params.delete("lid");
        params.delete("cmplid");
        let parameter = "";
        if (Array.from(params).length > 0) {
            parameter = `?${params.toString()}`;
        }
        let urlRelatedContents = `${process.env.REACT_APP_DOMAIN_URL}/content/${prgPerId}${parameter}`;
        return StringUtil.addUrlBackParameter(urlRelatedContents);
    }

    public static getMinuteAndSecondByTime = (time) => {
        if (time == null) return "00:00";
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        let minute = minutes > 9 ? minutes : "0" + minutes;
        let second = seconds > 9 ? seconds : "0" + seconds;
        return minute + ":" + second;
    }

    static getCookieByName = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    };

    static addUrlBackParameter = (href) => {
        let urlBack = sessionStorage.getItem("urlBack" + window.location.href) || "";
        if (urlBack) {
            let url = new URL(href);
            let searchParams = new URLSearchParams(url.search);
            searchParams.append("urlBack", urlBack);
            url.search = searchParams.toString();
            return url.toString();
        }
        return href;
    }

    static srtTimestamp = (milliseconds) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        milliseconds = milliseconds % 1000;
        seconds = seconds % 60;
        minutes = minutes % 60;
        return (hours < 10 ? '0' : '') + hours + ':'
            + (minutes < 10 ? '0' : '') + minutes + ':'
            + (seconds < 10 ? '0' : '') + seconds + '.'
            + (milliseconds < 100 ? '0' : '') + (milliseconds < 10 ? '0' : '') + milliseconds;
    }

    static setCookies = (name, value, saveAuth: boolean) => {
        let expires: Date | string = "Session";
        if (saveAuth) {
            expires = new Date();
            expires.setMonth(expires.getMonth() + 12);
        }
        document.cookie = (name + "=" + value + ";expires=" + expires  + ";domain="+ window.location.hostname +";path=/");
    };

    static deleteCookies = (name) => {
        document.cookie = (name + "=;expires=" + new Date(0).toUTCString()  + ";domain="+ window.location.hostname +";path=/");
    };

    public static formatTime = (valueInSeconds) => {
        const hours = Math.floor(valueInSeconds / 3600);
        const minutes = Math.floor((valueInSeconds % 3600) / 60);
        const seconds = Math.floor(valueInSeconds % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    public static secondsToHHMMStringJp = (second) => {
        if (second < 60) {
            return second + "秒";
        }
        second = Number(second);
        const hour = Math.floor(second / 3600);
        const minute = Math.floor(second % 3600 / 60);
        const seconds = Math.floor(second % 60);
        if (hour > 0) {
            if (minute === 0) {
                return `${hour}時間`;
            }
            return `${hour}時間${minute}分`;
        }
        if (seconds === 0) {
            return `${minute}分`;
        }
        return `${minute}分${seconds}秒`;
    }

    public static roundUpNearest = (num, nearest) => {
        return Math.ceil(num / nearest) * nearest;
    }

    public static convertTimeIndexToTime = (timeIndex) => {
        let minute = Math.floor(timeIndex / 60);
        let hour = 0;
        if(minute >= 60){
            hour = Math.floor(minute/60);
            minute = minute - (hour* 60);
        }
        let second = timeIndex - ((hour * 3600) + (minute * 60));
        return this.convertTimeSingleNumberToDoubleNumber(hour)+":"+ this.convertTimeSingleNumberToDoubleNumber(minute) + ":" + this.convertTimeSingleNumberToDoubleNumber(second);
    };

    public static convertTimeSingleNumberToDoubleNumber = (timeNumber)=>{
        return (timeNumber >= 0 && timeNumber < 10) ? "0"+timeNumber : timeNumber;
    };
}
