import React from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import './VideoDescription.sass';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import FlatList from 'flatlist-react';
import MemoItem from "../memo-item/MemoItem";
import ModalPopup from "../popup/ModalPopup";
import moment from "moment";
import {Constant} from "../constant/Constant";
import Authentication from "../authentication/Authentication";
import CryptoJS from "crypto-js";
import {StringUtil} from "../util/StringUtil";
import {isEmpty} from "lodash";
import truncate from "truncate-html";
import Snackbar from "@material-ui/core/Snackbar";
import ContentItem from "../related-contents/ContentItem";
import {isIOS} from "../../helper/utils";
import Tooltip from "@material-ui/core/Tooltip";
import Subtitle from "../subtitle/Subtitle";
import {connect} from "react-redux";
import {setFontSizeSubtitle, setHideNumberFavorite} from "../../features/setting/settingSlice";
import ChartBitData from "./ChartBitData";
import DownloadScormFile from "./DownloadScormFile";
import DownloadMemo from "./DownloadMemo";

const loading_icon = require("../../assets/image/Loading.gif");
const DETECT_ELLIPSIS = '***STR####';
const photo = require("../../assets/image/photo.png");

interface IProps {
    params?: any;
    prgID: string,
    timeIndex: number,
    videoAuth: boolean,
    name: string,
    authentication: string
    onClickTextArea?: any
    onBlurTextArea?: any,
    updateVideoTime?: any,
    onRemoveSubtitle?: any,
    transferFavoriteList?: any,
    updateLecCommentLength?: any,
    setRelatedContent: any,
    isAuthentication: boolean,
    updateActiveIndex?: any,
    handleClickOnFavoriteIcon: any,
    subscriptions: any,
    showingTracks?: any,
    token: string | null,
    isHideNumberFavorite: boolean,
    setHideNumberFavorite: any,
    fontSizeSubtitle: string,
    setFontSizeSubtitle: any,
    isScorm: boolean,
}

interface IState {
    activeTab: string,
    isExpandedDetail: boolean,
    memos: any,
    isDropDownOpen: boolean,
    currentItem: number,
    newMemo: string,
    isShowPopup: boolean,
    selectedComment: any,
    messageUpload: boolean,
    loading: boolean,
    disabled: boolean,
    messageUploadFail: boolean,
    updatePermission: boolean,
    viewPermission: boolean,
    typeLanguage: any,
    locationHref: any,
    checked: boolean,
    env: string,
    isOpenTabIndex: any,
    isShowImage: boolean,
    isUploading: boolean;
    isOpenModal: boolean;
    snackbarSetup: any,
    indexNewComment: number,
    relatedContents: RelatedContents[],
    isShowRelatedContents: boolean,
    urlAudio: string,
    isVideoProcessing: boolean,
    lecturerInformation: LecturerInformation | null,
    iframeUrl: string,
    isExpandedLecProfile: boolean,
    guestInformation: string[],
    snackbarList: SnackbarItem[],
    bitDataSummary: string[][],
    haveBitData: boolean,
    scormUrlList: ScormUrl[],
}

interface SnackbarItem {
    id: number,
    message: string,
}

interface FavoriteMemos {
    seconds: any,
    commentId: any,
    isFavorite: any
}

interface LecturerInformation {
    lec_name: string,
    lec_pronu: string,
    lec_name_e: string,
    lec_urllabel: string,
    lec_profile: string,
    lec_code: string,
    lecImage: string,
}

interface RelatedContents {
    playListName: string,
    contents: RelatedContenItem[]
}

interface RelatedContenItem {
    thumbnail: string,
    prg_theme: string,
    prg_per_id: number,
    prg_title: string,
    prg_duration: number,
}

export interface ScormUrl {
    scorm_url: string,
    group_id: number,
    project_id: number,
    group_name: string,
    project_name: string
}

class VideoDescription extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activeTab: '1',
            isExpandedDetail: false,
            memos: [],
            isDropDownOpen: false,
            currentItem: -1,
            newMemo: '',
            isShowPopup: false,
            selectedComment: {},
            messageUpload: false,
            loading: false,
            disabled: false,
            messageUploadFail: false,
            updatePermission: false,
            viewPermission: false,
            typeLanguage: Constant.JAPANESE_LANGUAGE_CODE,
            locationHref: window.location.href,
            checked: true,
            env: String(process.env.REACT_APP_ENV_NAME),
            isOpenTabIndex: null,
            isShowImage: true,
            isUploading: false,
            isOpenModal: false,
            snackbarSetup: {
                show: false,
                duration: 1500,
                messsage: '',
            },
            indexNewComment: -1,
            relatedContents: [],
            isShowRelatedContents: false,
            urlAudio: "",
            isVideoProcessing: false,
            lecturerInformation: null,
            iframeUrl: "",
            isExpandedLecProfile: false,
            guestInformation: [],
            snackbarList: [],
            bitDataSummary: [],
            haveBitData: false,
            scormUrlList: [],
        };
    }

    componentDidMount() {
        let authentication = new Authentication();
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetLecComments?u=${u}&a=${a}&prg=${this.props.prgID}&favoritememo=true`)
            .then(res => {
                const lecComments = res.data?.lecComments;
                this.setState({
                    memos: lecComments
                })
                if (lecComments) {
                    this.doTransferFavoriteListToSlide(res.data.lecComments);
                }
                this.props.updateLecCommentLength(lecComments && lecComments.length);
            }).catch(err => {
        })
        authentication.checkIsAdmin((permission) => {
            this.setState({
                updatePermission: permission.update,
                viewPermission: permission.view
            });
            if (permission.view) {
                this.getIframeUrl();
                this.getScormUrlList();
            }
        });

        this.getRelatedContents();
        this.checkUploadVideoIndexer();
    }

    checkUploadVideoIndexer = () => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        axios.get(process.env.REACT_APP_API_ENDPOINT + `/CheckUploadVideoIndexer?u=${u}&a=${a}&prg_id=${this.props.prgID}`)
            .then(res => {
                if (res.data.isProcessing) {
                    this.setState({
                        isVideoProcessing: true
                    })
                }
            }).catch(err => {}
        );
    }

    getRelatedContents = () => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let playListId = params.get('playlistId');
        let isSubcategory = params.get('isSubcategory') === 'true';
        let isFollowing = params.get('isFollowing')  === 'true';
        let watching = params.get('watching');

        if (a && (playListId || watching)) {
            this.setState({
                isShowRelatedContents: true
            });

            let urlRelatedContents = process.env.REACT_APP_API_ENDPOINT + `/GetAirSearchPlayList?u=${u}&a=${a}&play_list_id=${playListId}`;

            if (isSubcategory) {
                urlRelatedContents += "&is_get_sub_category=true&is_admin=false";
            }

            if (isFollowing) {
                urlRelatedContents = process.env.REACT_APP_API_ENDPOINT + `/GetFollowingPlayList?u=${u}&a=${a}&play_list_id=${playListId}`;
            }

            if (watching) {
                urlRelatedContents = process.env.REACT_APP_API_ENDPOINT + `/GetAirSearchPrgList?u=${u}&watching=${watching}`;
            }
            axios.get(urlRelatedContents)
                .then(res => {
                    let relatedContents = [] as RelatedContents[];
                    let relatedContent = {
                        playListName:  res.data?.items[0]?.play_list_name,
                        contents: res.data?.items[0]?.contents,
                    }
                    if (watching) {
                        if (watching === "airsearch_content") {
                            relatedContent.playListName = "視聴した講義";
                        } else if (watching === "aircampus_content") {
                            relatedContent.playListName = "AirCampusで受講した講義";
                        }
                        relatedContent.contents = res.data?.items?.map(item => {
                            return {
                                thumbnail: item.thumbnail,
                                prg_theme: item.prg_theme,
                                prg_per_id: item.prg,
                                prg_title: item.title,
                                prg_duration: item.prg_duration
                            }
                        })
                    }
                    relatedContents.push(relatedContent);
                    this.setState({
                        relatedContents: relatedContents
                    });
                    if (relatedContents.length > 0) {
                        this.props.setRelatedContent(relatedContents[0].contents);
                    }
                }).catch(err => {
                    this.setState({
                        isShowRelatedContents: false
                    });
            })
        } else if (a) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetAllSubcategoriesByContent?u=${u}&a=${a}&prg_id=${this.props.prgID}`)
                .then(res => {
                    let relatedContents = [] as RelatedContents[];
                    let listSubcategory = res.data.item;
                    listSubcategory.forEach((subcategory, index) => {
                        let urlRelatedContents = process.env.REACT_APP_API_ENDPOINT + `/GetAirSearchPlayList?u=${u}&a=${a}&play_list_id=${subcategory.play_list_id}&is_get_sub_category=true&is_admin=false`;
                        axios.get(urlRelatedContents)
                            .then(res => {
                                let relatedContent = {
                                    playListName:  res.data?.items[0]?.play_list_name,
                                    contents: res.data?.items[0]?.contents,
                                }
                                relatedContents.push(relatedContent);
                                if (index === listSubcategory.length - 1) {
                                    if (relatedContents.length > 0) {
                                        this.props.setRelatedContent(relatedContents[0].contents);
                                    }
                                }
                            }).catch(err => {})
                    })
                    if (listSubcategory.length > 0) {
                        this.setState({
                            relatedContents: relatedContents
                        });
                        this.setState({
                            isShowRelatedContents: true
                        });
                    }
                }).catch(err => {
                    this.setState({
                        isShowRelatedContents: false
                    });
                }
            );
        }
    }

    doTransferFavoriteListToSlide = (lecComments) => {
        if (lecComments != undefined) {
            let favoriteList: FavoriteMemos[] = [];
            lecComments.map((item) => {
                if (item.type == Constant.FAVORITE_MEMO_TYPE) {
                    favoriteList.push({
                        seconds: item.second,
                        commentId: item.comment_id,
                        isFavorite: true
                    });
                }
            });
            this.props.transferFavoriteList(favoriteList);
        }
    }

    componentDidUpdate(previousPos, previousState) {
        if (previousPos.params !== this.props.params) {
            this.checkOnUpload(this.props.params);

            if (this.state.lecturerInformation == null && this.props.params?.content?.lec_code) {
                this.getLecturerInformation(this.props.params?.content?.lec_code);
            }
            this.getGuestInformation(this.props.prgID);
        }
        let itemPlayList = document.querySelectorAll('.itemPlayList .card');
        itemPlayList.forEach((value, key) => {
            if (value instanceof HTMLElement) {
                let width = value.offsetWidth ;
                let height = width / Constant.WIDTH_HEIGHT_RATIO;
                let imageFitContent:any = value.querySelector('.img-fit-content');
                imageFitContent.style.height = height + "px";
            }
        })
    }

    toggle = (tab: any) => {
        if (this.state.activeTab !== tab) this.setActiveTab(tab);
    };

    setActiveTab = (tab: any) => {
        this.setState({
            activeTab: tab
        })
    };

    getCookieByName = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    };

    getDate = (day: number) => {
        switch (day) {
            case 1:
                return '月';
            case 2:
                return '火';
            case 3:
                return '水';
            case 4:
                return '木';
            case 5:
                return '金';
            case 6:
                return '土';
            case 0:
            default:
                return '日';
        }
    };

    getDateSummary = () => {
        let items = this.props.params?.timetables?.item
        for (let i in items) {
            if (items[i].attribute.tmt_fstflg == -1) {
                //console.log('item: ', items[i].attribute.tmt_date);
                let date = moment(items[i].attribute.tmt_date, 'YYYYMMDDHHmm');
                return `初回 ${date.year()}年${date.month() + 1}月${date.date()}日(${this.getDate(date.day())}) ${date.hour()}:${('0' + date.minutes()).slice(-2)}`
            }
        }
        return ''
    };

    renderDetails = () => {
        const {
            prg_summary = '',
            prg_detail = ''
        } = this.props.params?.content || {};
        const date = this.getDateSummary();
        let content = StringUtil.isEmptyOrSpaces(prg_summary) ? prg_detail : `${prg_summary}<br><br>${prg_detail}`;

        let urls = content.match(Constant.URL_REGEX);
        if (urls) {
            for (let i = 0; i < urls.length; i++) {
                let url = urls[i];
                let link = `<a href="${url}" target="_blank">${url}</a>`;
                if (!content.includes(link)) {
                    content = content.replaceAll(url, link);
                }
            }
        }

        const preContentTrim = truncate(content, Constant.MAX_LENGTH, {
            ellipsis: DETECT_ELLIPSIS,
        });
        const hasEllipsis = preContentTrim && preContentTrim.includes(DETECT_ELLIPSIS);
        const contentTrim = preContentTrim && preContentTrim.replace(DETECT_ELLIPSIS, '···');
        return (
            <div>
                {this.state.isExpandedDetail ?
                    <span className="none-bold" dangerouslySetInnerHTML={{__html: content}}></span>
                    : <span className="none-bold" dangerouslySetInnerHTML={{__html: contentTrim}}></span>
                }
                {hasEllipsis ?
                    (
                        <>
                            <br/>
                            <span
                                className={'txt'}
                                style={{color: 'blue'}}
                                onClick={() => {this.setState({isExpandedDetail: !this.state.isExpandedDetail})}
                                }>
                                {this.state.isExpandedDetail ? '折りたたむ ' : '続きを見る '}
                                        <FontAwesomeIcon icon={this.state.isExpandedDetail ? faAngleUp : faAngleDown}/>
                            </span>
                            <br/>
                            <br/>
                        </>
                    ) : null
                }
                <span>{date}</span>
            </div>
        )
    };

    onCreateMemo = (memo) => {
        this.doCallAPIToAddMemo(
            this.props.prgID,
            this.state.newMemo || memo,
            this.props.timeIndex,
            Constant.NORMAL_MEMO_TYPE,
        );
    };

    doCallAPIToAddMemo = (prgId, message, seconds, type, image  = '') => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        let broswerUrl = new URL(document.location.href);
        let cid = broswerUrl.searchParams.get("cid");
        let lid = broswerUrl.searchParams.get("lid");
        let cmplid = broswerUrl.searchParams.get("cmplid");
        const params = new URLSearchParams()
        params.append('u', u || '');
        params.append('a', a || '');
        params.append('type', type);
        params.append('prg', prgId);
        params.append('second', seconds);
        params.append('message', message);
        (type === Constant.FAVORITE_MEMO_TYPE) && params.append('comment_image_url', image);
        cid && params.append("cid", cid);
        lid && params.append("lid", lid);
        cmplid && params.append("cmplid", cmplid);

        axios({
            url: `${process.env.REACT_APP_API_ENDPOINT}/CreateLecComment`,
            method: 'POST',
            data: params,
            headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then((res) => {
            const memo = {
                ...res.data,
                prg_per_id: this.props.params.content.prg_per_id,
                prg_title: this.props.params.content.prg_title,
                prg_theme: this.props.params.content.prg_theme
            }
            this.state.memos.push(memo);
            this.props.updateLecCommentLength(this.state.memos.length);
            this.setState({
                newMemo: '',
                indexNewComment: this.state.memos.length - 1,
            });
            type === Constant.FAVORITE_MEMO_TYPE && this.setState({
                snackbarSetup: {
                    ...this.state.snackbarSetup,
                    isShow: true,
                    message: 'スライドをお気に入りとしてメモに登録しました。',
                }
            });
        }).catch((err) => {
            console.log(err);
        })

    }

    addNewMemosFromSlide = (data) => {
        this.doCallAPIToAddMemo(
            this.props.prgID,
            data.index_title,
            data.index_time,
            Constant.FAVORITE_MEMO_TYPE,
            `https://aaa.bbt757.com/asp/data/${this.props.prgID}/ldoc/${data.doc}`,
        )
    }

    deleteMemosFromSlide = (data) => {
        const newMemos = this.state.memos.filter(value => {return !(Number(value.second) === Number(data.index_time) && value.type == Constant.FAVORITE_MEMO_TYPE)})
        const deleteMemo = this.state.memos.filter(value => {return (Number(value.second) === Number(data.index_time) && value.type == Constant.FAVORITE_MEMO_TYPE)})
        if (deleteMemo.length > 0) {
            this.doCallAPIToDeleteMemo(newMemos, deleteMemo[0].comment_id, Constant.FAVORITE_MEMO_TYPE);
        }
    }

    onDeleteMemo = () => {
        const newMemos = this.state.memos.filter(value => Number(value.comment_id) !== Number(this.state.selectedComment.comment_id))
        this.doCallAPIToDeleteMemo(newMemos, this.state.selectedComment.comment_id, Constant.NORMAL_MEMO_TYPE);
    };

    doCallAPIToDeleteMemo = (newMemos, commentId, type) => {
        let u = this.getCookieByName("u");
        let a = this.getCookieByName("a");
        let bodyFormData = new FormData();
        bodyFormData.append('u', u + '');
        bodyFormData.append('a', a + '');
        bodyFormData.append('type', type);
        bodyFormData.append('commentId', commentId);
        axios.post(process.env.REACT_APP_API_ENDPOINT + `/DeleteLecComment?u=${u}&a=${a}&type=10&commentId=${commentId}`, bodyFormData).then((res) => {
            this.setState({
                memos: newMemos,
                selectedComment: {},
                isShowPopup: false
            });
            this.doTransferFavoriteListToSlide(this.state.memos);
            this.props.updateLecCommentLength(this.state.memos.length);
        }).catch((err) => {
            this.doTransferFavoriteListToSlide(this.state.memos);
            console.log(err);
        })
    }

    radioHandler = (e) => {
        this.setState({
            checked: !this.state.checked,
        });
        if (e.target.value === Constant.JAPANESE_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.JAPANESE_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.ENGLISH_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.ENGLISH_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.AUTO_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.AUTO_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.SPANISH_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.SPANISH_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.FRENCH_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.FRENCH_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.GERMAN_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.GERMAN_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.ITALIAN_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.ITALIAN_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.MANDARIN_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.MANDARIN_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.RUSSIAN_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.RUSSIAN_LANGUAGE_CODE
            })
        } else if (e.target.value === Constant.PORTUGUESE_LANGUAGE_CODE) {
            this.setState({
                typeLanguage: Constant.PORTUGUESE_LANGUAGE_CODE
            })
        }
    }

    onUpload = async (languageCode?: string) => {
        this.setState({
            disabled: true,
            isUploading: true,
            messageUploadFail: false
        })
        let userId = this.getCookieByName("u");
        let accessToken = this.getCookieByName("a");
        let urlVideo = this.props.params?.content?.ssltoken?.item[this.props.params?.content?.ssltoken?.item.length - 1]?.attribute?.url;
        let video_url = this.renderVideoUrl(window.location.href);
        let params:any = {
            prg: this.props.prgID,
            u: userId,
            a: accessToken
        };
        const CONTENT_TYPE_VIDEO = "1";
        const CONTENT_TYPE_AUDIO = "2";
        let contentTypeUploadVideo = CONTENT_TYPE_VIDEO;

        this.setState({loading: true})

        if (this.state.urlAudio) {
            urlVideo = this.state.urlAudio;
            contentTypeUploadVideo = CONTENT_TYPE_AUDIO;
        } else {
            await axios({
                url: process.env.REACT_APP_AIR_SEARCH_ENDPOINT + "s3/get-audio-url",
                method: "GET",
                params,
            }).then(data => {
                if (data.data.urlAudio) {
                    urlVideo = data.data.urlAudio;
                    contentTypeUploadVideo = CONTENT_TYPE_AUDIO;
                    this.setState({
                        urlAudio: data.data.urlAudio
                    })
                }
            }).catch(() => {});
        }

        let name = this.props.params.content.prg_title;
        name = this.stripHtml(name);
        name = name.substring(0, 80);

        axios.post(StringUtil.stringFormat(process.env.REACT_APP_API_ENDPOINT + Constant.UP_LOAD_VIDEO, userId, accessToken), {
            url: urlVideo,
            languageCode: languageCode ? languageCode : this.state.typeLanguage,
            name: name,
            videoId: this.props.prgID,
            env: this.state.env,
            videoUrl: video_url,
            contentType: contentTypeUploadVideo,
        }).then((res) => {
            if (res.data.status == Constant.MESSAGE_RESPONSE) {
                this.setState({
                    loading: false,
                    messageUpload: true,
                    disabled: false,
                    isVideoProcessing: true,
                })
                this.onClearMessager();
            } else {
                this.setState({
                    loading: false,
                    messageUploadFail: true,
                    disabled: false
                })
            }
        }).catch((err) => {
            if (err.response.status === 409) {
                this.setState({
                    loading: false,
                    disabled: true,
                    isVideoProcessing: true,
                })
            } else {
                this.setState({
                    loading: false,
                    messageUploadFail: true,
                    disabled: false
                })
            }
        })
    };

    stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    onDelete = () => {
        this.setState({
            disabled: false,
            loading: true,
            isUploading: false,
            messageUploadFail: false,
            isOpenModal: false
        });
        let userId = this.getCookieByName("u");
        let accessToken = this.getCookieByName("a");
        axios.post(StringUtil.stringFormat(process.env.REACT_APP_API_ENDPOINT + Constant.DELETE_SUBTITLE, this.props.prgID, userId, accessToken)).then(res => {
            if (res.data.success) {
                this.setState({
                    loading: false,
                    messageUpload: true
                });
                this.props.onRemoveSubtitle();
                this.onClearMessager();
            } else {
                this.setState({
                    loading: false,
                    messageUploadFail: true,
                    disabled: true
                })
            }
        }).catch((err) => {
            this.setState({
                loading: false,
                messageUploadFail: true,
                disabled: true
            })
        })
    }

    onClearMessager = () => {
        setTimeout(() => {
            this.setState({
                messageUpload: false
            })
        }, 1500)
    }

    private renderVideoUrl(url: string) {
        const domain = String(process?.env?.REACT_APP_DOMAIN);
        if (url.indexOf('localhost') >= 0) {
            return url.replace(window.location.host, domain);
        }
        return url;
    }

    private deleteMemo(item: any) {
        this.setState({
            isShowPopup: true,
            selectedComment: item,
            isOpenTabIndex: null
        })
    }

    private updateMemo(item: any) {
        let index = this.state.memos.findIndex((obj => obj.comment_id == item.comment_id));
        this.state.memos[index] = item;
        this.setState({indexNewComment: index});
    }

    checkOnUpload = (data) => {
        const {captioning} = data;
        const subTitileIndexer = captioning?.item.find(sub => sub.attribute.url.indexOf('/svlAC/GetSubtitles') >= 0);
        if (captioning?.item.length === 0 || isEmpty(subTitileIndexer)) {
            this.setState({
                disabled: false,
            })
        } else {
            this.setState({
                disabled: true,
            })
        }
    }

    onBlur = () => {
        setTimeout(() => {
            this.props.onBlurTextArea()
        }, 200);
    }

    onUpdateTab = (item) => {
        this.setState({
            isOpenTabIndex: item.comment_id
        })
    }

    renderRelatedContentsList = () => {
        return this.state.relatedContents?.map((relatedContent, index) => {
            return <div key={index}>
                <h5 className="MuiTypography-root MuiTypography-h5 mb-2">{relatedContent.playListName}</h5>
                <div id="itemPlayList" className="row no-gutters mx-n2 itemPlayList">
                    {this.renderRelatedContents(relatedContent)}
                </div>
            </div>;
        });
    }

    renderRelatedContents = (relatedContents: RelatedContents) => {
        return relatedContents.contents?.map((contentItem, index) => {
            if (this.props.prgID != contentItem.prg_per_id.toString()) {
                return <ContentItem key={index}
                                    prgTitle={contentItem.prg_title}
                                    thumbnail={contentItem.thumbnail}
                                    prgTheme={contentItem.prg_theme}
                                    prgDuration={StringUtil.getMinuteAndSecondByTime(contentItem.prg_duration)}
                                    prgPerId={contentItem.prg_per_id.toString()}
                                    urlRelatedContents={StringUtil.getUrlRelatedContents(contentItem.prg_per_id)}/>;
            }
        });
    }

    handleClickOnFavoriteIcon = (event, index) => {
        event.preventDefault();
        this.props.handleClickOnFavoriteIcon(index);
    }

    setContentFavoriteSlideEle = (index) => {
        let isAuthentication = new Authentication().checkCookieHaveUsernameAndToken();
        return <>
            <i className={`bi bi-bookmark-fill ${this.props.params?.slides?.item[index].attribute.isFavorite ? "favorite-icon" : "not-favorite-icon"}`}
               hidden={!isAuthentication}
               onClick={(event => this.handleClickOnFavoriteIcon(event, index))}
            />
            {
                (this.props.params?.slides?.item[index].attribute.favorite_num >= 0 && !this.props.isHideNumberFavorite) &&
                <Tooltip title={`${this.props.params?.slides?.item[index].attribute.favorite_num}人がこのスライドをブックマークしています`}
                         placement="left">
                    <span className="favorite-num" onClick={(event => this.handleClickOnFavoriteIcon(event, index))}>
                        <span>{`${this.props.params?.slides?.item[index].attribute.favorite_num < 100 ?
                            this.props.params?.slides?.item[index].attribute.favorite_num : '99+'}`}</span>
                    </span>
                </Tooltip>
            }
        </>
    }

    getLecturerInformation = (lecCode) => {
        if (lecCode) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetLecturerInformation?lec_code=${lecCode}`).then(res => {
                let lecturerInformation = res.data.information;
                if (lecturerInformation) {
                    const regex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
                    if (regex.test(lecturerInformation.lec_urllabel)) {
                        let lecImage = process.env.REACT_APP_SLIDE_DOMAIN_URL + `photo/lecturerphoto/${lecturerInformation.lec_urllabel}`;
                        lecturerInformation.lecImage = lecImage;
                    }
                    this.setState({
                        lecturerInformation
                    })
                }
            }).catch((err) => {})
        }
    }

    getGuestInformation  = (prgId) => {
        if (prgId) {
            axios.get(process.env.REACT_APP_API_ENDPOINT + `/GetGuestInformation?prg_id=${prgId}`).then(res => {
                let guestInformation = res.data.item;
                if (guestInformation) {
                    let guestInformationName = [] as string[];
                    guestInformation.forEach(guest => {
                        guestInformationName.push(guest.cst_name as string);
                    });
                    this.setState({
                        guestInformation: guestInformationName
                    })
                }
            }).catch((err) => {})
        }
    }

    getIframeUrl = () => {
        let userId = this.getCookieByName("u");
        let accessToken = this.getCookieByName("a");
        let params:any = {
            prg_per_id: this.props.prgID,
            u: userId,
            a: accessToken
        };
        axios({
            url: process.env.REACT_APP_AIR_SEARCH_ENDPOINT + "metabase/get-player-iframe-url",
            method: "GET",
            params,
        }).then(response => {
            if (response.data?.message === "success") {
                this.setState({
                    iframeUrl: response.data?.iframeUrl
                })
            }
        })
    }

    render() {
        const {loading} = this.state;
        const video_indexer_text = Constant.VIDEO_INDEXER;
        const video_delete_indexer_text = Constant.VIDEO_DELETE_INDEXER;
        // @ts-ignore
        return <div className="video-description">
            <Nav tabs className="mt-4 p-rl15 tab">
                <NavItem className="border-ul">
                    <NavLink className={this.state.activeTab === '1' ? 'active' : ''}
                             onClick={() => this.toggle('1')}>概要</NavLink>
                </NavItem>
                {
                    this.props.isAuthentication &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '2' ? 'active' : ''}
                                 onClick={() => this.toggle('2')}>メモ{this.state.memos.length > 0 ? `(${this.state.memos.length})` : ""}</NavLink>
                    </NavItem>
                }
                {
                    (this.props.isAuthentication && this.props.videoAuth && this.props.params?.slides) &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '6' ? 'active' : ''}
                                 onClick={() => this.toggle('6')}>スライド一覧</NavLink>
                    </NavItem>

                }
                {
                    this.state.isShowRelatedContents && !this.props.isScorm &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '4' ? 'active' : ''}
                                 onClick={() => this.toggle('4')}>関連講義</NavLink>
                    </NavItem>

                }
                {
                    this.props.isAuthentication &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '7' ? 'active' : ''}
                                 onClick={() => this.toggle('7')}>字幕</NavLink>
                    </NavItem>
                }
                {
                    new Authentication().checkCookieHaveUsernameAndToken() &&
                    this.props.params.telop !== undefined &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '3' ? 'active' : ''}
                                 onClick={() => this.toggle('3')}>認証</NavLink>
                    </NavItem>
                }
                {
                    (this.state.viewPermission === true && new Authentication().checkCookieHaveUsernameAndToken()) &&
                    <NavItem>
                        <NavLink className={this.state.activeTab === '5' ? 'active' : ''}
                                 onClick={() => this.toggle('5')}>管理</NavLink>
                    </NavItem>
                }
                {
                    this.props.isAuthentication &&
                    <NavItem className="border-ul">
                        <NavLink className={this.state.activeTab === '8' ? 'active' : ''}
                                 onClick={() => this.toggle('8')}>設定</NavLink>
                    </NavItem>
                }
            </Nav>
            <TabContent activeTab={this.state.activeTab} className="p-rl15">
                <TabPane tabId={'1'}>
                    <div className="mt-4 mb-4 border-0">
                        <div className="d-flex no-gutters">
                            {
                                this.state.isShowImage && (
                                    <div>
                                        <img
                                            onError={() => this.setState({isShowImage: false})}
                                            src={this.state.lecturerInformation?.lecImage ? this.state.lecturerInformation?.lecImage : photo}
                                            className="card-img lec-image" alt={this.props.params?.content?.lec_name}/>
                                    </div>
                                )
                            }
                            <div>
                                <div className="card-body pt-0">
                                    <h5 className="card-title" hidden={!this.props.params?.content?.lec_name}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             fill="currentColor" className="bi bi-mortarboard-fill mr-2" style={{transform: "translateY(-4px)"}} viewBox="0 0 16 16">
                                            <path
                                                d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z"/>
                                            <path
                                                d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z"/>
                                        </svg>
                                        <a target="_blank"
                                           href={process.env.REACT_APP_AIR_SEARCH_ENDPOINT + `search-by-keyword?keyword=${this.props.params?.content?.lec_name}&sortKey=rank&sortOrder=desc&lec_code=${this.props.params?.content?.lec_code}`}
                                           style={{textDecoration: "none"}}>
                                            {this.props.params?.content?.lec_name}</a>
                                    </h5>
                                    {
                                        this.state.lecturerInformation?.lec_profile &&
                                        <>
                                            <p className={`card-text collapse-lec-profile ${this.state.isExpandedLecProfile ? 'show' : 'hide'}`} id="lec_profile" dangerouslySetInnerHTML={{__html: this.state.lecturerInformation?.lec_profile}}/>
                                            <a
                                                className="txt mb-2 d-block"
                                                style={{color: 'blue'}}
                                                onClick={() => {this.setState({isExpandedLecProfile: !this.state.isExpandedLecProfile})}}
                                            >
                                                {
                                                    this.state.isExpandedLecProfile ?
                                                        <span className="simple">
                                                            <span>折りたたむ </span>
                                                            <FontAwesomeIcon icon={faAngleUp}/>
                                                        </span>
                                                        :
                                                        <span className="more">
                                                            <span>続きを見る </span>
                                                            <FontAwesomeIcon icon={faAngleDown}/>
                                                        </span>
                                                }
                                            </a>
                                        </>
                                    }
                                    <p hidden={this.state.guestInformation.length === 0} className="card-text">ゲスト：{this.state.guestInformation.join(", ")}</p>
                                    <p hidden={!this.props.params?.content?.ass_name} className="card-text">アシスタント：{this.props.params?.content?.ass_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderDetails()}
                    {
                        (/Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isIpad()) &&
                        new Authentication().checkCookieHaveUsernameAndToken() &&
                        this.props.videoAuth &&
                        <a
                            className={'txt'}
                            style={{color: 'blue'}}
                            href="#"
                            onClick={(event) => this.onClickOnDownload(event)}
                        >
                            <br/>
                            <br/>
                            講義ダウンロード
                        </a>
                    }
                </TabPane>
                {
                    this.props.isAuthentication &&
                    <TabPane tabId={'2'} className="tab-comment">
                        {this.props.videoAuth
                            ? <div>
                            <textarea
                                className="form-control mt-4"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                onClick={() => {
                                    this.props.onClickTextArea();
                                    this.setState({isOpenTabIndex: null})
                                }}
                                onBlur={this.onBlur}
                                onChange={(textarea) => {
                                    this.setState({newMemo: textarea.target.value})
                                }}
                                value={this.state.newMemo}
                                placeholder={'255 characters remaining'}
                            />
                                <span style={{color: '#f20a11'}} className="mt-2">
                            {this.state.newMemo.length > 255 ? 'Exceeded characters limit' : ''}
                            </span>
                                <div className="clearfix mt-3">
                                    <button type="button"
                                            disabled={this.state.newMemo.length > 255 || this.state.newMemo === ''}
                                            className="btn btn-primary rounded-pill float-right fs-14"
                                            onClick={this.onCreateMemo}>保存
                                    </button>
                                </div>
                            </div>
                            : <div/>
                        }

                        {
                            this.props?.params?.content && this.state.memos.length > 0 &&
                            <DownloadMemo memos={this.state.memos} content={this.props.params.content} />
                        }


                        <FlatList
                            list={this.state.memos}
                            extraData={this.state.memos}
                            renderItem={(item, index) => <MemoItem item={item} key={index}
                                                                   onClickTextArea={this.props.onClickTextArea}
                                                                   isOpenTabIndex={this.state.isOpenTabIndex}
                                                                   onBlurTextArea={this.onBlur}
                                                                   onDeleteCallback={(i) => this.deleteMemo(i)}
                                                                   onUpdateCallback={(i) => this.updateMemo(i)}
                                                                   onUpdateTab={this.onUpdateTab}
                                                                   updateVideoTime={this.props.updateVideoTime}
                                                                   isNew={false}
                            />}
                            renderWhenEmpty={() => <div/>}
                        />
                    </TabPane>
                }
                {
                    this.props.params?.telop !== undefined &&
                    <TabPane tabId={'3'}>
                        <pre style={{fontSize: 16, marginTop: 16}}>
                            {this.renderTabAuthentication()}
                        </pre>
                    </TabPane>
                }
                <TabPane tabId={'5'}>
                    <div className="mt-4 mb-4 border-0">
                        {loading &&
                        <div>
                            <img src={loading_icon} className="loading"/><span
                            className="message-success">{this.state.isUploading ? Constant.MESSAGE_LOADING : Constant.MESSAGE_DELETE_LOADING}</span>
                        </div>
                        }
                        {(this.state.messageUpload) ?
                            <div>
                                <p className="message-success">{this.state.isUploading ? Constant.MESSAGE_SUCCESS : Constant.MESSAGE_DELETE_SUCCESS}</p>
                            </div>
                            : <div aria-disabled={true}></div>
                        }
                        {(this.state.messageUploadFail && !this.state.disabled) ?
                            <div>
                                <p className="message-fail">{Constant.MESSAGE_FAIL}</p>
                            </div>
                            : <div aria-disabled={true}></div>
                        }
                        {(this.state.isVideoProcessing) ?
                            <div>
                                <p className="message-success">現在字幕を作成しています。しばらくお待ちください。
                                    <br/>※リクエストした受講者には処理が完了するとメールで通知が送信されます。</p>
                            </div>
                            : <div aria-disabled={true}></div>
                        }

                        <div>
                            <h6>字幕生成</h6>
                            <p className={'mb-2'}>コンテンツで主に話される言語を選択してください。</p>
                            <div onChange={this.radioHandler}>
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.AUTO_LANGUAGE_CODE} name="language"/> {Constant.AUTO_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.JAPANESE_LANGUAGE_CODE} name="language" className="radioButtonEng"
                                       defaultChecked={this.state.checked}/> {Constant.JAPANESE_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.ENGLISH_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.ENGLISH_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.SPANISH_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.SPANISH_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.FRENCH_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.FRENCH_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.GERMAN_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.GERMAN_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.ITALIAN_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.ITALIAN_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.MANDARIN_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.MANDARIN_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.RUSSIAN_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.RUSSIAN_LANGUAGE}
                                <input type="radio" disabled={this.state.loading || this.state.disabled}
                                       value={Constant.PORTUGUESE_LANGUAGE_CODE} name="language"
                                       className="radioButtonEng"/> {Constant.PORTUGUESE_LANGUAGE}
                            </div>
                            <div className="row no-gutters mt-2">
                                <button type="button"
                                        className="btn btn-primary rounded-pill float-right fs-14 buttonIndexer mr-3 mb-2"
                                        onClick={() => {this.onUpload(undefined)}} disabled={this.state.disabled || this.state.loading || this.state.isVideoProcessing}>
                                    {video_indexer_text}
                                </button>
                                <button type="button" hidden={!this.state.updatePermission}
                                        className="btn btn-primary rounded-pill float-right fs-14 buttonIndexer mb-2"
                                        disabled={this.state.loading || !this.state.disabled || this.state.isVideoProcessing}
                                        onClick={() => this.setState({isOpenModal: true})}
                                >
                                    {video_delete_indexer_text}
                                </button>
                            </div>
                            <a className="d-inline-block mt-3" target="_blank" href={this.renderProgramManager()}>
                                {this.props.params?.content?.prg_title}の管理画面を開く</a>
                            <br/>
                            <DownloadScormFile
                                scormUrlList={this.state.scormUrlList}
                                getUrlDownloadScormFile={this.getUrlDownloadScormFile}/>

                            {
                                this.state.iframeUrl &&
                                    <iframe src={this.state.iframeUrl}
                                            className="mt-3 iframe-import"
                                            frameBorder="0"
                                            width="100%"
                                            allowTransparency>
                                    </iframe>
                            }
                            <ChartBitData prgID={this.props.prgID} isAdmin={this.state.viewPermission}/>
                        </div>
                    </div>
                </TabPane>
                {
                    this.state.isShowRelatedContents &&
                    <TabPane tabId={'4'}>
                        <div className="mt-4 mb-4 border-0">
                            {
                                this.state.relatedContents.length > 0 ?
                                    this.renderRelatedContentsList() : null
                            }
                        </div>
                    </TabPane>
                }
                {
                    (this.props.isAuthentication && this.props.videoAuth && this.props.params?.slides) &&
                    <TabPane tabId={'6'}>
                        <div className="mt-4 mb-4 border-0">
                            <div className="row no-gutters mx-n2 itemPlayList pt-3"
                                 aria-expanded="true">
                                {
                                    this.props.params?.slides?.item.map((item, index) => {
                                        if (item.attribute.index_type === 0) {
                                            return (
                                                <div key={index} className="col-xl-3 col-md-4 col-sm-6 px-2 mb-3">
                                                    <div className="card product-card card-static prePerId">
                                                        <a className="card-img-top d-block overflow-hidden text-center img-wrapper image-time-watched item-gallery"
                                                            href="#" onClick={(event)=>{
                                                               if ((event.target as HTMLAnchorElement).tagName.toLowerCase() !== 'span' && (event.target as HTMLAnchorElement).tagName.toLowerCase() !== 'i') {
                                                                   const timeIndex = isIOS() ? item.attribute.index_time + 1 : item.attribute.index_time;
                                                                   this.props.updateActiveIndex(timeIndex);
                                                                   this.props?.updateVideoTime(timeIndex)
                                                               }}}>
                                                            <img className="img-fit-content"
                                                                 src={`${process.env.REACT_APP_SLIDE_DOMAIN_URL}asp/data/${this.props.prgID}/doc/${item.attribute.doc}`}/>
                                                            <span className="program-duration">{StringUtil.getMinuteAndSecondByTime(item.attribute.index_time)}</span>
                                                            <span className="favorite-slide">
                                                            {
                                                                this.setContentFavoriteSlideEle(index)
                                                            }
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </TabPane>
                }
                {
                    this.props.isAuthentication &&
                    <TabPane tabId={'7'} className="tab-comment">
                        <Subtitle subscriptions={this.props.subscriptions}
                                  onRequestSubtitles={this.onUpload}
                                  messageUpload={this.state.messageUpload}
                                  disabled={this.state.disabled} loading={this.state.loading}
                                  messageUploadFail={this.state.messageUploadFail}
                                  isUploading={this.state.isUploading}
                                  updateVideoTime={this.props.updateVideoTime}
                                  showingTracks={this.props.showingTracks}
                                  params={this.props.params}
                                  isVideoProcessing={this.state.isVideoProcessing}/>
                    </TabPane>
                }
                {
                    this.props.isAuthentication &&
                    <TabPane tabId={'8'}>
                        <div className="mt-4 mb-4 border-0">
                            <h6 className="mb-3">設定内容はすべての受講画面に反映されます。</h6>
                            <div className="d-flex">
                                <label className="mt-auto mb-auto">字幕のサイズ設定:</label>
                                <select className="form-control ml-2" style={{width: '240px'}} value={this.props.fontSizeSubtitle} onChange={e => {
                                    const val = e.target.value
                                    this.props.setFontSizeSubtitle(val)
                                }}>
                                    <option value="19">小</option>
                                    <option value="22">標準</option>
                                    <option value="30">大</option>
                                </select>
                            </div>
                            <br/>
                            <input type="checkbox"
                                   defaultChecked={this.props.isHideNumberFavorite}
                                   onChange={e => {
                                       const { checked } = e.target
                                       this.props.setHideNumberFavorite(checked)
                                   }}
                            /> お気に入りスライドの数を非表示にする
                        </div>
                    </TabPane>
                }
            </TabContent>

            <ModalPopup
                isShow={this.state.isShowPopup}
                title={Constant.DELETE_MEMO_CONFIRM_TITLE}
                message={Constant.DELETE_MEMO_CONFIRM_MESSAGE}
                positiveButton={Constant.DELETE_MEMO_CONFIRM_BUTTON_TEXT}
                negativeButton={Constant.CANCEL_BUTTON_TEXT}
                onPositiveButtonClicked={this.onDeleteMemo}
                onNegativeButtonClicked={() => {
                    this.setState({isShowPopup: false})
                }}
            />
            {/* modal comfirm delete video indexer */}
            <ModalPopup
                isShow={this.state.isOpenModal}
                title={'字幕の削除を確認'}
                message={'字幕を削除してもよろしいでしょうか？'}
                positiveButton={'OK'}
                negativeButton={'キャンセル'}
                onPositiveButtonClicked={this.onDelete}
                onNegativeButtonClicked={() => {
                    this.setState({isOpenModal: false})
                }}
            />
            <Snackbar
                autoHideDuration={this.state.snackbarSetup.duration}
                open={this.state.snackbarSetup.isShow}
                onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        return this.setState({snackbarSetup: {...this.state.snackbarSetup, isShow: false}})
                    }
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                children={<div className="alert alert-success" style={{maxWidth: 400}}>{this.state.snackbarSetup.message}</div>}
            />

            <Snackbar
                open={true}
                onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
                    return;
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                children={<div>
                    {
                        this.state.snackbarList.map((item, index) => {
                            return <div key={index} className="alert alert-success" style={{maxWidth: 400}}>{item.message}</div>
                        })
                    }
                </div>}
            />

            <div hidden>
                <div id="flatListForExtend">
                    <FlatList
                      list={this.state.memos}
                      extraData={this.state.memos}
                      renderItem={(item, index) => <MemoItem item={item} key={index}
                                                             onClickTextArea={this.props.onClickTextArea}
                                                             isOpenTabIndex={this.state.isOpenTabIndex}
                                                             onBlurTextArea={this.onBlur}
                                                             onDeleteCallback={(i) => this.deleteMemo(i)}
                                                             onUpdateCallback={(i) => this.updateMemo(i)}
                                                             onUpdateTab={this.onUpdateTab}
                                                             updateVideoTime={this.props.updateVideoTime}
                                                             isNew={Number.parseInt(index) === this.state.indexNewComment}
                      />}
                      renderWhenEmpty={() => <div/>}
                    />
                </div>
            </div>
        </div>
    }

    private renderTabAuthentication() {
        let authens = this.props.authentication?.split(";");
        let tabString = "";

        if (this.props.params.tcbit?.attendance_status == 'complete') {
            tabString += "受講認証は正常に終了しました。\n"
            tabString += "\n"
        }
        if (authens) {
            for (let i = authens.length - 2; i >= 0; i--) {
                let item = authens[i]?.split(",");
                if (item[0] == "I") {
                    tabString += "[" + this.secondsToHms(item[2]) + "]に「" + item[1] + "」を押しました。\n"
                } else if (item[0] == "C" && item[1] == "S") {
                    tabString += "\n";
                    tabString += "受講認証が開始されました。 (" + item[3] + ")"
                }
            }
        }
        return tabString;
    }

    private secondsToHms(d) {
        d = Number(d);
        let h = ("0" + Math.floor(d / 3600)).slice(-2);
        let m = ("0" + Math.floor(d % 3600 / 60)).slice(-2);
        let s = ("0" + Math.floor(d % 3600 % 60)).slice(-2);

        return h + ":" + m + ":" + s;
    }

    private renderMobileDeepLink = (): string => {
        let url = "acm://movie/addWithPrgPerId/";
        url += this.props.params?.content?.prg_per_id;
        url += "/Air Search";
        url += "/" + this.props.params?.content?.prg_title;

        // add expired time
        let date = new Date();
        date.setMinutes(date.getMinutes() + 30);
        url += "/" + date.getTime();

        // add hash and authen token
        let authenToken = new Authentication().getCookieByName("a");
        let secretKey = "gJExV9ltC9h=cEkepRpMyf7umLC7o8O6ka3k3kHpwYga+C5U3+uYYpEwnK+93PsP";
        let hash = CryptoJS.enc.Hex.parse(CryptoJS.SHA1(secretKey + ":" + authenToken + ":" + date.getTime() + ":" + this.props.params?.content?.prg_per_id).toString(CryptoJS.enc.Hex));
        url += "/" + hash;
        url += "/" + authenToken;
        return url;
    }

    private onClickOnDownload(event) {
        event.preventDefault();
        let checkedAt = (new Date()).getTime();
        let isAndroid;
        if (/Android/i.test(navigator.userAgent)) {
            isAndroid = true;
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isIpad()) {
            isAndroid = false;
        }
        let callback = setTimeout(function () {
            let lagTime = (new Date()).getTime() - checkedAt;
            if (lagTime > 400) {
                window.location.href = isAndroid ? 'https://play.google.com/store/apps/details?id=com.bbt757.android.acmobileandroidzebra' : 'itms-apps://itunes.com/app/1202067892';
                return;
            }
        }, this.isSafari() ? 3000 : 1500);
        window.location.href = this.renderMobileDeepLink();

        window.document.addEventListener("visibilitychange", function () {
            clearTimeout(callback);
        });
    }

    private isIpad() {
        let agent = window.navigator.userAgent.toLowerCase();
        return (agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1) && 'ontouchend' in document;
    }

    private isSafari() {
        let ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            return true;
        }
        return false;
    }

    private renderProgramManager() {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let playListId = params.get('playlistId');
        let isSubcategory = params.get('isSubcategory') === 'true';
        let isFollowing = params.get('isFollowing')  === 'true';
        if (playListId && isSubcategory && !isFollowing) {
            return process.env.REACT_APP_AIR_SEARCH_ENDPOINT + `program-manager?prgId=${this.props.prgID}&playListId=${playListId}`;
        }
        return process.env.REACT_APP_AIR_SEARCH_ENDPOINT + `program-manager?prgId=${this.props.prgID}`;
    }

     getUrlDownloadScormFile = (scormVersion: string, isHideBtnRegister: string, groupId?: number, projectId?: number, groupName?: string) => {
        let userId = this.getCookieByName("u");
        let token = this.getCookieByName("a");
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let cid = params.get("cid");
        let lid = params.get("lid");
        let cmplid = params.get("cmplid");
        let courseTitle = `${this.props.params?.content?.prg_title}${this.props.params?.content?.lec_name ? ` (${this.props.params?.content?.lec_name})` : ''}`;
        let data:any = {
            prg: this.props.prgID,
            username: userId,
            authToken: token,
            tokenScorm: this.props.token,
            hideBtnRegister: isHideBtnRegister,
            scormVersion,
            courseTitle,
            cid,
            lid,
            cmplid,
            groupId,
            projectId,
            groupName
        };

         const form = document.createElement('form');
         form.method = "post";
         form.action = process.env.REACT_APP_AIR_SEARCH_ENDPOINT + "s3/download-scorm-file";
         for (const [key, value] of Object.entries(data)) {
             if (value) {
                 const input = document.createElement('input');
                 input.type = "text";
                 input.name = key;
                 input.value = value.toString();
                 form.append(input);
             }
         }
         document.body.appendChild(form);
         form.submit();
         form.remove();
    }

    getScormUrlList = () => {
        let userId = StringUtil.getCookieByName("u");
        let token = StringUtil.getCookieByName("a");
        if (userId && token) {
            let urlGetScormUrl = process.env.REACT_APP_API_ENDPOINT + `/GetScormUrl?u=${userId}&a=${token}`;
            axios.get(urlGetScormUrl)
                .then(res => {
                    let data = res.data;
                    if (data.item?.length > 0) {
                        this.setState({
                            scormUrlList: data.item,
                        })
                    }
                })
        }
    }
}

const mapStateToProps = (state) => ({
    isHideNumberFavorite: state.setting.isHideNumberFavorite,
    fontSizeSubtitle: state.setting.fontSizeSubtitle,
    isScorm: state.scorm.isScorm,
});
export default connect(mapStateToProps, {setHideNumberFavorite, setFontSizeSubtitle}, null, {forwardRef: true})(VideoDescription);
