import React from 'react';
import {Table} from "reactstrap";
import {StringUtil} from "../util/StringUtil";
import {Constant} from "../constant/Constant";
import axios from "axios";
import {parse} from "@plussub/srt-vtt-parser";
import DownloadExcel from "./DownloadExcel";
import moment from "moment";

interface TrackProps {
    kind: string;
    src: string;
    srcLang: string;
    default: boolean;
    label: string;
}

interface IProps {
    subscriptions: any,
    updateVideoTime: any,
    showingTracks?: any,
    params?: any;
}

interface IState {
    subtitleList: any;
    subscriptionSelected: string,
}

class Subscription extends React.Component<IProps, IState> {
    subscriptionList: any;
    subtitleListMap = new Map<string, any>();
    subtitleSelectRef: any;

    constructor(props: IProps) {
        super(props);
        this.subtitleSelectRef = React.createRef();
        this.state = {
            subtitleList: [],
            subscriptionSelected: "日本語(自動生成)",
        };
    }

    componentDidMount() {
        if (this.state.subtitleList.length === 0) {
            const subscriptionList = this.renderSubscriptionObject(this.props.subscriptions);
            subscriptionList.sort(function (a, b) {
                return Constant.SORT_ORDER_LANGUAGE.indexOf(b.srcLang) - Constant.SORT_ORDER_LANGUAGE.indexOf(a.srcLang);
            });
            this.subscriptionList = subscriptionList;
            if (subscriptionList && subscriptionList[0]) {
                this.getSubtitle(subscriptionList[0].src);
            }
        }
    }

    renderSubscriptionObject = (subscription) => {
        let subscriptionList: TrackProps[] = [];
        if (subscription != undefined && subscription instanceof Array) {
            subscription.forEach(item => {
                subscriptionList.push({
                    kind: Constant.SUBSCRIPTION_KIND,
                    src: item.sourceUrl,
                    srcLang: item.languageName,
                    default: item?.languageCode?.toLowerCase() == Constant.JAPANESE_LANGUAGE_CODE,
                    label: item.languageName
                })
            });
        }
        return subscriptionList;
    };

    handleSelectChange = (event) => {
        let {value} = event.target;
        let index = event.nativeEvent.target.selectedIndex;
        let subscriptionSelected = event.nativeEvent.target[index].text;
        this.setState({
            subscriptionSelected: subscriptionSelected
        })
        this.getSubtitle(value);
    }

    getSubtitle = (src) => {
        if (this.subtitleListMap.has(src)) {
            this.setState({subtitleList: this.subtitleListMap.get(src)});
        } else {
            axios.get(src).then(res => {
                const { entries } = parse(
                    res.data
                );
                entries.sort((a, b) => a.from - b.from);
                this.setState({subtitleList: entries});
                this.subtitleListMap.set(src, entries);
            })
        }
    }

    updateVideoTime = (timeIndex) => {
        if (this.subtitleSelectRef) {
            let selectedValue = this.subtitleSelectRef.current.value;
            let selectedText = this.subtitleSelectRef.current.options[this.subtitleSelectRef.current.selectedIndex].text;
            this.props.showingTracks(selectedValue, selectedText);
            this.props.updateVideoTime(timeIndex / 1000);
            window.scrollTo(0, 0);
        }
    }

    render() {
        return (
            <>
                <div className="form-group d-flex">
                    <label className="col-form-label mr-2">字幕:</label>
                    <div>
                        <select className="form-control" onChange={this.handleSelectChange} ref={this.subtitleSelectRef}>
                            {
                                this.subscriptionList?.map((item, index) => {
                                    return <option value={item.src} key={index}>{item.label}</option>;
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="d-flex justify-content-between">
                        <span style={{ lineHeight: "43px" }}>作成日時：{moment(this.props.params?.content?.subtitle_create_date).format("YYYY/MM/DD HH:mm")}</span>
                        <DownloadExcel subtitleList={this.state.subtitleList}
                                       params={this.props.params}
                                       subscriptionSelected={this.state.subscriptionSelected}/>
                    </div>
                    <Table responsive="sm" className="table-subtitle">
                        <thead>
                        <tr>
                            <th style={{width: '200px'}}>タイム</th>
                            <th>内容</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.subtitleList?.map((item, index) => {
                                return <tr key={index} onClick={() => this.updateVideoTime(item.from)}>
                                    <td>{`${StringUtil.srtTimestamp(item.from)} - ${StringUtil.srtTimestamp(item.to)}`}</td>
                                    <td>{item.text}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
}

export default Subscription;